import React from 'react';

import './Commercial.css';


import Header from 'components/Header/Header';

import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";

import {withRouter} from "react-router-dom";

import NewSearchBar from "components/Searchbar/NewSearchBar";
import SalesUnitsFilters from "components/Filters/SalesUnitsFilters/SalesUnitsFilters";

import {SalePointService} from 'services/SalePointService';

import Navigation from 'components/Navigation/Navigation';
import {UserServices} from "services/UserServices";
import {convertDate} from "helpers/string";

import {sanitizeForSearch} from "helpers/string";

import _ from "lodash";

import Button from 'components/Button/Button';

import LoaderSpiral from "components/LoaderSpiral/LoaderSpiral";

import {routes} from "../../config/routes";

class Commercial extends PrivateComponent {

    constructor(props) {
        super(props);


        this.state = {
            fdvs : [],
            saleUnitFilter:null,
            searchList:[],
            searchQuery:null,
            fdvLoaded:false

        }

        this.searchBar = React.createRef();

    }



    componentDidMount(){

        if(!this.context.userSalePoints.length) {
            this.getUserSalePoints();
        }

        this.getFdvs();
    }


    getFdvs() {
        UserServices.getFdvs().then(response => {
            if (200 === response.status) {
                this.setState({fdvs:response.data, fdvLoaded:true});
            }
        });
    }


    getUserSalePoints() {

        SalePointService.getUserList().then(response => {
            if(response.data && Array.isArray(response.data)) {
                this.context.setUserSalePoints(response.data)
            }
        });

    }


    getNbSalesUnit() {

        if(this.context.userSalePoints && this.context.userSalePoints.length) {
            let saleUnits = {};
            this.context.userSalePoints.forEach((item,i) => {

                if(item.sales_unit) {
                    saleUnits[item.sales_unit.id] = item.sales_unit;
                }
            });

            return Object.keys(saleUnits).length;
        }


    }


    onResetSearch() {
        this.setState({searchQuery:null,searchList: []});
    }


    onSearchChange(query) {
        this.onSubmitSearch(query);
    }

    onSubmitSearch(query) {

        let searchResults = _.filter(this.state.fdvs, (user) => {
            const useQuery = sanitizeForSearch(query);
            return(
                (user.firstname && sanitizeForSearch(user.firstname).indexOf(useQuery) !== -1)
                || (user.lastname && sanitizeForSearch(user.lastname).indexOf(useQuery) !== -1)
                || (user.sales_unit && sanitizeForSearch(user.sales_unit.name).indexOf(useQuery) !== -1)
            )
        });

        this.setState({searchQuery:query,searchList: searchResults});
    }

    onFilterBySaleUnit(e) {
        this.setState({saleUnitFilter: e.target.value ? parseInt(e.target.value) : null});
    }


    getUserList() {

        let useList = this.state.fdvs;

        if(this.state.searchQuery) {

            useList = this.state.searchList;

            if(!useList.length) {
                return <div className={'no-result'}>
                    <p><strong>Désolé, aucun résultat ne correspond à votre mot clé</strong></p>
                    <p>Vérifiez l’orthographe de votre recherche ou utilisez un mot clé plus général</p>
                    <div className={'no-result__btns'}>
                        <Button onClick={() => this.searchBar.current.resetField()} className={'btn--secondary'} content={'Retour à tous mes commerciaux'} />
                    </div>
                </div>
            }
        }


        return useList.map((item, i) => {

            let displayUnit = true;

            if (this.state.saleUnitFilter) {
                displayUnit = item.sales_unit && item.sales_unit.id === this.state.saleUnitFilter;
            }

            if(!displayUnit) {
                return;
            }

            return <div className={'user-card'} key={i} onClick={(e) => { e.preventDefault(); this.props.history.push(routes.commercialSheet.route(item.id)) }}>
                <div className="user-card__in">
                    <i className={'icon icon-contact'} />
                    <div className={'info'}>
                        <div className={'info__user'}>{ item.firstname} {item.lastname}</div>
                        <div className={'info__date'}>Dernière activité : {item.last_login ? convertDate(item.last_login) : '-' }</div>
                    </div>
                    <div className={'user-card__unit'}>
                        <i className={'icon icon-work'} />
                        <span className={'name'}> {item.sales_unit ? item.sales_unit.name : ''}</span>
                    </div>
                    <div className={'user-card__status'}>

                        <span className={'status todo'}> {item.interview_status  ? item.interview_status.todo : 0} à faire</span>
                        <span className={'status in_progress'}> {item.interview_status  ? item.interview_status.in_progress : 0} en cours</span>
                        <span className={'status completed'}> {item.interview_status  ? item.interview_status.completed : 0} {item.interview_status && item.interview_status.completed > 1 ? 'réalisés' : 'réalisé'}  </span>
                    </div>

                    <i className="icon-miniarrowright sale-point-card__more-icon" />


                </div>
            </div>
        })
    }


    render() {

        let nbSalesUnit = this.getNbSalesUnit();

        return (
            <div className="Commercial">

                <Header
                    className="dashboard Commercial__head"
                    subtitle={'Commerciaux Hager'}
                    showSave={false}
                    title={'Gestion des commerciaux et des unités de ventes'}>

                    <div className="shop-count">
                        <div></div>
                        <div className="shop-count__counter">
                            <div className="shop-count__counter__number">{ this.state.fdvs ? this.state.fdvs.length : 0 }</div>
                            <div className="shop-count__counter__label"> {this.state.fdvs && this.state.fdvs.length > 1 ? 'commerciaux' : 'commercial'}</div>
                        </div>
                        <div className="shop-count__counter">
                            <div className="shop-count__counter__number">{ nbSalesUnit ? nbSalesUnit : 0 }</div>
                            <div className="shop-count__counter__label">{nbSalesUnit && nbSalesUnit > 1 ? 'unités de vente' : 'unité de vente'}</div>
                        </div>
                    </div>

                </Header>

                <div className="w-content">
                    <div className="container">

                        <NewSearchBar ref={this.searchBar}
                                      placeholder={'ex. Prénom, Nom, Unité de vente'}
                                      onChange={(query) => this.onSearchChange(query)}
                                      onSubmit={(query) => this.onSubmitSearch(query)}
                                      onReset={() => this.onResetSearch()} />

                        <div className="section-top">
                            <h2 className={'h2'}>Commerciaux</h2>
                            {
                                this.context.userSalePoints.length ?
                                    <SalesUnitsFilters onChange={(e) => this.onFilterBySaleUnit(e)} units={this.context.userSalePoints}/>
                                    :
                                    null
                            }
                        </div>

                        <div>

                            {
                                !this.state.fdvLoaded ? <LoaderSpiral /> : this.getUserList()
                            }

                        </div>
                    </div>

                </div>

                <Navigation/>

            </div>
        );
    }
}

export default withRouter(Commercial);

Commercial.contextType = GlobalContext;