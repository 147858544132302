import React, {Component} from 'react';
import {GlobalContext} from "../../../providers/GlobalProvider";

import './SalesUnitsFilters.scss';

export default class SalesUnitsFilters extends Component {

    constructor(props) {
        super(props);

        this.filters = React.createRef();

        this.state = {
            open: false,
            saleUnits:{}
        }
    }

    componentDidMount(){

        let saleUnits = {};

        this.props.units.forEach((item,i) => {

            if(item.sales_unit) {
                saleUnits[item.sales_unit.id] = item.sales_unit;
            }
        });
        this.setState({saleUnits: saleUnits})
    }

    handleClick(){
        if(this.state.open){
            this.filters.current.classList.remove('open');
            this.setState({
                open: false
            })
        }
        else{
            this.filters.current.classList.add('open');
            this.setState({
                open: true
            })
        }
    }

    render() {

        if(Object.keys(this.state.saleUnits).length > 1) {

            let options = [];
            let list = [];

            for(let i in this.state.saleUnits){
                let sale_unit = this.state.saleUnits[i];
                list.push([sale_unit.id, sale_unit.name]);
            }

            list.sort(function(a,b) {
                if (a[1] < b[1])
                    return -1;
                if (a[1] > b[1])
                    return 1;
                return 0;
            }).forEach((s, i) => {
                options.push(<option value={s[0]} key={i}>{s[1]}</option>)
            });

            return (
                <div className="unit-filters" ref={this.filters} onClick={() => this.handleClick()}>
                    <select className="unit-filters__select" onChange={e => this.props.onChange(e)}>
                        <option value="">Filtrer par unité de ventes</option>
                        {options}
                    </select>
                </div>
            )
        }
        else{
            return null
        }
    }

}

SalesUnitsFilters.contextType = GlobalContext;
