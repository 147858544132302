import React, {Component} from 'react';
import PropTypes from "prop-types";
import './Searchbar.css';


export default class NewSearchBar extends Component {

    constructor(props){
        super(props);

        this.state = {searchValue: ''};

    }

    onChange(e) {


        this.setState({searchValue: e.target.value});

        if(this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    onSubmit(e) {
        e.preventDefault();

        if(this.props.onSubmit) {
            this.props.onSubmit(this.state.searchValue);
        }
    }

    resetField() {
        this.setState({searchValue: ''});

        if(this.props.onReset) {
            this.props.onReset();
        }
    }

    render() {
        return (
            <div className="w-searchbar new-searchbar">
                <form className="w-searchbar__field" onSubmit={(e) => this.onSubmit(e)}>
                    <button/>
                    <input ref={this.inputSearch} type="search" name="searchbar" id="searchbar" placeholder={this.props.placeholder} value={this.state.searchValue} onChange={(e) => this.onChange(e)} />
                    {
                        this.state.searchValue ?
                            <div className="reset-field" onClick={() => this.resetField()}><i className={'icon icon-close'} /> </div>
                        :
                            null
                    }
                </form>
            </div>
        );
    }
}

NewSearchBar.defaultProps = {
    placeholder: 'Tapez votre recherche'
};


NewSearchBar.propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string
};