import React, {Component} from "react";
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';

import AddContactForm from 'components/Forms/AddContact';
import {SalePointService} from "../../../services/SalePointService";

export default class AddContact extends Component {

    constructor(props){
        super(props);
        this.state = {
            contacts: []
        };
        this.contact = {
            first_name: '',
            last_name: ''
        };
    }


    componentDidMount() {

        if(this.props.shop){
            SalePointService.getOne(this.props.shop.id).then(response =>{

                console.log(response.data);

                this.setState({contacts:response.data.contacts});

            });
        }
    }


    addContact(contact, index, submit = false){

        if(contact){

            let newContact = [...this.state.contacts];
            index ? newContact[index] = contact : newContact[0] = contact;

            if(submit){
                this.handleSubmit([...newContact]);
                return;
            }

            this.setState(prevState => ({
                contacts: [...newContact, this.contact]
            }));

        }
        else{
            this.setState({
                contacts: [this.contact]
            });
        }
    }

    removeContact(index){
        let contacts = [...this.state.contacts];
        contacts.splice(index, 1);
        this.setState({
            contacts: contacts
        });
    }

    handleSubmit(contacts){
        this.props.onSubmit(contacts);
    }

    render(){
        return(
            <React.Fragment>

                <h3 className="title h3">Contact(s) associé(s)</h3>

                {
                    this.props.interview ?
                        <AddContactForm once={true} handleStep={(step) => this.props.handleStep(step) } handleContact={(contact, index, submit) => this.addContact(contact, index, submit)} />
                    :
                        <React.Fragment>

                            {
                                this.state.contacts.map((contact, i) => {
                                    return(
                                        <AddContactForm contact={contact} removeContact={(index) => this.removeContact(index)} handleContact={(contact, index, submit) => this.addContact(contact, index, submit)} handleStep={(step) => this.props.handleStep(step) } count={this.state.contacts.length} index={i} key={i} />
                                    );
                                })
                            }

                            {
                                !this.state.contacts.length ?

                                    <React.Fragment>
                                        <span className="add-contact link-icon" onClick={() => this.addContact()}>Ajouter un contact</span>

                                        <div className="w-btn">
                                            <Button className={"btn--secondary"} content="Précédent" onClick={() => this.props.handleStep(1) } />
                                            <Button content="Terminer" onClick={() => this.props.onSubmit()}/>
                                        </div>
                                    </React.Fragment>

                                : null
                            }

                        </React.Fragment>
                }

            </React.Fragment>
        );
    }

}

AddContact.propTypes = {
    interview: PropTypes.bool,
    handleStep: PropTypes.func,
    addContact: PropTypes.func
};
