import React, {Component} from 'react';

import Searchbar from 'components/Searchbar/Searchbar';
import FindShop from "components/Shop/FindShop/FindShop";
import Card from "components/Card/Card";

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";
import ChooseContact from "../ChooseContact/ChooseContact";

import CreateShop from "../CreateShop/CreateShop";
import UpdateShop from "../UpdateShop/UpdateShop";
import Loader from 'components/Loader/Loader';
import PropTypes from "prop-types";

import {GlobalContext} from "providers/GlobalProvider";
import { toast } from 'react-toastify';

import NewSearchBar from "components/Searchbar/NewSearchBar";


import {SalePointService} from 'services/SalePointService';

import { User} from "app/Core";

import { confirmAlert } from 'react-confirm-alert';

import Button from 'components/Button/Button';



export default class ChooseShop extends Component {

    constructor(props){
        super(props);
        this.state = {
            shops: [],
            searchValue: '',
            contacts: [],
            createShop: false,
            loading: false,
            selectedShop: null,
            noResults: false,
            updateShopIsRequired: false
        };

        this.searchBar = React.createRef();
    }

    /*handleLoading(loading){
        this.setState({
            searchValue: '',
            shops: [],
            loading: loading
        });
    }*/

   /* handleDatas(datas, searchValue, noResults=false){
        this.setState({
            loading: false,
            shops: datas,
            searchValue: searchValue,
            noResults:  noResults
        });
    }*/

    handleShop(shop, fromShopUpdate){


        if(User.isManager) {

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <button onClick={onClose}>X</button>
                            <h1>Qui doit prendre en charge ce point de vente ?</h1>
                            <p></p>

                            <Button className={'btn--primary'}
                                    onClick={() => {

                                        onClose();
                                    }}
                            >
                                Moi
                            </Button>

                            Le commercial suivant :
                            <select>
                                <option>Choisir un commercial</option>
                            </select>

                        </div>
                    );
                }
            });

        }
        else if(User.isFdv) {

            SalePointService.addToUser(shop.id).then(response => {

                if(200 === response.status) {

                    SalePointService.getUserList().then(response => {
                        if(response.data && Array.isArray(response.data)) {
                            this.context.setUserSalePoints(response.data);
                        }
                        this.context.closeModal();
                    });
                }

            }).catch(error => {
                console.log(error);

                if(error.response && 403 === error.response.status) {
                    toast.error("Vous n\'êtes pas autorisé à selectionner ce point de ventre. Merci de contacter votre manager", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }

            });

        }



        // Before choosing a shop, we have to check if there already exists another interview for that pdv
        /*shop.checkIfWeCanCreateANewInterview().then(possible => {
            if(possible === true) {
                if(Object.keys(this.props.shop).length && Object.keys(this.props.interview).length){
                    if(this.props.shop.requiredInformationsNotMissing()) {
                        this.props.interview.pdv = shop;
                        this.props.interview.pdv.getContacts().then(_ => {
                            // The pdv has no contacts, so we need to create one
                            if(this.props.interview.pdv.contacts.length === 0) {
                                this.setState({selectedShop: shop, updateShopIsRequired: false});
                            }
                            // The pdv has some contact, so we suggest to choose one of them
                            else {
                                this.setState({selectedShop: shop, updateShopIsRequired: false});
                            }
                        });
                    } else {
                        this.setState({updateShopIsRequired: shop});
                    }
                }
                else{
                    if(shop.requiredInformationsNotMissing()) {
                        this.setState({selectedShop: shop, updateShopIsRequired: false});
                    } else {
                        this.setState({updateShopIsRequired: (typeof fromShopUpdate !== 'undefined')?false:shop});
                    }
                }
            } else if (possible === false) {
                toast.error("Un entretien a déjà été initié pour ce point de vente par une autre personne, il n’est pas possible d’en créer un nouveau.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            } else {
                toast.error("Nous ne sommes pas en mesure de vérifier si vous avez la possibilité de créer un nouvel entretien pour ce point de vente car une erreur http est survenue.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });*/


    }

    onSearchChange(query) {

    }

    onSubmitSearch(query) {

        this.setState({loading:true});

        SalePointService.search(query).then(response => {

            if (200 === response.status) {
                this.setState({shops: response.data, loading:false});
            }

        }).catch((error) => {

        });

    }

    onResetSearch() {
        this.setState({shops: []});
    }




    render() {

        const nbResults = this.state.shops.length + (this.state.shops.length > 1 ? ' résultats' : ' résultat');

        return (
            <React.Fragment>

                {
                    this.state.updateShopIsRequired ?

                        <UpdateShop shop={this.state.updateShopIsRequired} previous={() => this.handleShop(this.state.updateShopIsRequired, true)}/>

                        :

                    this.state.selectedShop ?

                    <ChooseContact interviewObj={this.props.interview} previous={() => this.setState({selectedShop: null}) } creationProcess={this.props.creationProcess} shop={this.state.selectedShop} interview={true} onFinish={() => this.props.onFinish()} />

                        :

                    this.state.createShop ?

                    <CreateShop interview={true} interviewObj={this.props.interview} previous={() => this.setState({createShop: false}) } onFinish={() => this.props.onFinish()} />

                        :

                    <React.Fragment>
                        <ModalHead className={"choose-shop"} subtitle={this.props.creationProcess ? "Nouveau point de vente" : ""} title={"Choisissez un point de vente"} text={this.state.shops.length ? nbResults : this.state.noResults ? "0 résultat" : "Rechercher un point de vente"} />

                        <div className="w-modal__content__searchbar">

                            <NewSearchBar ref={this.searchBar} placeholder={'Tapez le nom d\'un point de vente'} onChange={(query) => this.onSearchChange(query)} onSubmit={(query) => this.onSubmitSearch(query)} onReset={() => this.onResetSearch()} />


                        </div>

                        <ModalContent className={"no-padding " + (!this.state.shops.length ? "full" : "")}>

                            {this.state.loading ?

                                <Loader nbItems={5} fullWidth={true}/>

                            : null}

                            {this.state.shops.length ?

                                <React.Fragment>

                                    <div className={"w-list-shops display-row"}>

                                        <div className="w-results">
                                            <span>Sélectionnez un point de vente</span>
                                            <span>{nbResults}</span>
                                        </div>

                                        <div className="row-small">
                                            {
                                                this.state.shops.map((shop, i) => {
                                                    return <Card shop={true} data={shop} key={i} onClick={(shop) => this.handleShop(shop) }/>
                                                })
                                            }
                                        </div>

                                    </div>

                                    <div className="w-create-shop">
                                        <div className="w-create-shop__inner">
                                            <p className="txt--white">Votre point de vente n'apparait pas dans la liste ?</p>
                                            <span className="add-shop link-icon txt--white" onClick={() => this.setState({createShop: true})}>Créer un nouveau PDV</span>
                                        </div>
                                    </div>

                                </React.Fragment>

                            : !this.state.loading ?

                                <FindShop noResults={this.state.noResults} interview={true} interviewObj={this.props.interview}/>

                            : null}

                        </ModalContent>
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }

}

ChooseShop.contextType = GlobalContext;

ChooseShop.propTypes = {
    onFinish: PropTypes.func,
    shop: PropTypes.object,
    interview: PropTypes.object,
    creationProcess: PropTypes.bool
};

ChooseShop.defaultProps = {
    shop: {},
    interview: {},
    creationProcess : true
};
