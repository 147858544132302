import React, {Component} from 'react';
import Button from "../Button/Button";
import {generateStatus} from "config/status";

import {convertDate} from "helpers/string";

import './SalePointCard.scss';

import {statusKey} from "config/status";


import {User} from "app/Core";


import unlink from 'assets/images/unlink.svg';

export default class SalePointCard extends Component {

    constructor(props) {
        super(props);

        this.card = React.createRef();

    }





    expandCard(){

        if(this.props.hasOptions === false){
            return;
        }

        if(!this.card.current.classList.contains('expanded')){
            this.card.current.classList.add('expanded');
        }
        else{
            this.card.current.classList.remove('expanded');
        }

    }



    render() {
        return (
            <div onClick={e => this.props.cardAction(e, this.props.item)} className={"sale-point-card" + (this.props.shouldDisplay ? '' : ' hidden')} ref={this.card}>

                <div className="sale-point-card__wrapper">
                    <div className="sale-point-card__infos">
                        <div className={"sale-point-card__infos__status-icon " + (this.props.item.status)}/>
                        <div className="sale-point-card__infos__wrapper">
                            <div className="sale-point-card__infos__title">{this.props.item.name}</div>
                            <div className="sale-point-card__infos__content">
                                <div className="sale-point-card__infos__content__date">{this.props.item.last_modified_date ? `Modifié le ${convertDate(this.props.item.last_modified_date)}` : `Créé le ${convertDate(this.props.item.created_at)}`}</div>
                                <div className={"sale-point-card__infos__content__status " + (this.props.item.status)}>
                                    {generateStatus(this.props.item.status)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sale-point-card__location">
                        <i className="icon-place"/>
                        {this.props.item.postal_name} {this.props.item.city}
                    </div>

                    {
                        this.props.item.contact ?
                            <div className="sale-point-card__contact">
                                <i className="icon-contactclient"/>
                                {this.props.item.contact.first_name} {this.props.item.contact.last_name}
                            </div>
                            :
                            null
                    }
                    {
                        User.isSupervisor && this.props.item.status === statusKey.TODO || this.props.hasOptions === false
                            ? null
                            :

                            <i className="icon-miniarrowright sale-point-card__more-icon" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.expandCard()
                            }}/>
                    }
                    {
                        !User.isFdv && this.props.unlinkable
                        ?

                            <img src={unlink} className="unlink sale-point-card__more-icon" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.unlinkAction(this.props.item)
                            }}/>
                        :null
                    }
                </div>

                <div className="sale-point-card__more">
                    <div className="sale-point-card__more__item edit" onClick={e => {e.preventDefault(); e.stopPropagation(); this.props.editAction(e, this.props.item)}}>
                        <i className="icon-edit"/>
                    </div>
                    {
                        this.props.item.current_year_interview_id
                        ?
                        <div className="sale-point-card__more__item download" onClick={e => {e.preventDefault(); e.stopPropagation(); this.props.pdfAction(e, this.props.item)}}>
                            <i className="icon-pdf"/>
                        </div>
                        : null
                    }
                    {
                    !User.isFdv
                    ?
                        <div className="sale-point-card__more__item delete" onClick={e => {e.preventDefault(); e.stopPropagation(); this.props.deleteAction(e, this.props.item)}}>
                            <i className="icon-delete"/>
                        </div>
                    :null
                    }
                </div>

            </div>
        )
    }

}