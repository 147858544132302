import React, {Component} from 'react';

export default class NotFound extends Component {
    render() {
        return (
            <div className="Error-404">
                Not found
            </div>
        );
    }
}
