import Login from 'screens/Login/Login';
import Dashboard from 'screens/Dashboard/Dashboard';
//import Shop from 'screens/Shop/Shop';
//import Interview from 'screens/Interview/Interview';
import SalePoint from 'screens/SalePoint/SalePoint';
import Commercial from 'screens/Commercial/Commercial';
import CommercialSheet from 'screens/Commercial/CommercialSheet';

import Stats from 'screens/Stats/Stats';

export const routes = {

    home: {
        path: '/',
        component: Login,
        title: 'Connexion',
        exact: true,
        private: false
    },

    dashboard: (new function() {
        this.path = '/dashboard';
        this.component = Dashboard;
        this.title = 'Dashboard';
        this.exact = true;
        this.private = true;
        this.route = () => {
            return this.path;
        };
    }),

    salePointSheet: (new function() {
        this.path = '/points-de-vente/:id';
        this.component = SalePoint;
        this.title = 'Point de vente';
        this.exact = true;
        this.private = true;

        this.route = (id) => {
            return this.path.replace(':id', id);
        };
    }),

    questionPageSheet: (new function() {
        this.path = '/points-de-vente/:id/page/:pageId';
        this.component = SalePoint;
        this.title = 'Point de vente';
        this.exact = true;
        this.private = true;

        this.route = (salePointId, questionPageId) => {
            return this.path.replace(':id', salePointId).replace(':pageId', questionPageId);
        };
    }),

    commercial: (new function() {
        this.path = '/commerciaux-hager';
        this.component = Commercial;
        this.title = 'Commerciaux Hager';
        this.exact = true;
        this.private = true;

        this.route = (id) => {
            return this.path;
        };
    }),
    commercialSheet: (new function() {
        this.path = '/commerciaux-hager/:id';
        this.component = CommercialSheet;
        this.title = 'Commercial';
        this.exact = true;
        this.private = true;

        this.route = (id) => {
            return this.path.replace(':id', id);
        };
    }),

    stats: (new function() {
        this.path = '/statistiques';
        this.component = Stats;
        this.title = 'Statistiques';
        this.exact = true;
        this.private = true;

        this.route = (id) => {
            return this.path;
        };
    }),
    /*validationQuestionPageSheet: (new function() {
        this.path = '/points-de-vente/:id/validation';
        this.component = SalePoint;
        this.title = 'Validation';
        this.exact = true;
        this.private = true;

        this.route = (salePointId) => {
            return this.path.replace(':id', salePointId);
        };
    }),*/

    /*shop: {
        path: '/points-de-vente',
        component: Shop,
        title: 'Points de vente',
        exact: true,
        private: true
    },

    interview: {
        path: '/entretien',
        component: Interview,
        title: 'Entretien',
        exact: true,
        private: true
    }*/

};
