import {Network,Storage,Observer} from "app/Core";
import {Interviews, SalePoints} from "../Core";

export class UserAction {

    load() {

        return new Promise((resolve, reject) => {

            // Get from local storage the user infos
            Storage.get('user_datas').then(user_datas => {
                if(user_datas != null) {
                    resolve(user_datas);
                } else {
                    resolve({});
                }
            }).catch(err => {
                resolve({});
            });

        });

    }

    login(username, password) {

        return new Promise((resolve, reject) => {

            Network.post('/api/login-check/', {
                'username': username,
                'password': password
            }).then(cb => {
                if(cb.success) {
                    if(typeof cb.datas.user != 'undefined') {
                        Storage.set('user_datas', cb.datas.user).then(_ => {
                            this.datas = cb.datas.user;
                            Observer.publish('userLoggedStatusHasChanged');
                            resolve();
                        }).catch(err => {
                            reject(err);
                        });
                    } else {
                        reject(cb);
                    }
                } else {
                    reject(cb);
                }
            });

        });

    }

    logout() {

        return new Promise((resolve, reject) => {
            this.datas.token = null;
            Storage.set('user_datas', this.datas).then(_ => {
                SalePoints.logout();
                Interviews.logout();
                Observer.publish('userLoggedStatusHasChanged');
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    }

    verifyToken(userDatas) {

        return new Promise((resolve, reject) => {

            if(userDatas.token != null) {

                Network.post('/api/verify-token/', {}).then(cb => {

                    if(cb.success) {
                        if(cb.datas.user.id == userDatas.id && cb.datas.user.email == userDatas.email) {
                            resolve();
                        } else {
                            this.logout().then(resolve);
                        }
                    } else {
                        this.logout().then(resolve);
                    }

                });

            } else {
                resolve();
            }

        });

    }

}