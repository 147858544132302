import React, {Component} from 'react';

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";
import Button from "../../Button/Button";

class InterviewSuccess extends Component {

    render() {
        return(
            <React.Fragment>
                <ModalHead subtitle={"Terminé !"} title={'L’entretien a été validé !'} />
                <ModalContent className="full">
                    <p>Les résultats de l’entretien ont été enregistré, vous pourrez les retrouver dans la liste des entretiens terminés de votre dashboard.</p>
                    <div className="w-btn">
                        <Button content={"Retour au dashboard"} onClick={(e) => this.props.closeModal(e)}/>
                    </div>
                </ModalContent>
            </React.Fragment>
        );
    }

}

export default InterviewSuccess;