import React, {Component} from 'react';
import './RelatedSalePoints.css';

import PropTypes from "prop-types";

import AnimateHeight from 'react-animate-height';

import { GlobalContext } from "providers/GlobalProvider";

import {  CREATE_SHOP } from "utils/constants";

import {Observer} from "app/Core";
import SalePointCard from "../../../../components/SalePointCard/SalePointCard";
import {withRouter} from "react-router-dom";

import {routes} from "config/routes";
import {SalePointService} from "../../../../services/SalePointService";
import {confirmAlert} from "react-confirm-alert";

import Confirm from 'components/Confirm/Confirm';
import {toast} from "react-toastify";

class RelatedSalePoints extends Component {


    constructor(props) {
        super(props);

        this.state = {
          show:false,
          salePoint:this.props.salePoint
        };

        Observer.subscribe('shopCreated', () => {
            SalePointService.getOne(this.props.salePoint.id).then(response => {

                if(200 === response.status) {
                    this.setState({salePoint: response.data});
                }

            })
        });


    }

    onPdvSelected(e, pdv) {

        this.props.history.push(routes.salePointSheet.route(pdv.id));

    }



    render() {

        return (
            <div className={'RelatedSalePoints ' + (this.state.show ? 'show' : '')}>

                <AnimateHeight duration={ 500 } height={ this.state.show ? 'auto' : 0 }  className={'RelatedSalePoints__actor'}>

                    <div className={'RelatedSalePoints__head'}>
                        <h2 className={'h2'}>Points de ventes associés</h2>
                        <button onClick={(e) => { e.preventDefault(); this.context.setModalContent(CREATE_SHOP);  setTimeout(() => Observer.publish('fromDirection', this.props.salePoint.id), 100); }} className={'btn-icon'}><i className={'icon icon-add txt--secondary'} /> Ajouter un point de vente</button>
                    </div>

                    <div className={'RelatedSalePoints__body'}>

                        {
                            this.state.salePoint.pdvs
                            ?
                                this.state.salePoint.pdvs.map((item, i) => {

                                    return <SalePointCard
                                        item={item}
                                        cardAction={e => this.onPdvSelected(e, item)}
                                        hasOptions={false}
                                        shouldDisplay={true}
                                        unlinkable={true}
                                        unlinkAction={(item) => {

                                            confirmAlert({
                                                customUI: ({ onClose }) => {
                                                    console.log(item)
                                                    return (
                                                        <Confirm
                                                            onClose={onClose}
                                                            title='Êtes-vous sûr de vouloir dissocier ce point de vente de la direction ?'
                                                            buttonOk='Supprimer l’association'
                                                            onValidate={() => {
                                                                SalePointService.unlink(item.id).then((response) => {
                                                                    Observer.publish('shopCreated');

                                                                }).catch((e) => {
                                                                    toast.error("Erreur lors de la dissociation du point de vente", {
                                                                        position: toast.POSITION.BOTTOM_RIGHT,
                                                                        toastId: 'unlink_error'
                                                                    })
                                                                });

                                                                onClose();
                                                            }}
                                                            buttonKo='Annuler la dissociation'
                                                            danger={true}
                                                        >
                                                            Ce point de vente n’apparaitra plus dans la liste des points de ventes associés de cette direction.

                                                        </Confirm>

                                                    );
                                                }
                                            });
                                        }}
                                        key={i}/>
                                })
                            : null
                        }

                    </div>

                </AnimateHeight>
                <div>
                    <button onClick={(e) => { e.preventDefault(); this.setState({show: !this.state.show})}} className={'btn-icon RelatedSalePoints__toggle'}>
                        <i className={'icon icon-miniarrowbottom txt--secondary'} />
                        {this.state.show ? 'Masquer les points de ventes associés' : 'Voir les points de ventes associés' }
                    </button>
                </div>
            </div>
        );
    }
}



RelatedSalePoints.propTypes = {
    salePoint: PropTypes.object.isRequired
};

RelatedSalePoints.defaultProps = {
    salePoint: {},
};


export default withRouter(RelatedSalePoints);

RelatedSalePoints.contextType = GlobalContext;