import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

export default class Score extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (

            <div className={'Score question question--bg'}>
                <div className={'Score__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Score__control question__control">

                            {
                                this.props.delete
                                ?
                                <button className={'Score__delete btn-icon txt--danger'} onClick={(e) => {e.preventDefault(); this.props.onDelete()} }><i className="icon icon-delete" />Supprimer</button>
                                : null
                            }

                        {
                            [1,2,3].map((item, i) => {
                                return <div className={'Score__input-w'} key={i}>
                                    <input  disabled={User.isSupervisor} className={'Score__input'} id={`${this.props.name}_${item}`} checked={this.props.value === item} type='radio' value={item} name={this.props.name} onClick={() => {if(this.props.onClick) { this.props.onClick(item)} }} onChange={() => this.props.onChange(item)} onBlur={this.props.handleBlur} />
                                    <label className={'Score__label'} htmlFor={`${this.props.name}_${item}`}><span>{item}</span></label>
                                </div>
                            })
                        }
                    </div>

                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}


            </div>
        );
    }

}

Score.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.number,

};

Score.defaultProps = {

};
