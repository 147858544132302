import React from 'react';
import './Dashboard.css';

import Header from 'components/Header/Header';
import Navigation from 'components/Navigation/Navigation';
import ButtonToggle from 'components/ButtonToggle/ButtonToggle';
import Button from 'components/Button/Button';

import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";

import {SalePointService} from 'services/SalePointService';


import LoaderSpiral from "components/LoaderSpiral/LoaderSpiral";


import {statusKey} from "config/status";
import NewSearchBar from "components/Searchbar/NewSearchBar";

import {sanitizeForSearch} from "helpers/string";

import _ from "lodash";

import { confirmAlert } from 'react-confirm-alert';
import Confirm from 'components/Confirm/Confirm';


import { CHOOSE_SHOP } from "utils/constants";
import ShopCount from "../../components/Shop/ShopCount/ShopCount";
import SalesUnitsFilters from "../../components/Filters/SalesUnitsFilters/SalesUnitsFilters";
import SalePointCard from "../../components/SalePointCard/SalePointCard";
import {withRouter} from "react-router-dom";

import {routes} from "config/routes";

import {UPDATE_SHOP} from "utils/constants";

import {Observer} from "app/Core";

import {User} from "../../app/Core";
import {InterviewService} from "../../services/InterviewService";



class Dashboard extends PrivateComponent {

    constructor(props) {

        super(props);
        this.state = {
            isLoaded: false,
            salePoints: [],
            salePointsLoaded : false,
            filter: null,
            searchQuery: null,
            searchList:[],
            saleUnitFilter:null,
           // hasMore:true,
           // page:1
        };

        this.searchBar = React.createRef();

        Observer.subscribe('shopDeleted', () => {
            this.setState({salePointsLoaded: false});
            this.getUserSalePoints();
        });
        Observer.subscribe('updateContact', () => {
            this.setState({salePointsLoaded: false});
            this.getUserSalePoints();
        });
        Observer.subscribe('leave', () => {
            this.setState({salePointsLoaded: false});
            this.getUserSalePoints();
        });

    }

    getUserSalePoints() {

        SalePointService.getUserList().then(response => {
            if(response.data && Array.isArray(response.data)) {

                /*if(response.data.length < 150){
                    this.setState({hasMore:false});
                }

                let salePoints = this.context.userSalePoints.concat(response.data);

                this.setState({page:page});*/

                this.context.setUserSalePoints(response.data)
            }
            this.setState({salePointsLoaded: true});
        });

    }

    componentDidMount() {

        if(!this.context.userSalePoints.length) {
            this.getUserSalePoints();
        }
        else{
            this.setState({salePointsLoaded: true});
        }


        window.scrollTo(0, 0);

    }


    deleteSalePoint(e,salePoint) {

        e.preventDefault();
        e.stopPropagation();


        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <Confirm
                        onClose={onClose}
                        title='Êtes-vous sûr de vouloir supprimer ce point de vente ?'
                        buttonOk='Supprimer le point de vente'
                        danger={true}
                        onValidate={() => {
                            this.setState({salePointsLoaded: false});
                            SalePointService.delete(salePoint.id).then(response => {

                                if(200 === parseInt(response.status) || 204 === parseInt(response.status)) {
                                    this.getUserSalePoints();
                                    return;
                                }
                                this.setState({salePointsLoaded: false});

                            }).catch((error) => {
                                this.setState({salePointsLoaded: true});
                                console.log(error);
                            });
                            onClose();
                        }}
                        buttonKo='Annuler la suppression'
                    >
                        Ce point de vente pourra être de nouveau accessible en contactant l’administrateur de Hager Driver
                    </Confirm>

                );
            }
        });

    }


    salePointsList() {

        if(!this.state.salePointsLoaded) {
            return  <LoaderSpiral />;
        }

        if(this.context.userSalePoints && this.context.userSalePoints.length) {

            let useList = this.context.userSalePoints;

            if(this.state.searchQuery) {

                useList = this.state.searchList;

                if(!useList.length) {
                    return <div className={'no-result'}>
                                <p><strong>Désolé, aucun résultat ne correspond à votre mot clé</strong></p>
                                <p>Vérifiez l’orthographe de votre recherche ou utilisez un mot clé plus général</p>
                                <div className={'no-result__btns'}>
                                    <Button onClick={() => this.context.setModalContent(CHOOSE_SHOP)} className={'btn--primary'} content={'Créer un nouveau point de vente'} />
                                    <Button onClick={() => this.searchBar.current.resetField()} className={'btn--secondary'} content={'Retour à tous mes entretiens'} />
                                </div>
                            </div>
                }

            }


            return (
                <div className="list">

                    {
                        useList.map((item, i) =>  {

                            let displayFilter = !this.state.filter || (this.state.filter && this.state.filter === item.status);
                            let displayUnit = true;

                            if (this.state.saleUnitFilter) {
                                displayUnit = item.sales_unit && item.sales_unit.id === this.state.saleUnitFilter;
                            }

                            return (
                                <SalePointCard
                                    item={item}
                                    cardAction={e => this.onPdvSelected(e, item)}
                                    editAction={e => {
                                        this.context.setShop(UPDATE_SHOP, item, {}, false )
                                    }}
                                    deleteAction={e => this.deleteSalePoint(e,item)}
                                    pdfAction={(e, item) => {
                                        if(item.current_year_interview_id){

                                            window.open(
                                                InterviewService.getPdfLink(item.current_year_interview_token), "_blank");


                                        }
                                    }}

                                    shouldDisplay={displayFilter && displayUnit}
                                    key={i}/>
                            );
                        })
                    }
                    {/*
                        this.state.hasMore
                        ?
                        <div className={'Dashboard__foot'}>
                            <button className={'btn-icon '} onClick={(e) => { e.preventDefault(); console.log(this.state.page); this.getUserSalePoints(this.state.page + 1)}}>
                                <i className={'icon icon-add txt--secondary'}/>
                                Charger plus de points de ventes
                            </button>
                        </div>
                        : null
                   */ }
                </div>
            );
        }

        return <div className={'no-result'}>
                    <p><strong>Désolé, aucun point de vente n'est associé à ce compte.</strong></p>

                </div>
    }


    onPdvSelected(e, pdv) {

        if(pdv.status === statusKey.TODO && User.isSupervisor){
            return;
        }

        this.props.history.push(routes.salePointSheet.route(pdv.id));

    }

    onFilter(e, filter) {

        let value = filter.value;
        if(this.state.filter && value === this.state.filter){
            value = null;
        }

        this.setState({filter:value});

    }

    filters() {

        let filters = [
            {value:statusKey.TODO, label:'À faire'},
            {value:statusKey.IN_PROGRESS, label:'En cours'},
            {value:statusKey.COMPLETED, label:'Réalisé'},
        ]

        return <div className="status-filters">
            {
                filters.map((item,i) => {
                    return <div className={'status-filters__filter ' + (item.value) + (this.state.filter === item.value ? ' active':'')} onClick={(e) => this.onFilter(e, item)} key={i}>{item.label}</div>
                })
            }
        </div>;

    }

    onFilterBySaleUnit(e) {
        this.setState({saleUnitFilter: e.target.value ? parseInt(e.target.value) : null});
    }

    onResetSearch() {
        this.setState({searchQuery:null,searchList: []});
    }


    onSearchChange(query) {
        this.onSubmitSearch(query);
    }

    onSubmitSearch(query) {

       let searchResults = _.filter(this.context.userSalePoints, (salePoint) => {
           const useQuery = sanitizeForSearch(query);
            return(
                (salePoint.name && sanitizeForSearch(salePoint.name).indexOf(useQuery) !== -1)
                || (salePoint.city && sanitizeForSearch(salePoint.city).indexOf(useQuery) !== -1)
                || (salePoint.postal_name && sanitizeForSearch(salePoint.postal_name).indexOf(useQuery) !== -1)
                || (salePoint.contact  && sanitizeForSearch(salePoint.contact.first_name).indexOf(useQuery) !== -1)
            )
        });

       this.setState({searchQuery:query,searchList: searchResults});
    }



    render() {

        return (
            <div className="Dashboard">

                <Header className="dashboard" subtitle="Mes entretiens" title="Avancement des entretiens sur ma région">
                    {
                        !this.state.salePointsLoaded
                        ?
                            <LoaderSpiral />
                        :
                        (this.context.userSalePoints.length > 0 ?
                                <ShopCount salesPoints={this.context.userSalePoints} />
                            : null
                        )
                    }
                </Header>



                <div className="w-content">
                    <div className="container">

                        {
                            this.context.userSalePoints.length ?
                                <NewSearchBar ref={this.searchBar}
                                              placeholder={'ex. Entreprise, CP, Ville, Interlocuteur...'}
                                              onChange={(query) => this.onSearchChange(query)}
                                              onSubmit={(query) => this.onSubmitSearch(query)}
                                              onReset={() => this.onResetSearch()} />
                            :
                                null
                        }

                        <div className="section-top">
                            <h3 className="h3 title">{this.state.searchQuery ? 'Résultats pour “'+ this.state.searchQuery +'”' : 'Vos points de ventes'}</h3>

                            <div className="section-top__filters">
                                {
                                    this.filters()
                                }
                                {
                                    this.context.userSalePoints.length ?
                                        <SalesUnitsFilters onChange={(e) => this.onFilterBySaleUnit(e)} units={this.context.userSalePoints}/>
                                    :
                                        null
                                }
                            </div>

                            {
                                this.salePointsList()
                            }

                        </div>

                    </div>
                </div>

                <ButtonToggle/>

                {
                    <Navigation/>
                }


            </div>
        );
    }
}

export default withRouter(Dashboard);

Dashboard.contextType = GlobalContext;
