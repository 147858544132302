import React, {Component} from 'react';
import PropTypes from "prop-types";

import {fileUrl} from "config/network";

export default class File extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        //temporarily disabled, please remove to restore
        return <React.Fragment />;

        return (

            <div className={'File question question--bg'}>
                <div className={'File__in question__in'}>
                    <a className={'File__link'} href={fileUrl(this.props.question.file)} target={"_blank"}>
                        <i className={'icon icon-pdf'} />
                        {this.props.question.name}
                    </a>
                    <i className={'icon arrow icon-miniarrowright'} />
                </div>
            </div>
        );
    }

}

File.propTypes = {
    question: PropTypes.object.isRequired,

};

File.defaultProps = {

};