import {Network,Storage,Observer} from "app/Core";
import {InterviewsAction} from "./InterviewsAction";
import {Interview} from "./Interview";
import {SalePoints, User} from "../Core";

export class InterviewsClass extends InterviewsAction{

    list = null;
    survey = null;

    getSurveyForm() {

        return new Promise((resolve, reject) => {

            if(this.survey === null) {

                this.getRemoteSurveyForm().then(survey => {
                    survey.sort(this.sortByPosition);
                    this.survey = survey;
                    resolve(this.survey);
                });

            } else {

                resolve(this.survey);

            }

        });

    }

    sortByPosition(a,b) {
        if (a.position < b.position)
            return -1;
        if (a.position > b.position)
            return 1;
        return 0;
    }

    getList(pdv_id) {

        return new Promise((resolve, reject) => {

            if(this.list === null || typeof pdv_id !== 'undefined') {

                this.getRemoteList(pdv_id).then(interviews => {
                    if(typeof pdv_id === 'undefined') {
                        this.list = [];
                    }
                    if(interviews.length > 0) {
                        interviews.forEach((datas, index) => {
                            // Is the interview already in the list ?
                            let indexFound = this.list.findIndex(interview => {
                                return interview.id === datas.id;
                            });
                            if(indexFound >= 0) {
                                this.list[indexFound] = new Interview(datas);
                            } else {
                                this.list.push(new Interview(datas));
                            }
                            // Resolve after the last one
                            if(index === (interviews.length - 1)) {
                                resolve(this.filterList(pdv_id));
                            }
                        });
                    } else {
                        resolve(this.filterList(pdv_id));
                    }
                });

            } else {

                resolve(this.filterList(pdv_id));
            }

        });

    }

    filterList(pdv_id) {
        if(typeof pdv_id != 'undefined') {
            return this.list.filter(interview => {
                return interview.pdv_id === pdv_id;
            });
        }
        else {
            return this.list;
        }
    }

    start() {

        return new Promise((resolve, reject) => {

            if(User.isLogged) {
                this.getList().then(resolve);
            } else {
                resolve();
            }

        });

    }

    create() {

        return new Interview();

    }

    remove(interview) {

        return new Promise((resolve, reject) => {

            if(!isNaN(interview.id)) {

                this.saveRemoving(interview.id).then(_ => {
                    this.list.filter((value, index) => {
                        return value.id != interview.id;
                    });
                    resolve();
                }).catch(cb => {
                    reject(cb);
                });

            } else {
                reject({
                    'success' : false,
                    'error' : 'id_not_numeric'
                });
            }

        });

    }

    getInProgress() {

        return new Promise((resolve, reject) => {

            this.getList().then(list => {

                resolve(list.filter(interview => {
                    return interview.status === 0 && interview.userId === User.id;
                }));

            });

        });

    }

    getFinished() {

        return new Promise((resolve, reject) => {

            this.getList().then(list => {

                resolve(list.filter(interview => {
                    return interview.status === 1 && interview.userId === User.id;
                }));

            });

        });

    }



    find(id) {

        return (this.list !== null) ? this.list.find(interview => {
            return interview.id === id;
        }) : null ;

    }

    logout() {
        this.list = null;
        this.survey = null;
    }

}