import {Network,Storage,Observer} from "app/Core";

export class InterviewsAction {

    getRemoteSurveyForm() {

        return new Promise((resolve, reject) => {

            Network.post('/api/survey/form/', {})
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.survey);
                    } else {
                        resolve([]);
                    }
                });

        });

    }

    getRemoteList(pdv_id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/interviews/', {
                pdv_id : pdv_id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.interviews);
                    } else {
                        resolve([]);
                    }
                });

        });

    }

    saveRemoving(id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/interview/remove/', {
                'id' : id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve();
                    } else {
                        resolve(cb);
                    }
                });

        });

    }

}