import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";

export default class Product extends Component {


    constructor(props) {
        super(props);

        this.available = [
            'J0',
            'J+1',
            'J+2',
            'J+3',
            'Pas stocké'
        ];

        this.labels = {
            0:'Non',
            1:'Oui'
        }
    }


    render() {



        return (

            <div className={'Product question--bg'}>

                <div className={'Product__in'}>

                    <div className={'Product__label'}>
                        <label>{this.props.label}</label>
                    </div>
                    <div className={'Product__mid'}>
                        <select disabled={User.isSupervisor} name={`${this.props.name}[available]`} value={this.props.value && this.props.value['available'] ? this.props.value['available'] : ''} onChange={this.props.onChange} onBlur={this.props.handleBlur}>
                            <option value=""></option>
                            {
                                this.available.map((item, i) => {
                                    return <option key={i} value={item}>{item}</option>
                                })
                            }
                        </select>

                    </div>
                    <div className={'Product__last'}>
                        {
                            this.props.showMerch
                            ?
                                [1,0].map((item, i) => {
                                    return (
                                    <div className={'inline checkbox-row'} key={i}>
                                        <label className={'checkbox-w'}>
                                            <input disabled={User.isSupervisor} id={`${this.props.name}_merch_${item}`} checked={this.props.value && typeof this.props.value['merch'] !== 'undefined' && parseInt(this.props.value['merch']) === item} type='radio' value={item} name={`${this.props.name}[merch]`} onChange={() => this.props.onChangeMerch(item)}  />
                                            <i className="icon icon-check mark mark--radio"  />
                                        </label>
                                        <label htmlFor={`${this.props.name}_merch_${item}`}><span>{this.labels[item]}</span></label>
                                    </div>
                                    )
                                })
                            : null
                        }
                    </div>
                </div>

            </div>
        );
    }

}

Product.propTypes = {


};

Product.defaultProps = {

};