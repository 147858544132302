import {Network} from "app/Core";



export class QuestionService {


    static getQuestionPageTypes() {

        return Network.get('/api/question-page-types');

    }



}
