import React from 'react';

import './Stats.css';


import Header from 'components/Header/Header';

import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";

import {withRouter} from "react-router-dom";


import Navigation from 'components/Navigation/Navigation';
import {StatsService} from "services/StatsService";
import {toast} from "react-toastify";


import { HorizontalBar, Doughnut } from 'react-chartjs-2';

import 'chartjs-plugin-datalabels';

import LoaderSpiral from "components/LoaderSpiral/LoaderSpiral";

import {User} from "app/Core";

import MultiSelect from "@khanacademy/react-multi-select";

class Stats extends PrivateComponent {

    constructor(props) {
        super(props);


        this.tabs = {
            REGION: 'region',
            DISTRI: 'distri',
            FRANCE: 'france',
            COMMERCIAL: 'salers'
        };

        this.tabsLabel = [
            {key:this.tabs.REGION, label: 'Unité de vente'},
            {key:this.tabs.DISTRI, label: 'Distributeur'},
            {key:this.tabs.FRANCE, label: 'France'},
            {key:this.tabs.COMMERCIAL, label: 'Commercial'},
        ];

        this.state = {
            tab:this.tabs.REGION,


            filterRegion:[],
            filterDistri:[],
            filterCommercial:[],

            selectedRegion:[],
            selectedDistri:[],
            selectedCommercial:[],

        };
        this.state[this.tabs.REGION] = {};
        this.state[this.tabs.DISTRI] = {};
        this.state[this.tabs.FRANCE] = {};
        this.state[this.tabs.COMMERCIAL] = {};


    }



    getRegionsDatas() {

        if(!Object.keys(this.state[this.tabs.REGION]).length) {

            StatsService.getRegions().then(response => {
                let data = {};
                data[this.tabs.REGION] = response.data;
                data.filterRegion = this.getFiltersFromDatas(response.data);

                this.setState(data);


            }).catch(e => {
                toast.error("Erreur de connexion pour récupérer les données", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'region_error'
                });
            })
        }
    }
    getDistributorDatas() {

        if(!Object.keys(this.state[this.tabs.DISTRI]).length) {

            StatsService.getDistributor().then(response => {
                let data = {};
                data[this.tabs.DISTRI] = response.data;
                data.filterDistri = this.getFiltersFromDatas(response.data);
                this.setState(data);

            }).catch(e => {
                toast.error("Erreur de connexion pour récupérer les données", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'region_error'
                });
            })
        }
    }

    getFranceDatas() {

        if(!Object.keys(this.state[this.tabs.FRANCE]).length) {

            StatsService.getFrance().then(response => {
                let data = {};
                data[this.tabs.FRANCE] = response.data;
                this.setState(data);

            }).catch(e => {
                toast.error("Erreur de connexion pour récupérer les données", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'region_error'
                });
            })
        }
    }




    getCommercialDatas() {

        if(!Object.keys(this.state[this.tabs.COMMERCIAL]).length) {

            StatsService.getUser().then(response => {
                let data = {};
                data[this.tabs.COMMERCIAL] = response.data;

                data.filterCommercial = this.getFiltersFromDatas(response.data);
                this.setState(data);



            }).catch(e => {
                toast.error("Erreur de connexion pour récupérer les données", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'region_error'
                });
            })
        }

    }


    getFiltersFromDatas(values) {

        let filters = [];

        for(let key in values){
            let stat = values[key];
            filters.push({value:stat.name, label:stat.name})
        }
        return  filters;
    }


    onChangeTab(e, item) {
        e.preventDefault();
        this.setState({tab: item.key});
    }

    getTabs(){
        return <div className="status-filters">
            {
                this.tabsLabel.map((item,i) => {
                    if(User.isFdv && item.key === this.tabs.COMMERCIAL){
                        return null;
                    }
                    return <div className={'status-filters__filter ' + (item.key) + (this.state.tab === item.key ? ' active':'')} onClick={(e) => this.onChangeTab(e, item)} key={i}>{item.label}</div>
                })
            }
        </div>;
    }


    getContent(){

        switch (this.state.tab) {
            case this.tabs.REGION:
                return this.buildRegion();
            case this.tabs.DISTRI:
                return this.buildDistri();
            case this.tabs.FRANCE:
                return this.buildFrance();
            case this.tabs.COMMERCIAL:
                return this.buildCommercial();
        }
        return <React.Fragment />
    }


    filterValues(filters, tab) {

        let stats = null;
        let nbItems = 0;
        if(filters.length && this.state[tab]){
            stats = {};
            for(let value in filters){
                let regionName = filters[value];

                for(let id in this.state[tab]){
                    let itemData = this.state[tab][id];
                    if(itemData.name === regionName){
                        stats[id] = itemData;
                        nbItems++;
                    }
                }
            }
        }

        return stats === null ? this.state[tab] : stats;
    }


    buildRegion() {
        this.getRegionsDatas();
        let results = null;
        if(this.state.selectedRegion.length && this.state[this.tabs.REGION]){
            results = this.filterValues(this.state.selectedRegion, this.tabs.REGION);
        }

        return this.buildHorizontalBar(results === null ? this.state[this.tabs.REGION] : results, this.tabs.REGION + (results !== null ? Object.keys(results).length : ''));
    }


    buildDistri(){
        this.getDistributorDatas();

        let results = null;
        if(this.state.selectedDistri.length && this.state[this.tabs.DISTRI]){
            results = this.filterValues(this.state.selectedDistri, this.tabs.DISTRI);
        }

        return this.buildHorizontalBar(results === null ? this.state[this.tabs.DISTRI] : results,  this.tabs.DISTRI  +  (results !== null ? Object.keys(results).length : ''));
    }

    buildCommercial() {
        this.getCommercialDatas();

        let results = null;
        if(this.state.selectedCommercial.length && this.state[this.tabs.COMMERCIAL]){
            results = this.filterValues(this.state.selectedCommercial, this.tabs.COMMERCIAL);
        }

        return this.buildHorizontalBar(results === null ? this.state[this.tabs.COMMERCIAL] : results, this.tabs.COMMERCIAL  + (results !== null ? Object.keys(results).length : ''));
    }

    buildFrance(){
        this.getFranceDatas();

        if(Object.keys(this.state[this.tabs.FRANCE]).length) {


            const data = {

                labels: [
                    'À faire',
                    'En cours',
                    'Réalisé'
                ],
                datasets: [{
                    data: [
                        this.state[this.tabs.FRANCE].todo,
                        this.state[this.tabs.FRANCE].in_progress,
                        this.state[this.tabs.FRANCE].completed],
                    backgroundColor: [
                        '#00AAE1',
                        '#E46529',
                        '#27C55D'
                    ],

                }]
            };


            return <div>

                <Doughnut
                    data={data}
                    width={500}
                    height={500}
                    options={{
                        plugins: {
                            datalabels: {
                                color: '#ffffff',
                                display: function(context) {
                                    return context.dataset.data[context.dataIndex] >= 1;
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        showAllTooltips: true,
                        legend: {
                            position:'right'
                        },

                    }}

                />
            </div>
        }
        else{
            return <LoaderSpiral/>
        }
    }

    buildHorizontalBar(stats, key) {

        if(Object.keys(stats).length) {
            let labels = [];
            let todos = [];
            let inProgress = [];
            let completed = [];

            for(let key in stats){
                let stat = stats[key];
                labels.push(stat.name);
                todos.push(stat.todo);
                inProgress.push(stat.in_progress);
                completed.push(stat.completed);
            }

            const data = {
                type: 'horizontalBar',
                labels: labels,
                datasets: [
                    {
                        label: 'À faire',
                        backgroundColor: '#00AAE1',
                        borderWidth: 0,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        data: todos
                    },
                    {
                        label: 'En cours',
                        backgroundColor: '#E46529',
                        borderWidth: 0,
                        data: inProgress
                    },
                    {
                        label: 'Réalisé',
                        backgroundColor: '#27C55D',
                        borderWidth: 0,
                        data: completed
                    }
                ]
            };

            const height = (labels.length < 5 ? 90 : 60) * labels.length;


            return <div>

                <HorizontalBar
                    key={key}
                    redraw={true}
                    data={data}
                    width={100}
                    height={height}
                    options={{
                        plugins: {
                            datalabels: {
                                color: '#ffffff',
                                display: function(context) {
                                    return context.dataset.data[context.dataIndex] >= 1;
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        showAllTooltips: true,
                        legend: {
                            position:'right'
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,

                            }],
                            yAxes: [{
                                stacked: true,

                            }]
                        }
                    }}
                />
            </div>
        }
        else{
            return <LoaderSpiral/>
        }
    }

    onSelectFilter(selected){


        if(this.state.tab == this.tabs.REGION){
            this.setState({selectedRegion:selected});
        }
        else if(this.state.tab == this.tabs.DISTRI){
            this.setState({selectedDistri:selected});
        }
        else if(this.state.tab == this.tabs.COMMERCIAL){
            this.setState({selectedCommercial:selected});
        }
    }



    render() {


        let filters = null;
        let selected = null;
        let title = null;

        let filterPlaceholder = '';

        if(this.state.tab == this.tabs.REGION){
            filters = this.state.filterRegion;
            selected = this.state.selectedRegion;
            filterPlaceholder = 'Choisissez une ou plusieurs unités de vente';

            title = 'Filtrer les unités de vente';
        }
        else if(this.state.tab == this.tabs.DISTRI){
            filters = this.state.filterDistri;
            selected = this.state.selectedDistri;
            filterPlaceholder = 'Choisissez un ou plusieurs distributeurs';

            title = 'Filtrer les distributeurs';
        }
        else if(this.state.tab == this.tabs.COMMERCIAL){
            filters = this.state.filterCommercial;
            selected = this.state.selectedCommercial;

            filterPlaceholder = 'Choisissez un ou plusieurs commerciaux';

            title = 'Filtrer les commerciaux';

        }


        return (
            <div className="Commercial Stats Dashboard">

                <Header
                    className="dashboard Stats__head"
                    subtitle={'Statistiques'}
                    showSave={false}
                    title={'Graphiques d’avancemment'}>
                </Header>

                <div className="w-content">
                    <div className="container">

                        <div className="section-top">
                            <div className="section-top__filters">
                                {this.getTabs()}
                            </div>

                        </div>

                        <div className="stats-top">
                            {
                                filters
                                    ? <h2 className={'h2'}>{title}</h2>
                                    : null
                            }
                            {
                                filters
                                    ?
                                    <MultiSelect
                                        options={filters}
                                        selected={selected}
                                        onSelectedChanged={selected => {this.onSelectFilter(selected)}}
                                        overrideStrings={{
                                            selectSomeItems: filterPlaceholder,
                                            allItemsAreSelected: "Tous les éléments sont selectionnés",
                                            selectAll: "Tout selectionner",
                                            search: "Rechercher",
                                        }}
                                    />
                                    : null
                            }
                        </div>


                        <div className="list">

                            {this.getContent()}

                        </div>

                    </div>

                </div>

                <Navigation/>

            </div>
        );
    }
}

export default withRouter(Stats);

Stats.contextType = GlobalContext;
