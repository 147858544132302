import React from 'react';

import './Commercial.css';

import '../Dashboard/Dashboard.css';


import Header from 'components/Header/Header';

import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";

import {withRouter} from "react-router-dom";

import NewSearchBar from "components/Searchbar/NewSearchBar";


import Navigation from 'components/Navigation/Navigation';
import {UserServices} from "services/UserServices";

import {sanitizeForSearch} from "helpers/string";

import _ from "lodash";

import Button from 'components/Button/Button';

import LoaderSpiral from "components/LoaderSpiral/LoaderSpiral";
import SalePointCard from "../../components/SalePointCard/SalePointCard";

import {statusKey} from "config/status";

import {routes} from "config/routes";
import {confirmAlert} from "react-confirm-alert";

import Confirm from 'components/Confirm/Confirm';

import {User} from "app/Core";
import {toast} from "react-toastify";

class CommercialSheet extends PrivateComponent {

    constructor(props) {
        super(props);


        this.state = {
            user : null,
            saleUnitFilter:null,
            searchList:[],
            searchQuery:null,
            userLoaded:false,
            filter: null,
            salesUnits : []
        }

        this.searchBar = React.createRef();

    }



    componentDidMount(){

        this.getUser();
    }


    getUser() {
        UserServices.getUser(this.props.match.params.id).then(response => {
            if (200 === response.status) {
                this.setState({user:response.data, userLoaded:true});

            }
        });
    }




    onResetSearch() {
        this.setState({searchQuery:null,searchList: []});
    }


    onSearchChange(query) {
        this.onSubmitSearch(query);
    }

    onSubmitSearch(query) {

        let searchResults = _.filter(this.state.user.filtered_pdvs, (salePoint) => {
            const useQuery = sanitizeForSearch(query);
            return(
                (salePoint.name && sanitizeForSearch(salePoint.name).indexOf(useQuery) !== -1)
                || (salePoint.city && sanitizeForSearch(salePoint.city).indexOf(useQuery) !== -1)
                || (salePoint.postal_name && sanitizeForSearch(salePoint.postal_name).indexOf(useQuery) !== -1)
                || (salePoint.contact  && sanitizeForSearch(salePoint.contact.first_name).indexOf(useQuery) !== -1)
            )
        });

        this.setState({searchQuery:query,searchList: searchResults});
    }

    onFilterBySaleUnit(e) {
        this.setState({saleUnitFilter: e.target.value ? parseInt(e.target.value) : null});
    }


    getList() {

        if(!this.state.userLoaded) {
            return  <LoaderSpiral />;
        }

        if(this.state.user.filtered_pdvs && this.state.user.filtered_pdvs.length) {

            let useList = this.state.user.filtered_pdvs;

            if(this.state.searchQuery) {

                useList = this.state.searchList;

                if(!useList.length) {
                    return <div className={'no-result'}>
                        <p><strong>Désolé, aucun résultat ne correspond à votre mot clé</strong></p>
                        <p>Vérifiez l’orthographe de votre recherche ou utilisez un mot clé plus général</p>
                        <div className={'no-result__btns'}>
                            <Button onClick={() => this.searchBar.current.resetField()} className={'btn--secondary'} content={'Retour à tous les points de vente'} />
                        </div>
                    </div>
                }

            }


            return (
                <div className="list">
                    {
                        useList.map((item, i) =>  {

                            let displayFilter = !this.state.filter || (this.state.filter && this.state.filter === item.status);
                            let displayUnit = true;

                            if (this.state.saleUnitFilter) {
                                displayUnit = item.sales_unit && item.sales_unit.id === this.state.saleUnitFilter;
                            }

                            return (
                                <SalePointCard
                                    item={item}
                                    cardAction={e => this.onPdvSelected(e, item)}
                                    shouldDisplay={displayFilter && displayUnit}
                                    hasOptions={false}
                                    key={i}/>
                            );
                        })
                    }
                </div>
            );
        }

        return <React.Fragment />


    }

    onPdvSelected(e, pdv) {

        this.props.history.push(routes.salePointSheet.route(pdv.id));

    }

    filters() {

        let filters = [
            {value:statusKey.TODO, label:'À faire'},
            {value:statusKey.IN_PROGRESS, label:'En cours'},
            {value:statusKey.COMPLETED, label:'Réalisé'},
        ]

        return <div className="status-filters">
            {
                filters.map((item,i) => {
                    return <div className={'status-filters__filter ' + (item.value) + (this.state.filter === item.value ? ' active':'')} onClick={(e) => this.onFilter(e, item)} key={i}>{item.label}</div>
                })
            }
        </div>;

    }

    onFilter(e, filter) {

        let value = filter.value;
        if(this.state.filter && value === this.state.filter){
            value = null;
        }

        this.setState({filter:value});

    }

    deleteUser() {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <Confirm
                        className={'form'}
                        onClose={onClose}
                        title='Êtes-vous sûr de vouloir supprimer ce commercial ?'
                        danger={true}
                        onValidate={() => {
                            onClose();
                            UserServices.delete(this.state.user.id).then(response => {

                                if(204 === response.status) {
                                    this.props.history.replace(routes.commercial.route());

                                }


                            }).catch(e => {

                                toast.error("Erreur lors de la suppression du profil.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    toastId: 'error_delete_profile'
                                });

                                onClose();

                            })

                        }}
                        buttonOk='Supprimer'
                        buttonKo='Annuler la suppression'
                    >
                        Ce commercial n'aura plus accès à la plateforme.

                    </Confirm>
                );
            }
        });

    }


    render() {


        return (
            <div className="Dashboard">

                <Header
                    className="dashboard Commercial__head"
                    subtitle={'Commercial'}
                    showSave={false}
                    title={this.state.user ? this.state.user.firstname + ' ' + this.state.user.lastname : ''}>

                    <div className="shop-count">

                        <div className="shop-count__counter">
                            <div className="shop-count__counter__number">{ this.state.user ? this.state.user.interview_status.todo : 0 }</div>
                            <div className="shop-count__counter__label"> {'à faire'}</div>
                        </div>

                        <div className="shop-count__counter">
                            <div className="shop-count__counter__number">{ this.state.user ? this.state.user.interview_status.in_progress : 0 }</div>
                            <div className="shop-count__counter__label"> {'en cours'}</div>
                        </div>

                        <div className="shop-count__counter">
                            <div className="shop-count__counter__number">{ this.state.user ? this.state.user.interview_status.completed : 0 }</div>
                            <div className="shop-count__counter__label"> {this.state.user && this.state.user.interview_status.completed > 1 ? 'réalisés' : 'réalisé'}</div>
                        </div>


                    </div>

                </Header>

                <div className="w-content">
                    <div className="container">

                        <NewSearchBar ref={this.searchBar}
                                      placeholder={'ex. Entreprise, CP, Ville, Interlocuteur...'}
                                      onChange={(query) => this.onSearchChange(query)}
                                      onSubmit={(query) => this.onSubmitSearch(query)}
                                      onReset={() => this.onResetSearch()} />

                        <div className="section-top">
                            <h3 className="h3 title">{this.state.searchQuery ? 'Résultats pour “'+ this.state.searchQuery +'”' : 'Points de ventes'}</h3>

                            <div className="section-top__filters">
                            {
                                this.filters()
                            }

                            </div>
                        </div>

                        <div>

                            { this.getList()}

                        </div>
                        {
                            (User.isManager || User.isAdmin) && this.state.userLoaded
                            ?
                            <div className={'Commercial__foot'}>
                                <button onClick={(e) => { e.preventDefault(); this.deleteUser(); }} className={'btn-icon txt--danger'}>
                                    <i className={'icon icon-delete'}/>
                                    Supprimer le profil
                                </button>
                            </div>
                            :null
                        }
                    </div>

                </div>

                <Navigation/>

            </div>
        );
    }
}

export default withRouter(CommercialSheet);

CommercialSheet.contextType = GlobalContext;