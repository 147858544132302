import {Network} from "app/Core";



export class UserServices {


    static getFdvs(withMe = false) {

        return Network.get('/api/fdvs' + (withMe ? '?withme=1' : ''));

    }

    static getUser(id) {

        return Network.get('/api/fdvs/' + id);

    }

    static delete(id) {

        return Network.get('/api/fdvs/' + id + '/delete');

    }

}
