import * as localforage from "localforage";

export class Storage {

    constructor() {

        this.localForage = localforage;

    }

    get(name) {
        return this.localForage.getItem(name);
    }

    set(name, value) {
        return this.localForage.setItem(name, value);
    }

}