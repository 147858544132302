import ReactObserver from 'react-event-observer';
import {Network as NetworkUtil} from "utils/network";
import {Storage as StorageUtil} from "utils/storage";
import {UserClass} from "app/User/User";
import {InterviewsClass} from "app/Interview/Interviews";
import {Component} from "react";
import {SalePointsClass} from "app/SalePoint/SalePoints";
import {routes} from "config/routes";


let Network = new NetworkUtil();
let Storage = new StorageUtil();
let Observer = ReactObserver();

let User = new UserClass();
let Interviews = new InterviewsClass();
let SalePoints = new SalePointsClass();

User.start().then(_ => {

    Observer.publish('coreDidInit')

});



class PrivateComponent extends Component {

    constructor(props){
        super(props);
        Observer.subscribe('userLoggedStatusHasChanged', () => {
            if(!User.isLogged && this.props.history.location.pathname != '/') {
                this.props.history.push('/');
            }
        });
        Observer.subscribe('redirectToInterviewPageAfterCreation', (interview) => {
            this.context.setInterview(interview);
            this.props.history.push({
                pathname: routes.interview.path,
                search: '?id=' + interview.id
            });
        });
    }

}

export {
    Network,
    Storage,
    Observer,
    User,
    Interviews,
    SalePoints,
    PrivateComponent
};