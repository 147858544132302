import React, {Component} from 'react';

import Logo from '../../assets/images/logo-white.svg';

import './Resize.css';

export default class Button extends Component {
    render() {
        return (
            <div className="Resize">
                <img src={Logo} className={"logo"} alt={"Logo Hager"} />
                <div className="w-content container-fluid">
                    <div className="col-10 m-auto">
                        <span className="icon icon--64 txt--white icon-icon-tablet"></span>
                        <p className="p--small txt--white">Cette appplication est uniquement disponible à partir des résolutions tablettes.</p>
                    </div>
                </div>
            </div>
        );
    }
}