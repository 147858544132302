import React from 'react';
import './QuestionPage.css';


import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";


import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";


import { withFormik } from 'formik';


import * as Yup from 'yup';

import _ from "lodash";
import {fieldsTypes} from "../../../utils/fields";



import { confirmAlert } from 'react-confirm-alert';

import Confirm from 'components/Confirm/Confirm';

import {isJSON} from "../../../helpers/string";

import Score from './Fields/Score';
import Input from './Fields/Input';
import Rating from './Fields/Rating';
import Stock from './Fields/Stock';
import Clients from './Fields/Clients';
import Stars from './Fields/Stars';
import Boolean from './Fields/Boolean';
import Months from './Fields/Months';
import Radio from './Fields/Radio';
import Product from './Fields/Product';
import Textarea from './Fields/Textarea';
import Turnover from './Fields/Turnover';
import TurnoverUnivers from './Fields/TurnoverUnivers';
import Training from './Fields/Training';
import ShowPage from './Fields/ShowPage';
import File from './Fields/File';
import Validation from './Fields/Validation';
import Univers from './Fields/Univers';




import Actions from './Actions';
import ActionsDates from './ActionsDates';


import {User} from "app/Core";
import Select from "./Fields/Select";
import MaskInput from "react-maskinput/lib";
import RatingWithAnswers from "./Fields/RatingWithAnswers";
import {InterviewService} from "../../../services/InterviewService";



const QUESTION_PREFIX = 'question_';

class QuestionPage extends PrivateComponent {

    constructor(props) {

        super(props);

        this.state = {scoreMore:[], priorityPrevious:[]};
    }

    componentDidMount(){
        const { childRef } = this.props;
        childRef(this);

        this.getAnswers();
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.page.id != this.props.page.id) {
            this.getAnswers();
        }
    }

    getAnswers() {

            let values = {};

            if(this.props.interview && this.props.interview.pages) {

                let pageAnswer = _.find(this.props.interview.pages, (item) => {
                    return this.props.page.id === item.question_page_id;
                });

                values['free_comment'] = pageAnswer && pageAnswer.free_comment? pageAnswer.free_comment : '';
                values['internal_comment'] = pageAnswer && pageAnswer.internal_comment ? pageAnswer.internal_comment : '';

                if(this.props.page.has_actions) {
                    values['actions'] = pageAnswer && pageAnswer.actions ? JSON.parse(pageAnswer.actions) : [{
                        priority:'',
                        note:'',
                        budget:'',
                        perf:'',
                        duration:''
                    }];

                    values['action_dates'] = pageAnswer && pageAnswer.action_dates ? JSON.parse(pageAnswer.action_dates) : [{
                        date:'',
                        note:''
                    }];
                }

                if(this.props.page.has_actions_dates) {
                    const pageAnswer57 = _.find(this.props.interview.pages, (item) => {
                        return item.question_page_id === 57;
                    });
                    values['actions'] = pageAnswer57 && pageAnswer57.actions ? JSON.parse(pageAnswer57.actions) : [{
                        priority:'',
                        note:'',
                        budget:'',
                        perf:'',
                        duration:''
                    }];

                    values['action_dates'] = pageAnswer && pageAnswer.action_dates ? JSON.parse(pageAnswer.action_dates) : [{
                        date:'',
                        note:''
                    }];
                }


                values['validate'] = '0';


            }
            if(this.props.interview && this.props.interview.answers) {

                if(this.props.page && this.props.page.questions){

                    this.props.page.questions.forEach(question => {

                        let answer = _.find(this.props.interview.answers, (answer) => {
                            return question.id === answer.question.id;
                        });

                        if(answer && typeof answer.answer !== 'undefined' && answer.answer) {
                            values[this.getQuestionFieldName(question.id)] = answer.answer && isJSON(answer.answer) && question.id !== 233 ? JSON.parse(answer.answer) : answer.answer;
                        }

                    })
                }
            }


        this.props.setValues(values);
    }

    getValues() {
        return this.props.values;
    }


    getQuestionFieldName(questionId) {
        return QUESTION_PREFIX + questionId;
    }


    getPreviousAnswer(questionId) {

        if(this.props.previousInterview && this.props.previousInterview.answers){
            for(let i in this.props.previousInterview.answers){
                let answer = this.props.previousInterview.answers[i];

                if(answer.question.id === questionId) {

                    return answer;
                }
            }
        }

    }

    getPreviousAnswerPage(questionPageId) {

        if(this.props.previousInterview && this.props.previousInterview.pages){
            for(let i in this.props.previousInterview.pages){
                let page = this.props.previousInterview.pages[i];

                if(page.question_page_id === questionPageId) {

                    return page;
                }
            }
        }

    }

    getPreviousPriority() {
        let priorityPrevious = null;

        if(this.props.page && this.props.page.questions) {
            priorityPrevious = [];

            this.props.page.questions.forEach(question => {
                let previousAnswer = this.getPreviousAnswer(question.id);


                if(previousAnswer && question.type == fieldsTypes.TYPE_SCORE){
                    priorityPrevious.push({
                        question:question,
                        answer: previousAnswer
                    });
                }
            });

        }

        return priorityPrevious;

    }


    buildQuestions() {

        let questions = [];

        if(this.props.page && this.props.page.questions) {

            let needProductHead  = true;

            this.props.page.questions.forEach(question => {

                let productShow = true;

                let show = true;

                if(question.conditional_question_id && question.formatted_conditional_answer ){
                    const conditionalId = this.getQuestionFieldName(question.conditional_question_id);
                    if(!this.props.values[conditionalId]
                        || question.formatted_conditional_answer.indexOf(this.props.values[conditionalId]) === -1
                    ){
                        if(question.type === fieldsTypes.TYPE_PRODUCT){
                            productShow = false;
                        }
                        else {
                            return;
                        }
                    }
                }

                let id = this.getQuestionFieldName(question.id);

                let previousAnswer = this.getPreviousAnswer(question.id);


                switch (question.type) {

                    case fieldsTypes.TYPE_FILE:
                        questions.push(
                            <File key={id} question={question} />
                        );
                        break;

                    case fieldsTypes.TYPE_SCORE:
                        questions.push(
                            <Score previousAnswer={previousAnswer} key={id} label={question.name} name={id} id={id} value={parseInt(this.props.values[id])} onClick={(item) => { if(this.props.values[id] === item){ this.props.setFieldValue(id, null)} }} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                    break;


                    case fieldsTypes.TYPE_UNIVERS:

                        questions.push(
                            <Univers previousAnswer={previousAnswer} key={id} label={question.name} name={id} id={id} value={this.props.values[id]}
                                     onClick={(item) => {
                                        /* let items = this.props.values[id];

                                         if(Array.isArray(items) && items.includes(item)){

                                             let j = 0;
                                             while (j < items.length) {
                                                 if (items[j] === item) {
                                                     items.splice(j, 1);
                                                 } else {
                                                     ++j;
                                                 }
                                             }


                                             this.props.setFieldValue(id, items)
                                         }*/
                                     }}
                                     onChange={(item, i) => {

                                         let items = this.props.values[id];
                                         if(!Array.isArray(items)) {
                                             items = [];
                                         }
                                         console.log(item,items, items.includes(item) );
                                         if(Array.isArray(items) && items.includes(item)){
                                             console.log('if',items);

                                             let j = 0;
                                             while (j < items.length) {
                                                 if (items[j] === item) {
                                                     items.splice(j, 1);
                                                 } else {
                                                     ++j;
                                                 }
                                             }
                                             console.log(items);
                                         }
                                         else{
                                             console.log('else');
                                             items.push(item);
                                         }
                                         console.log(items);


                                         this.props.setFieldValue(id, items);
                                     }}
                                     onBlur={this.props.handleBlur} />
                        );
                        break;


                    case fieldsTypes.TYPE_SCORE_MORE:

                        if(User.isSupervisor){
                            break;
                        }

                        questions.push(
                            <React.Fragment key={id}>
                                {
                                    this.props.values[id]
                                    ?
                                        this.props.values[id].map((item, i) => {
                                            return  <Score key={id +'_'+i}
                                                           label={item.name}
                                                           name={id+'['+i+']'}
                                                           id={id}
                                                           value={this.props.values[id][i].value ? parseInt(this.props.values[id][i].value) : 0}
                                                           onChange={(value) => {
                                                               let moreValues = this.props.values[id].slice(0);
                                                               moreValues[i].value = value;
                                                               this.props.setFieldValue(id, moreValues);
                                                           }} onBlur={this.props.handleBlur}
                                                           delete={true}
                                                           onDelete={() => {


                                                               confirmAlert({
                                                                   customUI: ({ onClose }) => {
                                                                       return (
                                                                           <Confirm
                                                                               onClose={onClose}
                                                                               title='Êtes-vous sûr de vouloir supprimer cette priorité ?'
                                                                               buttonOk='Supprimer cette priorité'
                                                                               onValidate={() => {
                                                                                   let moreValues = this.props.values[id].slice(0);
                                                                                   moreValues.splice(i,1);
                                                                                   this.props.setFieldValue(id, moreValues);
                                                                                   let state = this.state.scoreMore.splice(0);
                                                                                   state.splice(i,1);
                                                                                   this.setState({scoreMore:state});
                                                                                   onClose();
                                                                               }}
                                                                               buttonKo='Annuler la suppression'
                                                                               danger={true}
                                                                           >
                                                                               Cette priorité n’apparaitra plus dans votre liste de l’année courante.
                                                                           </Confirm>

                                                                       );
                                                                   }
                                                               });
                                                           }}
                                            />
                                        })
                                    :null
                                }

                                <div className={'ScoreMore add-btn-w'}>


                                    <button
                                        className={'btn-icon'}
                                        onClick={(e) => {
                                        confirmAlert({
                                            customUI: ({ onClose }) => {
                                                return (
                                                    <Confirm
                                                        className={'form'}
                                                        onClose={onClose}
                                                        headline={this.props.page.type}
                                                        title={question.name}
                                                        buttonOk={'Valider'}
                                                        buttonKo={'Annuler'}
                                                        onValidate={() => {
                                                            let values = this.props.values[id];
                                                            if(!Array.isArray(values)){
                                                                values = [];
                                                            }
                                                            values.push({name:this.state.scoreMore[this.props.values[id] ? this.props.values[id].length : 0], value:null});
                                                            this.props.setFieldValue(id, values);
                                                            onClose();
                                                        }}
                                                    >
                                                        <form>
                                                           <div className={'form-group'}>
                                                               <label>Nommez votre priorité</label>
                                                                <textarea
                                                                    placeholder={'Ecrivez votre nouvelle priorité ici…'}
                                                                    name="add_score"
                                                                    value={this.state.scoreMore[this.props.values[id] ? this.props.values[id].length : 0]}
                                                                    onChange={(e) => {
                                                                        let state = this.state.scoreMore.splice(0);
                                                                        state[this.props.values[id] ? this.props.values[id].length : 0] = e.target.value;
                                                                        this.setState({scoreMore:state})
                                                                 }} />
                                                           </div>
                                                        </form>
                                                    </Confirm>
                                                );
                                            }
                                        });


                                    }}>
                                        <i className={'icon icon-add txt--secondary'} /> {question.name}
                                    </button>
                                </div>
                            </React.Fragment>
                        );
                    break;

                    case fieldsTypes.TYPE_INT:
                        questions.push(
                            <Input  previousAnswer={previousAnswer} key={id} type={"number"} question={question} name={id} value={this.props.values[id]} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_TEXT:
                        questions.push(
                            <Input previousAnswer={previousAnswer} key={id} type={"text"} question={question} name={id} value={this.props.values[id]} interview={this.props.interview} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                        );
                        break;

                    case fieldsTypes.TYPE_DATE:
                        questions.push(
                            <Input type={"text"} maskString={ this.props.maskString} mask={'00/00/0000'}  maskString={'JJ/MM/AAAA'}   previousAnswer={previousAnswer} key={id} type={"text"} question={question} name={id} value={this.props.values[id]} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                        );
                        break;

                    case fieldsTypes.TYPE_TITLE:
                        questions.push(
                            <h2 key={id} className={'h2 Title'}>{question.name}</h2>
                        );
                    break;

                    case fieldsTypes.TYPE_RATING:
                        questions.push(
                            <Rating previousAnswer={previousAnswer} key={id} label={question.name} name={id} id={id} value={parseInt(this.props.values[id])} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_RATING_WITH_ANSWERS:
                        questions.push(
                            <RatingWithAnswers previousAnswer={previousAnswer} key={id} label={question.name} answers={question.answers} name={id} id={id} value={parseInt(this.props.values[id])} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_STOCK:
                        questions.push(
                            <Stock previousAnswer={previousAnswer} key={id} label={question.name} name={id} id={id} value={(this.props.values[id])} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_CLIENTS:
                        questions.push(
                            <Clients question={question} previousAnswer={previousAnswer} key={id} label={question.name} name={id} id={id} value={(this.props.values[id])}
                                     onChange={(key, value) => {
                                         let clientDatas = this.props.values[id];
                                         if (!clientDatas || !Array.isArray(clientDatas)) clientDatas = [];
                                         const kv = clientDatas.findIndex(v => v.key === key);
                                         if (kv === -1) {
                                             clientDatas.push({
                                                 key: key,
                                                 value: value
                                             });
                                         } else {
                                             clientDatas[kv] = {
                                                 key: key,
                                                 value: value
                                             };
                                         }
                                         this.props.setFieldValue(id, clientDatas);
                                     }}  />
                        );
                    break;

                    case fieldsTypes.TYPE_STARS:
                        questions.push(
                            <Stars previousAnswer={previousAnswer}  key={id} label={question.name} name={id} id={id} value={parseInt(this.props.values[id])} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_BOOLEAN:
                        questions.push(
                            <Boolean previousAnswer={previousAnswer}  key={id} label={question.name} name={id} id={id} value={parseInt(this.props.values[id])} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_MONTHS:
                        questions.push(
                            <Months previousAnswer={previousAnswer}  key={id} label={question.name} name={id} value={this.props.values[id]} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                        );
                    break;

                    case fieldsTypes.TYPE_SELECT:
                        questions.push(
                            <Select previousAnswer={previousAnswer}  answers={question.answers}  key={id} label={question.name} name={id} id={id} value={this.props.values[id]}  onChange={this.props.handleChange}  onBlur={this.props.handleBlur} />
                        );
                        break;




                    case fieldsTypes.TYPE_RADIO:
                    questions.push(
                        <Radio previousAnswer={previousAnswer}  answers={question.answers}  key={id} label={question.name} name={id} id={id} value={this.props.values[id]} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                    );
                    break;

                    case fieldsTypes.TYPE_PRODUCT:
                        if(needProductHead){
                            questions.push(
                                <div className={'Product Product--head'} key={`${id}_head`}>
                                    <div className={'Product__in'}>
                                        <div className={'Product__label'}><h3 className={'h3'}>Produits</h3></div>
                                        <div className={'Product__mid'}><h3 className={'h3'}>Dispo</h3></div>

                                        <div className={'Product__last'}>
                                        {
                                            productShow
                                            ?
                                            <h3 className={'h3'}>Merch.</h3>
                                            :null
                                        }
                                        </div>
                                    </div>


                                </div>
                            );
                            needProductHead = false;
                        }

                        questions.push(
                            <Product
                                previousAnswer={previousAnswer}
                                key={id}
                                label={question.name}
                                name={id}
                                value={this.props.values[id]}
                                onChange={this.props.handleChange}
                                onBlur={this.props.handleBlur}
                                showMerch={productShow}
                                onChangeMerch={(item) => {
                                    let val = {...this.props.values[id]};
                                    val.merch = item;
                                    this.props.setFieldValue(id, val);
                                }}
                            />
                        );
                    break;
                    case fieldsTypes.TYPE_TEXTAREA:
                        questions.push(
                            <Textarea previousAnswer={previousAnswer}  key={id} label={question.name} name={id} value={this.props.values[id]} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                        );
                    break;
                    case fieldsTypes.TYPE_TURNOVER:
                        questions.push(
                            <Turnover
                                previousAnswer={previousAnswer}
                                key={id}
                                title={question.name}
                                name={id}
                                value={this.props.values[id]}
                                onChange={(index, field, value) => {

                                    let turnoverValues = [];
                                    if(this.props.values[id]) {
                                        turnoverValues = this.props.values[id].slice(0);
                                    }

                                    if(!turnoverValues[index]){
                                        let diff = index - (turnoverValues.length - 1)
                                        for(let i = 0; i < diff; i++){
                                            turnoverValues.push({});
                                        }
                                    }
                                    else if(Array.isArray(turnoverValues[index])){
                                        turnoverValues[index] = {};
                                    }
                                    turnoverValues[index][field] = value;

                                    if(turnoverValues[index]['ca_hager_pdv'] && turnoverValues[index]['ca_pdv_total']) {
                                        let part = turnoverValues[index]['ca_hager_pdv'] * 100 / turnoverValues[index]['ca_pdv_total'];
                                        turnoverValues[index]['ca_part'] = part.toFixed(2);
                                    }

                                    this.props.setFieldValue(id, turnoverValues);
                                }}
                                onBlur={this.props.handleBlur} />
                        );
                    break;
                    case fieldsTypes.TYPE_TURNOVER_UNIVERS:
                        questions.push(
                            <TurnoverUnivers
                                pdv={this.props.interview.pdv}
                                previousAnswer={previousAnswer}
                                key={id}
                                title={question.name}
                                name={id}
                                value={this.props.values[id]}
                                onChange={(index, field, value) => {

                                    let turnoverValues = [];
                                    if(this.props.values[id]) {
                                        turnoverValues = this.props.values[id].slice(0);
                                    }

                                    if(!turnoverValues[index]){
                                        let diff = index - (turnoverValues.length - 1)
                                        for(let i = 0; i < diff; i++){
                                            turnoverValues.push({});
                                        }
                                    }
                                    else if(Array.isArray(turnoverValues[index])){
                                        turnoverValues[index] = {};
                                    }
                                    turnoverValues[index][field] = value;


                                    if(turnoverValues[index]['ca_hager_pdv'] && turnoverValues[index]['ca_pdv_total']) {
                                        let part = turnoverValues[index]['ca_hager_pdv'] * 100 / turnoverValues[index]['ca_pdv_total'];
                                        turnoverValues[index]['ca_part'] = part.toFixed(2);
                                    }
                                    
                                    this.props.setFieldValue(id, turnoverValues);
                                }}
                                onBlur={this.props.handleBlur} />
                        );
                    break;
                    case fieldsTypes.TYPE_TRAINING_TEAM:
                    case fieldsTypes.TYPE_TRAINING_CLIENT:
                    case fieldsTypes.TYPE_TRAINING_AGENCY:
                        questions.push(
                            <Training
                                key={id}
                                team={question.type === fieldsTypes.TYPE_TRAINING_TEAM}
                                client={question.type === fieldsTypes.TYPE_TRAINING_CLIENT}
                                agency={question.type === fieldsTypes.TYPE_TRAINING_AGENCY}
                                title={question.name}
                                name={id}
                                answers={question.answers}
                                value={this.props.values[id]?this.props.values[id]:[{}]}
                                onChange={this.props.handleChange}
                                onBlur={this.props.handleBlur}
                                onAdd={() => {

                                    let trainings = this.props.values[id] ? this.props.values[id].slice(0) : [{}];
                                    trainings.push({});
                                    this.props.setFieldValue(id, trainings);
                                }}
                                onDelete={(index) => {
                                    confirmAlert({
                                        customUI: ({ onClose }) => {
                                            return (
                                                <Confirm
                                                    onClose={onClose}
                                                    title='Êtes vous sûr de vouloir supprimer cette formation ?'
                                                    buttonOk={'Supprimer cette formation'}
                                                    buttonKo={'Annuler la suppression'}
                                                    danger={true}
                                                    onValidate={() => {
                                                        let trainings = this.props.values[id] ? this.props.values[id].slice(0) : [{}];
                                                        trainings.splice(index, 1);
                                                        this.props.setFieldValue(id, trainings);
                                                        onClose();
                                                    }}
                                                >

                                                </Confirm>
                                            );
                                        }
                                    });


                                }}
                            />
                        );
                    break;
                    case fieldsTypes.TYPE_SHOW_PAGE:
                        questions.push(
                            <ShowPage
                                key={id}
                                question={question}
                                name={id}
                                value={this.props.values[id]}

                                onChange={(value, checked) => {

                                    if(checked) {
                                        this.context.addAllowedConditionalPage(value);
                                    }
                                    else{
                                        this.context.removeAllowedConditionalPage(value);
                                    }

                                    this.props.setFieldValue(id, checked ? value : null);
                                }}
                                onBlur={this.props.handleBlur}
                            />
                        )
                    break;

                    case fieldsTypes.TYPE_VALIDATION:
                        questions.push(
                            <Validation
                                interview={this.props.interview}
                                key={id}
                                progress={this.props.progress}
                                onChange={this.props.handleChange}
                                onBlur={this.props.handleBlur}
                                name={'validate'}
                                value={this.props.values['validate']}
                                onValidate={() => {

                                    this.props.setFieldValue('validate', '1');

                                    setTimeout(() => {this.props.validate();}, 100);

                                }}
                                onSave={() => {
                                    this.props.savePage();
                                }}
                                onLeave={() => {
                                    this.props.leave();
                                }}
                            />
                        )
                    break;
                }

            });
        }

        return questions;
    }


    render() {

        let previousAnswerPage = this.getPreviousAnswerPage(this.props.page.id);


        let priorityPrevious = null;

        if(this.props.page.id === 50) {
            const oldPriority = this.getPreviousPriority();

            if(oldPriority.length) {
                priorityPrevious = [];
                for(let k in oldPriority) {
                    priorityPrevious.push(<div key={k} style={{marginBottom:5}}>{oldPriority[k].question.name} : <strong>{oldPriority[k].answer.answer}</strong></div>)
                }

            }

        }


        return (
            <div className="QuestionPage">

                <form  onSubmit={this.props.handleSubmit}>

                    <div className={'container'}>

                        <div className={'questions-w'}>

                            {
                                priorityPrevious
                                ?
                                <div className="Textarea"><h3 style={{"fontWeight": 'bold', "marginBottom":'10px'}}>Priorité 2020</h3>{priorityPrevious}</div>
                                : null

                            }

                            { this.buildQuestions() }

                            {
                                this.props.page.has_actions
                                ?
                                    <Actions
                                        page={this.props.page}
                                        value={this.props.values.actions}
                                        dates={this.props.values.action_dates}
                                        onChange={this.props.handleChange}
                                        onBlur={this.props.handleBlur}
                                        setFieldValue={this.props.setFieldValue}
                                        onAdd={() => {
                                            let actions = this.props.values.actions.slice(0);
                                            actions.push({
                                                priority:'',
                                                note:'',
                                                budget:'',
                                                perf:'',
                                                duration:''
                                            })
                                            this.props.setFieldValue('actions', actions);
                                        }}
                                        onDelete={(index) => {
                                            confirmAlert({
                                                customUI: ({ onClose }) => {
                                                    return (
                                                        <Confirm
                                                            onClose={onClose}
                                                            title='Êtes vous sûr de vouloir supprimer cette prioriété ?'
                                                            buttonOk={'Supprimer cette prioriété'}
                                                            buttonKo={'Annuler la suppression'}
                                                            danger={true}
                                                            onValidate={() => {
                                                                let actions = this.props.values.actions.slice(0);
                                                                actions.splice(index,1);

                                                                let dates = this.props.values.action_dates.slice(0);
                                                                dates.splice(index,1);

                                                                this.props.setFieldValue('actions', actions);
                                                                this.props.setFieldValue('action_dates', dates);
                                                                onClose();
                                                            }}
                                                        >

                                                        </Confirm>
                                                    );
                                                }
                                            });

                                        }}
                                    />
                                : null
                            }

                            {
                                this.props.page.has_actions_dates
                                    ?
                                    <ActionsDates
                                        page={this.props.page}
                                        interview={this.props.interview}
                                        value={this.props.values.actions}
                                        dates={this.props.values.action_dates}
                                        onChange={this.props.handleChange}
                                        onBlur={this.props.handleBlur}
                                        setFieldValue={this.props.setFieldValue}
                                        onAddDate={(index) => {
                                            let actionDates = this.props.values.action_dates.slice(0);
                                            if(Array.isArray(actionDates[index])) {
                                                actionDates[index].push({
                                                    date: '',
                                                    avancement: '',
                                                    note: ''
                                                })
                                            }
                                            else{
                                                let initDates = [];

                                                if(actionDates[index]){
                                                    initDates.push(actionDates[index]);
                                                }
                                                else{
                                                    initDates.push({
                                                        date: '',
                                                        avancement: '',
                                                        note: ''
                                                    });
                                                }
                                                initDates.push({
                                                    date: '',
                                                    avancement: '',
                                                    note: ''
                                                });
                                                actionDates[index] = initDates;

                                            }
                                            this.props.setFieldValue('action_dates', actionDates);
                                        }}
                                        onDeleteDate={(index, i) => {
                                            confirmAlert({
                                                customUI: ({ onClose }) => {
                                                    return (
                                                        <Confirm
                                                            onClose={onClose}
                                                            title='Êtes vous sûr de vouloir supprimer cette date ?'
                                                            buttonOk={'Supprimer cette date'}
                                                            buttonKo={'Annuler la supression'}
                                                            danger={true}
                                                            onValidate={() => {
                                                                let actionDates = this.props.values.action_dates;
                                                                if(Array.isArray(actionDates[index])) {
                                                                    actionDates[index].splice(i, 1);
                                                                    this.props.setFieldValue('action_dates', actionDates);
                                                                }
                                                                onClose();
                                                            }}
                                                        >

                                                        </Confirm>
                                                    );
                                                }
                                            });

                                        }}
                                        onValidate={() => {
                                            this.props.notifyPriorities();
                                        }}
                                        onExport={() => {
                                            this.props.savePage();
                                        }}
                                    />
                                    : null
                            }

                        </div>


                        {
                            this.props.page.has_free_comment
                            ?
                                <div className={'free-comment'}>
                                    <div className={'free-comment__in'}>
                                        <div className={'field-group'}>
                                            <label htmlFor={"free-field"}>{this.props.page.free_comment_label ? this.props.page.free_comment_label : 'Précision à ajouter'} </label>
                                            <textarea disabled={User.isSupervisor}  id={"free-field"} name="free_comment" value={this.props.values.free_comment} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                                        </div>
                                        {/*{previousAnswerPage && previousAnswerPage.free_comment*/}
                                        {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{previousAnswerPage.free_comment}"</div>*/}
                                        {/*    : null}*/}
                                    </div>

                                </div>
                            : null
                        }

                    </div>

                    {
                        this.props.page.has_internal_comment
                            ?
                            <div className={'private-comment'}>
                                <div className={'container private-comment__in'}>
                                    <h2 className="h2 h2--secondary">Commentaire interne</h2>
                                    <div className={'field-group'}>
                                        <label htmlFor={"private-field"}>Votre commentaire</label>
                                        <textarea disabled={User.isSupervisor} id={"private-field"} name="internal_comment" value={this.props.values.internal_comment} onChange={this.props.handleChange} onBlur={this.props.handleBlur}></textarea>
                                    </div>
                                    {/*{previousAnswerPage && previousAnswerPage.internal_comment*/}
                                    {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{previousAnswerPage.internal_comment}"</div>*/}
                                    {/*    : null}*/}
                                </div>
                            </div>
                        : null
                    }

                </form>
            </div>
        );
    }
}

QuestionPage.propTypes = {
    salePoint: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

QuestionPage.defaultProps = {
    salePoint: null,
    page:null
};

const validationSchema = Yup.object().shape({
    internal_comment: Yup.string(),
    free_comment: Yup.string()
});

export default withRouter(withFormik({

    mapPropsToValues: (props) => ({
        internal_comment: '',
        free_comment: ''
        //question_156: ''
    }),

    validationSchema: validationSchema,

    handleSubmit: (values, { setSubmitting, setStatus, setFieldError,  props }) => {},


})(QuestionPage));

QuestionPage.contextType = GlobalContext;
