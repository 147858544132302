import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";

export default class Boolean extends Component {

    constructor(props) {
        super(props);

        this.labels = {
            0:'Non',
            1:'Oui'
        }

    }

    render() {


        return (

            <div className={'Boolean question question--bg'}>
                <div className={'Boolean__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Boolean__control question__control">
                    {
                        [1,0].map((item, i) => {
                            return <div className={'inline checkbox-row'} key={i}>
                                        <label className={'checkbox-w'}>
                                            <input disabled={User.isSupervisor} id={`${this.props.name}_${item}`} checked={this.props.value === item} type='radio' value={item} name={this.props.name} onChange={() => this.props.onChange(item)} onBlur={this.props.handleBlur} />
                                            <i className="icon icon-check mark mark--radio"  />
                                        </label>
                                        <label className={'checkbox-label'} htmlFor={`${this.props.name}_${item}`}><span>{this.labels[item]}</span></label>
                                    </div>
                        })
                    }
                    </div>
                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}
            </div>
        );
    }

}

Boolean.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.number,

};

Boolean.defaultProps = {

};
