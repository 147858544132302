import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

export default class Rating extends Component {

    constructor(props) {
        super(props);

        /*this.labels = {
            0:'Absent',
            1:'Très faible',
            2:'Faible',
            3:'Moyen',
            4:'Fort',
            5:'Très fort'
        };*/

        this.labels = {
            0:'Pas de potentiel',
            1:'Prospect',
            2:'Acheteur occasionnel',
            3:'Acheteur fidèle',
            //4:'Fort',
           // 5:'Très fort'
        }
        /*this.labels = {
            0:'Non distribué',
            1:'Distribué',
            2:'Vente 2ième marque',
            3:'Vente 1ière marque',
            //4:'Fort',
            // 5:'Très fort'
        };*/


    }

    render() {


        return (

            <div className={'Rating question question--bg'}>
                <div className={'Rating__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Rating__control question__control">
                    {
                        [0,1,2,3,/*4,5*/].map((item, i) => {
                            return <div className={'Rating__input-w'}  key={i}>
                                <input disabled={User.isSupervisor} className={'Rating__input'} id={`${this.props.name}_${item}`} checked={this.props.value === item} type='radio' value={item} name={this.props.name} onChange={() => this.props.onChange(item)} onBlur={this.props.handleBlur} />
                                <label className={`Rating__label level-${item}`} htmlFor={`${this.props.name}_${item}`}><span>{this.labels[item]}</span></label>
                            </div>
                        })
                    }
                    </div>
                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}
            </div>
        );
    }

}

Rating.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.number,

};

Rating.defaultProps = {

};
