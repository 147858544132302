import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class ModalHead extends Component {

    render() {
        return (
            <div className={"w-modal__content__head " + (this.props.className)}>
                <div className="headline">{this.props.subtitle}</div>
                <h2 className="h2--secondary title">{this.props.title}</h2>
                {this.props.text ? <p className="txt--white txt--small">{this.props.text}</p> : null}
            </div>
        );
    }

}

ModalHead.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string
};

ModalHead.defaultProps = {
    className: '',
    title: '',
    subtitle: '',
    text: ''
};
