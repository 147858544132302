import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";

export default class Stars extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (

            <div className={'Stars question'}>
                <div className={'Stars__in question__in'}>
                    <div className="question__side">
                        <label  className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Stars__control question__control">
                    {
                        [1,2,3].map((item, i) => {
                            return <label className="star" key={i}>
                                <input disabled={User.isSupervisor} id={`${this.props.name}_${item}`} checked={this.props.value === item} type='radio' value={item} name={this.props.name} onChange={() => this.props.onChange(item)} onBlur={this.props.handleBlur} />
                                <span className="star__icon">{'★'.repeat(item)}</span>
                            </label>
                        })
                    }
                    </div>
                </div>
                {this.props.previousAnswer && this.props.previousAnswer.value !== null
                    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>
                    : null}
            </div>
        );
    }

}

Stars.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.number,

};

Stars.defaultProps = {

};