import React from 'react';
import './Drawer.css';


import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";


import {withRouter} from "react-router-dom";
import {routes} from "config/routes";
import PropTypes from "prop-types";
import {convertDate} from "../../../helpers/string";
import {generateStatus} from "../../../config/status";


import { confirmAlert } from 'react-confirm-alert';
import Confirm from 'components/Confirm/Confirm';

import {SalePointService} from 'services/SalePointService';
import {toast} from "react-toastify";

import {Observer} from "app/Core";
import {UserServices} from "../../../services/UserServices";

import {User} from "app/Core";
import LoaderSpiral from "../../../components/LoaderSpiral/LoaderSpiral";
import {InterviewService} from "../../../services/InterviewService";


class Drawer extends PrivateComponent {

    constructor(props) {

        super(props);

        this.state = {salePoint:null, userLoading:false};

    }

    componentDidMount() {
        if(User.isManager || User.isAdmin || User.isSupervisor) {
            this.getFdvs();
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(!this.state.salePoint && this.props.salePoint) {
            this.setState({salePoint:this.props.salePoint});
        }

    }


    getFdvs() {
        UserServices.getFdvs(true).then(response => {

            if (200 === response.status) {
                this.setState({fdvs:response.data});
            }

        });
    }

    onItemSelected(page){
        if(this.props.isLoading){
            return;
        }
        this.props.history.push(routes.questionPageSheet.route(this.props.salePoint.id, page.id));

        if(this.props.onSelectItem){
            this.props.onSelectItem(page);
        }

    }

    getIsCompleted(page) {


        if (this.props.interview) {

            let filteredPage = this.props.interview.pages.find((item) => {
                return parseInt(item.question_page_id, 10) === parseInt(page.id, 10);
            });

            if(filteredPage) {
                return filteredPage.is_completed;
            }
        }

        return false;
    }

    navigationItems(){

        let items = {};

        let elements = [];

        if(this.context.getActiveQuestionPages() && this.context.getActiveQuestionPages()) {

            this.context.getActiveQuestionPages().forEach((page, i) => {

                if(!items[page.type]){
                    items[page.type] = [];
                }
                items[page.type].push(page);
            });

            const sections = Object.keys(items);
            sections.forEach((key, i) => {

                elements.push(<h3 key={i} className="h3 nav-title">{key}</h3>);



                items[key].forEach((page, j) => {

                    const isActive = this.props.currentPage && this.props.currentPage.id === page.id;

                    const isCompleted =  0 === i && 0 === j && this.props.interview && this.props.interview.pdv_contact
                                      ? true
                                      : ( sections.length - 1 === i && items[key].length - 1 === j && this.props.interview ? this.props.interview.status : this.getIsCompleted(page));

                    return elements.push(
                        <div
                            onClick={(e) => {e.preventDefault(); this.onItemSelected(page)}}
                            className={'nav-item '+ (j== 0 ? 'first': '') + (j === items[key].length - 1 ? ' last' : '') + (isActive ? ' active': '') + (isCompleted ? ' completed': '')}
                            key={`${i}-${j}`}

                        >
                            <div className={'nav-item__in'}>
                                <div className={'nav-item__icon'} ><i className={'icon icon-' +(isCompleted ? 'check' : page.icon)} /> </div>
                                <div className={'nav-item__name'} >{page.name}</div>
                            </div>

                            <i className={'nav-item__arrow icon icon-miniarrowright'} />
                        </div>
                    )
                });
            })

        }

        return elements;

    }


    delete(e){

        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <Confirm
                        onClose={onClose}
                        title='Êtes-vous sûr de vouloir supprimer ce point de vente ?'
                        buttonOk='Supprimer le point de vente'
                        danger={true}
                        onValidate={() => {
                            this.setState({salePointsLoaded: false});
                            SalePointService.delete(this.props.salePoint.id).then(response => {

                                if(200 === parseInt(response.status) || 204 === parseInt(response.status)) {

                                    Observer.publish('shopDeleted');
                                    this.props.history.replace(routes.dashboard.route());


                                    return;
                                }

                                toast.error("Erreur lors de la suppression du point de vente. ", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    toastId: '404_sale_point'
                                });

                            }).catch((error) => {

                                toast.error("Erreur lors de la suppression du point de vente. ", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    toastId: '404_sale_point'
                                });

                            });
                            onClose();
                        }}
                        buttonKo='Annuler la suppression'
                    >
                        Ce point de vente pourra être de nouveau accessible en contactant l’administrateur de Hager Driver
                    </Confirm>

                );
            }
        });
    }

    editUser() {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <Confirm
                        className={'form'}
                        onClose={onClose}
                        title='Sélectionner un nouveau commercial'
                        danger={false}

                        buttonKo='Annuler'
                    >
                        {
                            this.state.fdvs
                            ?
                                this.state.fdvs.map((item, i) => {
                                    return <div key={i} className={'user-card ' + (this.state.salePoint.user_data && item.id === this.state.salePoint.user_data.id ? 'active' : '')}>
                                                <div onClick={(e) => {this.setUser(item, onClose) }} className={'user-card__in'}>
                                                    <i className={'icon icon-contact'} />
                                                    <div>
                                                        <div className={'info__user'}>{ item.firstname} {item.lastname}</div>
                                                        <div className={'info__date'}>Dernière activité : {item.last_login ? convertDate(item.last_login) : '-' }</div>
                                                    </div>
                                                </div>
                                        </div>
                                })
                            : null
                        }

                    </Confirm>
                );
            }
        });
    }

    setUser(user, close) {

        if(this.state.salePoint.user_data && user.id === this.state.salePoint.user_data.id){
            return;
        }

        this.setState({userLoading:true});

        close();


        SalePointService.edit(this.state.salePoint.id, {user_id: user.id}).then(response => {

            if(200 === response.status) {
                this.setState({salePoint:response.data, userLoading:false});
            }

        }).catch(e => {
            toast.error("Erreur lors de la modification du commercial", {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: 'commercial_error'
            });
        })

    }

    showPdf(e) {
        e.preventDefault();

        if(this.state.salePoint && this.state.salePoint.current_year_interview_id){
            window.open( InterviewService.getPdfLink(this.state.salePoint.current_year_interview_token), "_blank");
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className={(this.props.show ? 'show' : '') + ' Drawer' }>

                    <div className={'Drawer__head'}>

                        <button onClick={(e) => { e.preventDefault(); this.props.close(); }} className={'Drawer__close' }><i className={'icon icon-close'} /></button>



                        <div className="headline">{this.state.salePoint && this.state.salePoint.direction ? 'Direction' : 'Point de vente' }</div>
                        <h2 className="h2 h2--secondary">{this.state.salePoint ? this.state.salePoint.name : '' }
                            <span className={'status ' + (this.state.salePoint ? this.state.salePoint.status : '')}>{this.props.salePoint ? generateStatus(this.props.salePoint.status) : ''}</span>
                        </h2>

                        <hr />

                        {
                            this.state.salePoint && this.state.salePoint.user_data
                            ?
                            <div className={'Drawer__user'}>
                                <h4 className={'h4 Drawer__user__title'}>Commercial rattaché</h4>
                                <div className={'Drawer__user__card'}>
                                    {
                                        this.state.userLoading
                                        ? <LoaderSpiral className={'small'} />
                                        :
                                        <React.Fragment>
                                            <div className={'Drawer__user__id'}>
                                                <i className={'icon icon-contact'} />
                                                <div className={'info'}>
                                                    <div className={'info__user'}>{ this.state.salePoint.user_data.firstname} {this.state.salePoint.user_data.lastname}</div>
                                                    <div className={'info__date'}>Dernière activité : {this.state.salePoint.user_data.last_login ? convertDate(this.props.salePoint.user_data.last_login) : '-' }</div>
                                                </div>
                                            </div>
                                            <div className={'Drawer__user__unit'}>
                                               <i className={'icon icon-work'} />
                                                <span className={'name'}> {this.state.salePoint.user_data.sales_unit ? this.state.salePoint.user_data.sales_unit : ''}</span>
                                            </div>
                                            { User.isManager || User.isAdmin ? <button onClick={(e) => {e.preventDefault(); this.editUser();}} className={'Drawer__user__edit'}><i className={'icon icon-edit'} /> </button> : null}
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            :<div className={'Drawer__user'}>
                                    <h4 className={'h4 Drawer__user__title'}>Commercial rattaché</h4>
                                    <div className={'Drawer__user__card'}>
                                        { User.isManager || User.isAdmin ? <button onClick={(e) => {e.preventDefault(); this.editUser();}} className={'Drawer__user__edit'}><i className={'icon icon-edit'} /> </button> : null}
                                    </div>
                            </div>
                        }


                    </div>

                    <div className={'Drawer__in'}>

                        <div className={"Drawer__action"}>
                            {
                                this.state.salePoint && this.state.salePoint.current_year_interview_id
                                ?
                                    <button onClick={(e) => this.showPdf(e)} className={'btn-icon'}><i className="icon icon-pdf txt--gray-2" /> Exporter au format PDF</button>
                                : <span></span>

                            }
                            {
                                !User.isSupervisor && !User.isFdv
                                ? <button onClick={(e) => this.delete(e)} className={'btn-icon txt--danger'}><i className="icon icon-delete" /> Supprimer {this.props.salePoint && this.props.salePoint.direction ? 'la direction' : 'le point de vente' }</button>
                                :<span></span>
                            }

                        </div>

                        {this.navigationItems()}

                    </div>

                </div>

                <div onClick={(e) => { e.preventDefault(); this.props.close(); }} className={(this.props.show ? 'show' : '') + ' Drawer-overlay' }></div>

            </React.Fragment>
        );
    }
}



Drawer.propTypes = {
    salePoint: PropTypes.object,
    onSelectItem: PropTypes.func,
    currentPage: PropTypes.object,
};

Drawer.defaultProps = {
    salePoint: {},
    onSelectItem:null,
    currentPage:null
};



export default withRouter(Drawer);

Drawer.contextType = GlobalContext;