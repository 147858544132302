import React, {Component} from 'react';
import './Modal.css';

import { GlobalContext } from "providers/GlobalProvider";

import ChooseShop from "./ChooseShop/ChooseShop";
import CreateShop from "./CreateShop/CreateShop";
import InfoShop from "./InfoShop/InfoShop";
import ChooseContact from "./ChooseContact/ChooseContact";
import UpdateShop from "./UpdateShop/UpdateShop";
import InterviewSuccess from "./InterviewSuccess/InterviewSuccess";
import UpdateContact from "./UpdateContact/UpdateContact";

export default class Modal extends Component {

    constructor(props){
        super(props);
        this.className = "w-modal-overlay";
        this.state = {
            className: this.className
        };
        this.modal = React.createRef();
    }

    handleClose(e){
        if(e && e.target.classList.contains(this.state.className) && !this.props.shouldCloseOnOverlayClick){
            return;
        }
        this.modal.current.classList.add('fade-out');
        setTimeout(() => this.context.deleteModal(), 300);
    }

    handleContent(key){
        let modalContents = {
            'UPDATE_CONTACT': <UpdateContact shop={this.context.shop} contact={this.context.contact} />,
            'CHOOSE_CONTACT': <ChooseContact changeContact={true} interview={true} interviewObj={this.context.interview} shop={this.context.shop} contacts={this.context.contacts} onFinish={() => this.handleClose()} />,
            'INFO_SHOP': <InfoShop shop={this.context.shop} onFinish={() => this.handleClose()} />,
            'CHOOSE_SHOP': <ChooseShop interview={this.context.interview} shop={this.context.shop} creationProcess={this.context.creationProcess} onFinish={() => this.handleClose()} />,
            'CREATE_SHOP': <CreateShop onFinish={() => this.handleClose()} />,
            'UPDATE_SHOP': <UpdateShop shop={this.context.shop} updateInterview={true}/>,
            'INTERVIEW_SUCCESS': <InterviewSuccess closeModal={(e) => this.handleClose(e)} />,
            'CREATE_SHOP_INTERVIEW': <CreateShop interview={true} interviewObj={this.context.interview} onFinish={() => this.handleClose()} />
        };
        if(!modalContents[key]){
            return;
        }
        return modalContents[key];
    }

    componentDidUpdate(){
        if(this.modal.current){
            if(this.isScrollable()){
                this.modal.current.classList.add('scroll-active');
            } else{
                this.modal.current.classList.remove('scroll-active');
            }
        }
        if(!this.context.modalIsOpen){
            this.handleClose();
        }
    }

    isScrollable(){
        let el = document.querySelector('.w-modal__content__main');
        if(el){
            let hasVerticalScrollbar = el.scrollHeight > el.clientHeight;
            return hasVerticalScrollbar;
        }
        return false;
    }

    render() {
        if(this.context.modalContent){
            return(

                <div ref={this.modal} className={this.state.className} onClick={(e) => this.handleClose(e)}>

                    <div className={"w-modal " + (this.context.interviewSuccess ? "w-modal--small" : "")} onClick={(e) => e.stopPropagation()}>

                        <div className="w-modal__close" onClick={(e) => this.handleClose(e)}></div>

                        <div className="w-modal__content">
                            {this.handleContent(this.context.modalContent)}
                        </div>

                    </div>

                </div>

            );
        }
        else{
            return null;
        }
    }

}

Modal.contextType = GlobalContext;