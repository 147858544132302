import {Storage} from 'app/Core';
import {UserAction} from "./UserAction";

export class UserClass extends UserAction {

    datas = {};

    ROLE_FDV        = 'ROLE_FDV';
    ROLE_MANAGER    = 'ROLE_MANAGER';
    ROLE_SUPERVISOR = 'ROLE_SUPERVISOR';
    ROLE_ADMIN      = 'ROLE_ADMIN';

    constructor() {
        super();
    }

    get id() {
        return this.datas['id']
    }

    get email() {
        return this.datas['email']
    }

    get lastname() {
        return this.datas['lastname']
    }

    get firstname() {
        return this.datas['firstname']
    }

    get token() {
        return this.datas['token']
    }

    get role() {
        return this.datas['role']
    }

    get isManager() {
        return this.role === this.ROLE_MANAGER;
    }

    get isFdv() {
        return this.role === this.ROLE_FDV;
    }

    get isSupervisor() {
        return this.role === this.ROLE_SUPERVISOR;
    }

    get isAdmin() {
        return this.role === this.ROLE_ADMIN;
    }

    get isLogged() {
        return !(this.datas.token == null);
    }

    start() {

        return new Promise((resolve, reject) => {

            this.load().then(datas => {
                this.datas = datas;
                this.verifyToken(this.datas).then(resolve);
            });

        });

    }

}