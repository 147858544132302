import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Loader.css';

class Loader extends Component {

    render() {
        return (
            <div className="w-load-content">
                {this.props.children}
                <div className="row-small">
                    {[...Array(this.props.nbItems)].map((x, i) =>
                        <div className={"bloc " + (!this.props.fullWidth ? "bloc--half" : "")} key={i}>
                            <div className="bloc__inner animated"></div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

}

Loader.propTypes = {
    nbItems: PropTypes.number,
    fullWidth: PropTypes.bool
};

Loader.defaultProps = {
    nbItems: 4,
    fullWidth: false
};

export default Loader;