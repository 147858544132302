import React, {Component} from 'react';
import PropTypes from "prop-types";
import Textarea from "./Textarea";


import {User} from "app/Core";
export default class Turnover extends Component {


    constructor(props) {
        super(props);
    }


    render() {

        return (

            <div className={'Turnover'}>
                <h2 className={'Turnover__title h2'}>{this.props.title}</h2>

                <div className={'Turnover__in'}>
                    {
                        [0,1].map((i) => {

                            return <div className={'Turnover__row'} key={`turnover_row_${i}`}>

                                <div className={'Turnover__col'}>
                                    <label>Année</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][year]`} value={this.props.value && this.props.value[i] && this.props.value[i]['year'] ? this.props.value[i]['year'] : ''} onChange={(e) => {this.props.onChange(i, 'year', e.target.value)}} onBlur={this.props.onBlur} />
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>Evol CA Hager / N-1</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_hager]`} value={this.props.value && this.props.value[i] && this.props.value[i]['ca_hager'] ? this.props.value[i]['ca_hager'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_hager', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>%</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>Evol CA PDV / N-1</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_pdv]`} value={this.props.value && this.props.value[i] && this.props.value[i]['ca_pdv'] ? this.props.value[i]['ca_pdv'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_pdv', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>%</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>CA Hager du PdV</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_hager_pdv]`} value={this.props.value && this.props.value[i] && this.props.value[i]['ca_hager_pdv'] ? this.props.value[i]['ca_hager_pdv'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_hager_pdv', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>€</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>CA du PdV</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_pdv_total]`} value={this.props.value && this.props.value[i] && this.props.value[i]['ca_pdv_total'] ? this.props.value[i]['ca_pdv_total'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_pdv_total', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>€</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>Part de marché</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor || true} type="number" name={`${this.props.name}[${i}][ca_part]`} value={this.props.value && this.props.value[i] && this.props.value[i]['ca_part'] ? this.props.value[i]['ca_part'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_part', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>%</span>
                                    </div>
                                </div>
                            </div>

                        })
                    }
                    {/*<div className={'inline Turnover__bottom'}>*/}
                    {/*    <div className={'inline checkbox-row Turnover__bottom__item'}>*/}
                    {/*        <label className={'checkbox-w'}>*/}
                    {/*            <input disabled={User.isSupervisor} id={"vva"} type="radio" checked={this.props.value && this.props.value[3] && this.props.value[3]['vva_stv'] && this.props.value[3]['vva_stv'] === 'vva'} name={`${this.props.name}[3]`} value={'vva'} onChange={(e) => {this.props.onChange(3, 'vva_stv', e.target.checked ? 'vva' : '')}}  onBlur={this.props.onBlur} />*/}
                    {/*            <i className="icon icon-check mark"  />*/}
                    {/*        </label>*/}
                    {/*        <label className={'checkbox-label'} htmlFor={'vva'}>VVA</label>*/}
                    {/*    </div>*/}
                    {/*    <div className={'inline checkbox-row Turnover__bottom__item'}>*/}
                    {/*        <label className={'checkbox-w'}>*/}
                    {/*            <input disabled={User.isSupervisor} id={"stv"} type="radio" checked={this.props.value && this.props.value[3] && this.props.value[3]['vva_stv'] && this.props.value[3]['vva_stv'] === 'stv'} name={`${this.props.name}[3]`} value={'stv'} onChange={(e) => {this.props.onChange(3, 'vva_stv', e.target.checked ? 'stv' : '')}}  onBlur={this.props.onBlur} />*/}
                    {/*            <i className="icon icon-check mark"  />*/}
                    {/*        </label>*/}
                    {/*        <label className={'checkbox-label'} htmlFor={'stv'}>STV</label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>
        );
    }

}

Turnover.propTypes = {
    title:PropTypes.string,
    name:PropTypes.string.isRequired,
    value:PropTypes.array
};

Turnover.defaultProps = {
    title:'Niveau d’engagement'
};
