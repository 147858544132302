import React, { createContext, Component } from "react";

export const GlobalContext = createContext({

    modalContent: null,
    shop: {},
    contact: {},
    contacts: [],
    interview: {},
    interviewSuccess: false,
    modalIsOpen: true,

    userSalePoints: [],
    userSaleUnits: [],
    salePointTypes: [],

    questionPageTypes:[],

    setModalContent: () => {},
    closeModal: () => {},
    setShop: () => {},

    allowedConditionalPages: [],
    addAllowedConditionalPage: () => {},
    removeAllowedConditionalPage: () => {},

    isDirection: false

});

class GlobalProvider extends Component {

    constructor(props) {
        super(props);

        this.state = {

            modalContent: null,
            shop: {},
            contact: {},
            contacts: [],
            interview: {},
            interviewSuccess: false,
            modalIsOpen: true,
            creationProcess: true,

            userSalePoints: [],
            userSaleUnits: [],
            salePointTypes: [],

            questionPageTypes:[],

            allowedConditionalPages: [],

            isDirection: false,

            setModalContent: (modalContent) => {
                this.setState({ modalContent: modalContent });
            },
            setShop: (modalContent, shop, interview={}, creationProcess=true) => {
                this.setState({ modalContent: modalContent, shop: shop, interview: interview, creationProcess: creationProcess });
            },
            setContact: (modalContent, shop, contact) => {
                this.setState({ modalContent: modalContent, shop: shop, contact: contact });
            },
            setContacts: (modalContent, shop, contacts, interview) => {
                this.setState({ modalContent: modalContent, shop: shop, contacts: contacts, interview:interview });
            },
            deleteModal : () => {
                this.setState({ modalIsOpen: true, modalContent: null, interviewSuccess: false });
            },
            closeModal : () => {
                this.setState({ modalIsOpen: false, requestShops: {}, interviewSuccess: false });
            },
            setInterview: (interview) => {
                this.setState({ interview: interview });
            },
            setInterviewSuccess: (modalContent) => {
                this.setState({ modalContent: modalContent, interviewSuccess: true })
            },

            setUserSalePoints: (userSalePoints) => {
                this.setState({ userSalePoints: userSalePoints })
            },
            setUserSaleUnits: (userSaleUnits) => {
                this.setState({ userSaleUnits: userSaleUnits })
            },
            setSalePointTypes: (salePointTypes) => {
                this.setState({ salePointTypes: salePointTypes })
            },

            setQuestionPageTypes: (questionPageTypes) => {
                this.setState({ questionPageTypes: questionPageTypes })
            },

            getActiveQuestionPages: () => {
                let activePages = [];
                if(this.state.questionPageTypes){
                    this.state.questionPageTypes.forEach((type) => {

                        type.active_question_pages.forEach(page => {

                            page.type = type.name;

                            if ( (!page.is_conditional || (page.is_conditional && this.pageIsAllowed(page.id)) ) && ((!this.state.isDirection) || (this.state.isDirection && page.allow_direction))) {
                                activePages.push(page);
                            }

                        });
                    })
                }

                return activePages;

            },

            getFormationPage:() => {
                //dirty for now need to get it from ws
                if(this.state.questionPageTypes){
                    const lastType = this.state.questionPageTypes[this.state.questionPageTypes.length - 2];
                    if(lastType && lastType.active_question_pages){
                        return lastType.active_question_pages[lastType.active_question_pages.length - 1];
                    }
                }
            },

            addAllowedConditionalPage: (pageId) => {
                if (!this.pageIsAllowed(pageId)) {

                    let cpPages = this.state.allowedConditionalPages.slice(0);
                    cpPages.push(parseInt(pageId,10));

                    this.setState({ allowedConditionalPages: cpPages })
                }
            },
            removeAllowedConditionalPage: (pageId) => {

                let index = this.state.allowedConditionalPages.indexOf(parseInt(pageId));
                if(index === -1){
                    return;
                }
                let cpPages = this.state.allowedConditionalPages.slice(0);
                cpPages.splice(index, 1);

                this.setState({ allowedConditionalPages: cpPages })
            },

            setIsDirection: (direction) => {
                this.setState({ isDirection: direction })
            },
        };
    };

    pageIsAllowed(pageId){

        for(let i= 0; i < this.state.allowedConditionalPages.length; i++) {
            if(parseInt(pageId) === parseInt(this.state.allowedConditionalPages[i])) {
                return true;
            }
        }
        return false;
    };

    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                {this.props.children}
            </GlobalContext.Provider>
        );
    }
}

export default GlobalProvider;
