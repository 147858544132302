import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

export default class RatingWithAnswers extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        // Apply default value (from pdv 2022 import)
        this.pdvDefault = null;
        if( typeof this.props.pdv.default2022_datas != "undefined" && this.props.pdv.default2022_datas && typeof this.props.pdv.default2022_datas[this.props.label] != "undefined" && this.props.pdv.default2022_datas[this.props.label] ) {
            let val = this.props.pdv.default2022_datas[this.props.label];
            switch (val) {
                case 'No': val = "Non"; break;
                case 'Yes': val = "Oui"; break;
                case 'Deuxième étoile': val = "2 étoiles"; break;
                case 'Première étoile': val = "1 étoile"; break;
                case 'Troisième étoile': val = "3 étoiles"; break;
            }
            this.pdvDefault = val;
        }

        return (
            <div className={'Rating question question--bg'}>
                <div className={'Rating__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Rating__control question__control">
                    {
                        this.props.answers.map((answer, i) => {
                            if(this.pdvDefault === answer.value && typeof this.props.value !== 'undefined' && !this.props.value) {
                                this.props.onChange(answer.value);
                            }
                            return <div className={'Rating__input-w'}  key={i}>
                                <input disabled={User.isSupervisor} className={'Rating__input'} id={`${this.props.name}_${answer.id}`} checked={this.props.value === answer.value || (this.pdvDefault === answer.value && !this.props.value)} type='radio' value={answer.value} name={this.props.name} onChange={() => this.props.onChange(answer.value)} onBlur={this.props.handleBlur} />
                                <label className={`Rating__label level-${answer.id}`} htmlFor={`${this.props.name}_${answer.id}`}><span>{answer.name}</span></label>
                            </div>
                        })
                    }
                    </div>
                </div>
            </div>
        );
    }

}

RatingWithAnswers.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.string,
    answers:PropTypes.array,
    pdv:PropTypes.object,
};

RatingWithAnswers.defaultProps = {

};
