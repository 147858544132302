import React from 'react';
import './SalePoint.css';
import './Interview/Fields/Fields.scss';

import Header from 'components/Header/Header';

import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";


import {SalePointService} from 'services/SalePointService';
import {QuestionService} from "services/QuestionService";
import {InterviewService} from "services/InterviewService";


import LoaderSpiral from "components/LoaderSpiral/LoaderSpiral";
import {withRouter} from "react-router-dom";

import { toast } from 'react-toastify';

import {routes} from "config/routes";
import InterviewNavigation from "../../components/InterviewNavigation/InterviewNavigation";
import QuestionPage from "./Interview/QuestionPage";
import StartingPage from "./Interview/StartingPage";

import Button from 'components/Button/Button';
import {isJSON, sanitizeForSearch} from "helpers/string";
import {priorities} from "utils/fields";
import Drawer from "./Drawer/Drawer";

import ProgressIndicator from './ProgressIndicator/ProgressIndicator';


import {Observer} from "app/Core";


class SalePoint extends PrivateComponent {

    constructor(props) {
        super(props);
        this.state = {
            salePoint: null,
            interview:null,
            page:null,
            isLoading:false,
            showDrawer:false,

            previousInterview:null,
        }

        this.questionPage = React.createRef();
        this.progress = React.createRef();


        Observer.subscribe('shopUpdated', () => {
            this.getSalePoint();
        });

        Observer.subscribe('updateContact', () => {
            this.getSalePoints();
        });


    }

    componentDidMount(){
        this.getSalePoint();

        this.getQuestionPage();


    }

    getSalePoints() {
        SalePointService.getUserList().then(response => {

            if(response.data && Array.isArray(response.data)) {
                this.context.setUserSalePoints(response.data);
                this.getSalePoint();
            }
        });
    }


    getInterview(salePoint) {

        SalePointService.getInterview(salePoint.id).then(response => {

            if(response.data) {
                this.setState({interview:response.data, isLoading:false});

                //add conditional page to navigation
                if(response.data.answers){
                    for(let i in response.data.answers){
                        let answer = response.data.answers[i];
                        if(answer.question && answer.question.conditional_page_id && parseInt(answer.answer) === parseInt(answer.question.conditional_page_id)){
                            this.context.addAllowedConditionalPage(parseInt(answer.answer));
                        }
                    }
                }
                //check if we need to add formation page (dependency on user priorities in actions)
                if(response.data.pages){
                    /*let addFormation = false;
                    for(let i in response.data.pages){
                        let page = response.data.pages[i];
                        if(page.actions && isJSON(page.actions)){
                            let actions = JSON.parse(page.actions);
                            for(let j in actions) {
                                let action = actions[j];
                                const actionPriority = sanitizeForSearch(action.priority).replace(/\s/g, '');
                                const refTechicalPriority = sanitizeForSearch(priorities.TECHNICAL_TEAM).replace(/\s/g, '');
                                const refComercialPriority = sanitizeForSearch(priorities.COMMERCIAL_TEAM).replace(/\s/g, '');

                                if(action.priority && (actionPriority === refComercialPriority || actionPriority === refTechicalPriority)){
                                    addFormation = true;
                                    break;
                                }
                            }
                        }
                        if(addFormation){
                            break;
                        }
                    }
                     */
                    let addFormation = true;
                    const formation = this.context.getFormationPage();
                    if(formation) {
                        if(addFormation){
                            this.context.addAllowedConditionalPage(formation.id);
                        }
                        else {
                            this.context.removeAllowedConditionalPage(formation.id);
                        }
                    }

                }
            }
        });


    }

    getPreviousInterview(salePoint) {

        SalePointService.getPreviousInterview(salePoint.id).then(response => {

            if(response.data) {
                this.setState({previousInterview:response.data});
            }
        });


    }



    getQuestionPageTypes() {
        QuestionService.getQuestionPageTypes().then(response => {

            if(response.data && Array.isArray(response.data)) {
                this.context.setQuestionPageTypes(response.data);
                this.getQuestionPage();
            }
        });
    }

    getSalePoint() {
        if(this.context.userSalePoints && this.context.userSalePoints.length && this.props.match.params.id){
            const salePoint = this.context.userSalePoints.find((item) => {
                return item.id === parseInt(this.props.match.params.id, 10);
            })

            if(salePoint){
                this.setState ({salePoint:salePoint});


                this.context.setIsDirection(salePoint.direction);


                this.getInterview(salePoint);

                this.getPreviousInterview(salePoint);

            }
            else{
                toast.error("Point de vente invalide. ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: '404_sale_point'
                });
                this.props.history.replace(routes.dashboard.route());
            }

        }
        else{
            this.getSalePoints();
        }

    }



    getQuestionPage() {



            if(this.context.getActiveQuestionPages() && this.context.getActiveQuestionPages().length ){



                if(this.props.match.params.pageId) {


                    let page =  this.context.getActiveQuestionPages().find((item) => {
                        return item.id === parseInt(this.props.match.params.pageId, 10);
                    });

                    if (page) {
                        this.setState({page: page});
                    }
                    else {
                        toast.error("Page invalide. ", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            toastId: '404_page'
                        });

                        if (this.state.salePoint) {
                            this.props.history.replace(routes.salePointSheet.route(this.state.salePoint));
                        }
                        else {
                            this.props.history.replace(routes.dashboard.route());
                        }
                    }
                }
                else {
                    this.props.history.replace(routes.questionPageSheet.route(this.props.match.params.id, this.context.getActiveQuestionPages()[0].id));
                }

            }
            else{
                this.getQuestionPageTypes();
            }

    }


    onSelectPage(item){

        this.savePage();

        this.setState ({page:item, showDrawer:false});

    }


    isFirstPage(page) {

        return this.context.getActiveQuestionPages() && this.context.getActiveQuestionPages().length
               && this.context.getActiveQuestionPages()[0].id === page.id;

    }

    isLastPage(page) {

        return this.context.getActiveQuestionPages() && this.context.getActiveQuestionPages().length
            && this.context.getActiveQuestionPages()[this.context.getActiveQuestionPages().length - 1].id === page.id;

    }




    savePage(notifyPriorities = false) {

        let values = this.questionPage && typeof this.questionPage.getValues !== 'undefined' ? this.questionPage.getValues() : null;

        let data = {
            question_page:this.state.page.id,
            notifyPriorities:notifyPriorities,
            values
        };


        this.setState({isLoading:true});

        InterviewService.savePage(this.state.interview.id, data).then(response => {

            this.getInterview(this.state.salePoint);
            this.getQuestionPage();
            if(notifyPriorities) {
                this.next();
            }

        }).catch(error => {

            let message = "Une erreur est survenue lors de l'enregistrement de cette étape.";

            try {

                message = JSON.parse(error.response.request.response).error.exception[0].message;
            }
            catch(e){}

            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: 'save_page_error'
            });


            this.setState({isLoading:false});
        });



    }


    notifyPriorities() {
        this.savePage(true);
    }

    goToPage(direction = "next") {

        let flattenPages = this.context.getActiveQuestionPages();

        for(let i in  flattenPages){

            if(flattenPages[i].id === this.state.page.id) {
                const index = direction === 'next' ? parseInt(i) + 1 : (direction === "previous" ? parseInt(i) - 1 : parseInt(i));
                if(flattenPages[index]) {
                    let route = routes.questionPageSheet.route(this.state.salePoint.id, flattenPages[index].id);
                    this.props.history.push(route);
                    break;
                }
            }
        }
    }


    next(){
       this.goToPage('next');
       this.savePage();
    }

    previous(){
        this.goToPage('previous');
        this.savePage();
    }

    leave() {
        this.savePage();

        let route = routes.dashboard.route();
        this.props.history.push(route);
    }


    getPage() {

        return <React.Fragment>

            {
                this.state.page
                ?
                    this.isFirstPage(this.state.page)
                    ?
                        <StartingPage
                            interview={this.state.interview}
                            salePoint={this.state.salePoint}
                            page={this.state.page}
                            childRef={ref => (this.questionPage = ref)}
                            validate={() => {this.savePage()}}
                            leave={() => {this.leave()}}
                            savePage={() => {this.savePage()}}
                        />
                    :
                        <QuestionPage validate={() => {this.savePage()}}
                                      savePage={() => {this.savePage()}}
                                      notifyPriorities={() => {this.notifyPriorities()}}
                                      leave={() => {this.leave()}}
                                      progress={this.progress}
                                      interview={this.state.interview}
                                      previousInterview={this.state.previousInterview}
                                      childRef={ref => (this.questionPage = ref)}
                                      salePoint={this.state.salePoint}
                                      page={this.state.page}
                        />
                :null
            }

            <div className={'SalePoint__nav'}>
                <div className={"SalePoint__nav__in container " + (this.isFirstPage(this.state.page) ? 'start' : '')}>
                    {!this.isFirstPage(this.state.page) ? <Button onClick={(e) => this.previous()} className={'btn--secondary'}>Étape précédente</Button> : null}
                    {!this.isLastPage(this.state.page) ? <Button onClick={(e) => this.next()} className={'btn--primary'}>Étape suivante</Button> : null}
                </div>
            </div>

        </React.Fragment>

    }

    render() {

        return (
            <div className="SalePoint">

                <Drawer interview={this.state.interview}  isLoading={this.state.isLoading || !this.state.interview} close={() => {this.setState({showDrawer:false});}} show={this.state.showDrawer} currentPage={this.state.page} salePoint={this.state.salePoint} onSelectItem={(item) => this.onSelectPage(item)} />

                <Header
                    topTitle={this.state.salePoint ? this.state.salePoint.name : null}
                    showSave={true}
                    menuToggle={() => {this.setState({showDrawer:!this.state.showDrawer});}}
                    saveInterview={() => {this.leave()}}
                    className="SalePoint__head"
                    subtitle={this.state.page ? this.state.page.type :""} title={this.state.page ? this.state.page.name :""}>

                    <ProgressIndicator ref={this.progress} interview={this.state.interview} />

                </Header>

                <div className="w-content">
                    <div className={''}>
                        <InterviewNavigation interview={this.state.interview} isLoading={this.state.isLoading || !this.state.interview} currentPage={this.state.page} salePoint={this.state.salePoint} onSelectItem={(item) => this.onSelectPage(item)} />
                    </div>
                    <div className="SalePoint__content">

                        {
                            this.state.isLoading || !this.state.salePoint || !this.state.interview || !this.state.page
                            ?
                                <LoaderSpiral/>
                            :
                            this.getPage()
                        }

                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(SalePoint);

SalePoint.contextType = GlobalContext;
