import React, {Component} from 'react';
import PropTypes from "prop-types";
import './Card.css';

export default class Card extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }


    getDate(value){
        let date = new Date(value);
        return date.toLocaleDateString('fr-FR');
    }
    

    edit(e) {
        e.preventDefault();
        e.stopPropagation();

        if(this.props.onEdit) {
            this.props.onEdit(e, this.props.data);
        }
    }


    render() {
        
        const className = this.props.shop ? 'w-item--shop' : this.props.contact ? 'w-item--contact' : this.props.interview && this.props.data.status ? 'w-item--interview' : '';

        const text = this.props.interview && this.props.data.status ? 'Validé le' : 'Dernière modification le';
        return (



                <div className={"w-item " + className}>

                        <div className="w-item__content" onClick={this.props.onClick ? () => this.props.onClick(this.props.data) :  null}>

                            <i className={'icon icon-contact'} />

                            <div>

                                <span className="w-item__content__name">
                                    {this.props.data.first_name ? this.props.data.first_name : null} {this.props.data.last_name ? this.props.data.last_name : null}
                                </span>

                                <div className="w-item__content__desc">
                                    {this.props.data.function}
                                </div>

                            </div>

                            {
                                this.props.edit
                                ?
                                    <button onClick={(e) => this.edit(e)} className={'edit'}><i className={'icon icon-edit'} /></button>
                                 : null
                             }

                        </div>


                </div>



        );
    }

}

Card.propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    shop: PropTypes.bool,
    contact: PropTypes.bool,
    interview: PropTypes.bool
};

Card.defaultProps = {
    data: {},
    shop: false,
    contact: false,
    interview: false
};