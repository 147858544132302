import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";

import AnimateHeight from 'react-animate-height';

export default class Training extends Component {


    constructor(props) {
        super(props);

        this.state = {
            heights: {}
        };

    }


    render() {


        return (

            <React.Fragment>

                <div>
                    <h2 className={'h2 Title'}>{this.props.title}</h2>
                    {
                        this.props.value.map((values,i) => {
                            return (
                                <div className={'accordeon Training'} key={i}>

                                    <div className="accordeon__head">
                                        <div className="h3"> #{i + 1} {this.props.value && this.props.value[i] && this.props.value[i]['training'] ? ' - ' + this.props.value[i]['training'] : ''} </div>
                                        <div>
                                            <button className={'btn-icon btn-accordeon'} onClick={(e) => {
                                                let heights = {...this.state.heights};
                                                heights[i] = heights[i] === 0 ? 'auto' : 0;
                                                this.setState({heights: heights});
                                            }}>
                                                {this.state.heights[i] === 0 ? 'Déplier' : 'Replier'} le contenu
                                                <i className={'icon icon-miniarrowright ' + (this.state.heights[i] === 0 ? 'down' : 'up')}/>
                                            </button>
                                        </div>
                                    </div>

                                    <AnimateHeight duration={ 500 } height={ this.state.heights[i] !== 0 ? 'auto' : 0 }>
                                        <div className="accordeon__body">

                                            {
                                                i > 0 && !User.isSupervisor
                                                ?
                                                    <button  className={'Training__delete btn-icon txt--danger'}  onClick={(e) => { e.preventDefault(); this.props.onDelete(i)}}>
                                                        <i className={'icon icon-delete'} />
                                                        Supprimer
                                                    </button>
                                                : null
                                            }

                                            <div className={'Training__row Training__columns'}>

                                                <div className={'Training__col'}>
                                                    <label>Formation</label>
                                                    <select disabled={User.isSupervisor} name={`${this.props.name}[${i}][training]`} value={this.props.value && this.props.value[i] && this.props.value[i]['training'] ? this.props.value[i]['training'] : ''}  onChange={this.props.onChange}  onBlur={this.props.onBlur}>
                                                        <option value=""></option>
                                                        {
                                                            this.props.answers.map((answer, j) => {
                                                                return <option key={j} value={answer.value}>{answer.name}</option>
                                                            })
                                                        }
                                                        <option value="other">Autre (Précisez)</option>
                                                    </select>
                                                </div>
                                                <div className={'Training__col'}>
                                                    <label>Période</label>
                                                    {/*<input disabled={User.isSupervisor} type="text"  name={`${this.props.name}[${i}][date]`} value={this.props.value && this.props.value[i] && this.props.value[i]['date'] ? this.props.value[i]['date'] : ''}  onChange={this.props.onChange}  onBlur={this.props.onBlur} />*/}
                                                    <select disabled={User.isSupervisor} name={`${this.props.name}[${i}][date]`} value={this.props.value && this.props.value[i] && this.props.value[i]['date'] ? this.props.value[i]['date'] : ''}  onChange={this.props.onChange}  onBlur={this.props.onBlur}>
                                                        <option value=""></option>
                                                        {
                                                            [{'period' : '1er trimestre'}, {'period' : '2ème trimestre'}, {'period' : '3ème trimestre'}, {'period' : '4ème trimestre'}].map((answer, j) => {
                                                                return <option key={j} value={answer.period}>{answer.period}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                this.props.team ?
                                                    <div className={'Training__row'}>
                                                        <label>Nom et prénom du/des candidats</label>
                                                        <textarea disabled={User.isSupervisor} name={`${this.props.name}[${i}][candidats]`} value={this.props.value && this.props.value[i] && this.props.value[i]['candidats'] ? this.props.value[i]['candidats'] : ''}  onChange={this.props.onChange}  onBlur={this.props.onBlur} />
                                                    </div>
                                                :
                                                (
                                                    this.props.client ?
                                                        <div className={'Training__row'}>
                                                            <label>Liste des candidats potentiels</label>
                                                            <textarea disabled={User.isSupervisor} name={`${this.props.name}[${i}][candidats]`} value={this.props.value && this.props.value[i] && this.props.value[i]['candidats'] ? this.props.value[i]['candidats'] : ''}  onChange={this.props.onChange}  onBlur={this.props.onBlur} />
                                                        </div>
                                                        : null
                                                )
                                            }
                                            <div className={'Training__row'}>
                                                <label>En cas de besoin particulier, précisez :</label>
                                                <textarea disabled={User.isSupervisor} name={`${this.props.name}[${i}][need]`} value={this.props.value && this.props.value[i] && this.props.value[i]['need'] ? this.props.value[i]['need'] : ''}  onChange={this.props.onChange}  onBlur={this.props.onBlur} />
                                            </div>
                                        </div>
                                    </AnimateHeight>
                                </div>
                            )
                        })
                    }
                    {
                        !User.isSupervisor
                        ?
                            <div className={'add-btn-w'}>
                                <button className={'btn-icon'} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onAdd()
                                }}>
                                    <i className={'icon icon-add txt--secondary'}/>
                                    Ajouter une formation
                                </button>
                            </div>
                        : null
                    }

                </div>

            </React.Fragment>
        );
    };

}

Training.propTypes = {
    title:PropTypes.string,
    value:PropTypes.array,
    answers:PropTypes.array,
    team: false,
    clients: false,
    agency: false,
};

Training.defaultProps = {
    title:'',
    value:[],
    answers:[]
};
