import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

export default class Univers extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (

            <div className={'Score question question--bg'}>
                <div className={'Score__in question__in ' + (this.props.vertical ? 'vertical' : '')}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Score__control question__control">

                            {
                                this.props.delete
                                ?
                                <button className={'Score__delete btn-icon txt--danger'} onClick={(e) => {e.preventDefault(); this.props.onDelete()} }><i className="icon icon-delete" />Supprimer</button>
                                : null
                            }

                        {
                            ['H','T','D', 'M', 'C', 'S', 'G'].map((item, i) => {
                                return <div className={'Score__input-w'} key={i}>
                                    <input  disabled={User.isSupervisor} className={'Score__input'} id={`${this.props.name}_${item}`}
                                            checked={Array.isArray(this.props.value) && this.props.value.includes(item) || this.props.value==item}
                                            type='checkbox' value={item} name={this.props.name + "[]"} onClick={() => this.props.onClick(item)}  onChange={() => this.props.onChange(item, i)} onBlur={this.props.handleBlur} />
                                    <label className={'Score__label'} htmlFor={`${this.props.name}_${item}`}><span>{item}</span></label>
                                </div>
                            })
                        }
                    </div>

                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}


            </div>
        );
    }

}

Univers.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.array,

};

Univers.defaultProps = {

};
