import {Network,Storage,Observer} from "app/Core";

import {SalePointAction} from "./SalePointAction";
import {Contact} from "../Contact/Contact";
import {Interviews, User} from "../Core";

export class SalePoint extends SalePointAction {

    datas = {
        id : 0,
        name : '',
        address : '',
        zip_code : '',
        city : '',
        region : '',
        uv : '',
        ag_com : '',
        type_pdv : '',
        crm_code : '',
        nb_contacts : 0,
        contacts: null,
        created_at: new Date(),
        updated_at: new Date(),
    };
    listInterviews = null;

    initContacts(contacts) {
        this.datas['contacts'] = null;
        contacts.forEach(contactDatas => {
            this.addContact(contactDatas);
        })
    }

    constructor(datas) {
        super();
        if(typeof datas != 'undefined') {
            if(typeof datas['contacts'] !== 'undefined') {
                this.datas = datas;
                this.listInterviews = null;
                this.initContacts(datas['contacts']);
            } else {
                this.listInterviews = null;
                this.datas = datas;
            }
        }
    }

    get id() {
        return this.datas['id'];
    }

    set id(id) {
        this.datas['id'] = id;
    }

    get name() {
        return this.datas['name'];
    }

    set name(name) {
        this.datas['name'] = name;
    }

    get crm_code() {
        return this.datas['crm_code'];
    }

    set crm_code(crm_code) {
        this.datas['crm_code'] = crm_code;
    }

    get address() {
        return this.datas['address'];
    }

    set address(address) {
        this.datas['address'] = address;
    }

    get zip_code() {
        return this.datas['zip_code'];
    }

    set zip_code(zipcode) {
        this.datas['zip_code'] = zipcode;
    }

    get city() {
        return this.datas['city'];
    }

    set city(city) {
        this.datas['city'] = city;
    }

    get region() {
        return this.datas['region'];
    }

    set region(region) {
        this.datas['region'] = region;
    }

    get uv() {
        return this.datas['uv'];
    }

    set uv(uv) {
        this.datas['uv'] = uv;
    }

    get ag_com() {
        return this.datas['ag_com'];
    }

    set ag_com(ag_com) {
        this.datas['ag_com'] = ag_com;
    }

    get type_pdv() {
        return this.datas['type_pdv'];
    }

    set type_pdv(type_pdv) {
        this.datas['type_pdv'] = type_pdv;
    }

    get createdAt() {
        return this.datas['created_at'];
    }

    get updatedAt() {
        return this.datas['updated_at'];
    }

    get nb_contacts() {
        if(this.datas['contacts'] == null) {
            return 0;
        } else {
            return this.datas['contacts'].length;
        }
    }

    get interviews() {
        return this.listInterviews === null ? [] : this.listInterviews;
    }

    get contacts() {
        return this.datas['contacts'] === null ? [] : this.datas['contacts'];
    }

    getContacts() {

        return new Promise((resolve, reject) => {

            if(this.datas['contacts'] == null) {
                this.getRemoteContacts(this.id).then(contacts => {
                    //this.datas['contacts'] = contacts;
                    this.initContacts(contacts);
                    resolve(this.datas['contacts']);
                });
            } else {
                resolve(this.datas['contacts']);
            }

        });

    }

    findContact(id) {

        return new Promise((resolve, reject) => {

            this.getContacts().then(contacts => {

                if(contacts === null) {
                    resolve(null);
                    return;
                }

                resolve(contacts.find(contact => {
                    return contact.id === id;
                }));

            });

        });

    }

    addContact(datas) {

        if (this.datas['contacts'] == null) {
            this.datas['contacts'] = [];
        }
        let contact = new Contact(datas);
        this.datas['contacts'].push( contact );
        return contact;

    }

    updateAndSaveContact(Contact) {

        let index = this.contacts.findIndex(value => {
            return Contact.id !== value.id;
        });
        this.datas['contacts'][index] = Contact;
        return this.save();

    }

    removeContact(contact) {

        return new Promise((resolve, reject) => {

            if (this.datas['contacts'] == null) {
                this.datas['contacts'] = [];
            }
            contact.removeRemote(contact, this.id).then(_ => {
                this.datas['contacts'] = this.datas['contacts'].filter((value, index) => {
                    return contact.id != value.id;
                });
                resolve(true);
            }).catch(cb => {
                resolve(false);
            });

        });

    }

    save() {

        return new Promise((resolve, reject) => {

            return this.saveRemote(this.datas).then(salepoint => {
                this.datas = salepoint;
                this.initContacts(salepoint['contacts']);
                resolve();
            }).catch(cb => {
                reject(cb);
            });

        });

    }

    getInterviews(allUsers = false) {

        return new Promise((resolve, reject) => {

            if(this.listInterviews !== null) {

                resolve((allUsers)?this.listInterviews:this.filterInterviewFromLoggedUser());

            } else {

                Interviews.getList(this.id).then(list => {
                    this.listInterviews = list;
                    resolve((allUsers)?this.listInterviews:this.filterInterviewFromLoggedUser());
                });

            }

        });

    }

    checkIfWeCanCreateANewInterview() {

        return new Promise((resolve, reject) => {

            this.checkIfWeCanCreateANewInterviewRemote(this.id).then(possible => {
                resolve(possible);
            }).catch(cb => {
                resolve(cb);
            });

        });

    }

    filterInterviewFromLoggedUser() {

        return this.listInterviews.filter(interview => {
            return interview.userId === User.id;
        })

    }

    requiredInformationsNotMissing() {

        return ( !this.type_pdv || !this.ag_com || !this.uv || !this.region ) ? false : true;

    }

}