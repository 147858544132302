import React, {Component} from "react";
import PropTypes from "prop-types";

import {Field, withFormik} from "formik";
import * as Yup from "yup";
import Button from 'components/Button/Button';
import {Observer} from "../../app/Core";
import { toast } from 'react-toastify';
import {SalesUnitService} from "../../services/SalesUnitService";
import CreateShop from "../Modal/CreateShop/CreateShop";

import { GlobalContext } from "providers/GlobalProvider";
import {SalePointService} from "../../services/SalePointService";
import LoaderSpiral from "../LoaderSpiral/LoaderSpiral";
import {UserServices} from "../../services/UserServices";

import {User} from "app/Core";

import Autocomplete from 'react-autocomplete';




class UpdateShop extends Component {

    constructor(props) {
        super(props);

        this.state = {fdvs:null, fromDirection:true, directionId:null, pdvs:[]};

        Observer.subscribe('fromDirection', (directionId) => {

            this.setState({fromDirection:true});

            this.props.setFieldValue('direction_id', directionId);
            this.props.setFieldValue('direction', 0);
        });

    }

    getUserSalesUnits() {

        SalesUnitService.getUserList().then(response => {
            if(response.data && Array.isArray(response.data)) {
                this.context.setUserSaleUnits(response.data)
            }
        });

        SalePointService.getTypes().then(response => {
            if(response.data && Array.isArray(response.data)) {
                this.context.setSalePointTypes(response.data);
            }
        });

    }

    getFdvs() {
        UserServices.getFdvs(true).then(response => {

            if (200 === response.status) {
               this.setState({fdvs:response.data});
            }

        });
    }

    componentDidMount() {

        if(!this.context.userSaleUnits.length) {
            this.getUserSalesUnits();
        }

        if(User.isManager || User.isAdmin || User.isSupervisor) {
            this.getFdvs();
        }
    }


    render(){

        return(
            <React.Fragment>

                <h3 className="title h3">Informations point de vente</h3>

                <form noValidate onSubmit={this.props.handleSubmit}>

                    <div className="w-content">

                        <Field type="hidden" name="id" />
                        <div className="w-field-group autocomplete-w">
                            <div className={"w-field w-field--required " + (this.props.errors.crm_id && this.props.touched.crm_id ? "w-field--error" : "")}>
                                <label htmlFor="crm-id" className={"w-field__label"}>Numéro SAP</label>
                            <Autocomplete
                                className="w-field__control"
                                menuStyle={{
                                    position:'absolute',
                                    left:0,
                                    top:'100%',
                                    backgroundColor:'white',
                                    border:'1px solid #AFAFAF',
                                    right:0
                                }}
                                getItemValue={(item) => item.crm_id }
                                items={this.state.pdvs}
                                value={this.props.values.crm_id}
                                renderItem={(item, isHighlighted) => (
                                    <div
                                        className={`autocomplete-item ${isHighlighted ? 'highlighted' : ''}`}
                                        key={item.id}
                                    >{item.crm_id} - {item.name} - {item.city} </div>
                                )}
                                onSelect={(val, obj) => {
                                    this.props.setFieldValue('crm_id', obj.crm_id);
                                    this.props.setFieldValue('id', obj.id);
                                    this.props.setFieldValue('name', obj.name);
                                    this.props.setFieldValue('address', obj.address);
                                    this.props.setFieldValue('city', obj.city);
                                    this.props.setFieldValue('zip_code', obj.zip_code);
                                    this.props.setFieldValue('type_pdv', obj.type_pdv);
                                    if(obj.sales_unit) {
                                        this.props.setFieldValue('sales_unit', obj.sales_unit.id);
                                    }
                                    if(obj.user_id){
                                        this.props.setFieldValue('fdv', obj.user_id);
                                    }
                                }}
                                onChange={(e) => {
                                    SalePointService.autocomplete(e.target.value).then(response =>{

                                        if(response.status == 200){
                                            this.setState({pdvs: response.data});
                                        }

                                    });
                                    this.props.setFieldValue('crm_id', e.target.value);
                                }}
                            />
                                {this.props.errors.crm_id && this.props.touched.crm_id && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.crm_id}</div>}
                            </div>
                        </div>
                        {/*
                        <div className="w-field-group">
                            <div className={"w-field w-field--required " + (this.props.errors.crm_id && this.props.touched.crm_id ? "w-field--error" : "")}>
                                <label htmlFor="crm-id" className={"w-field__label"}>Numéro SAP</label>
                                <Field type="text" name="crm_id" placeholder={"SAP"} id="crm-id" className="w-field__control" disabled={this.props.update ? true : false}/>
                                {this.props.errors.crm_id && this.props.touched.crm_id && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.crm_id}</div>}
                            </div>
                        </div>
                        */}

                        <div className="w-field-group">
                            <div className={"w-field w-field--required " + (this.props.errors.name && this.props.touched.name ? "w-field--error" : "")}>
                                <label htmlFor="company-name" className={"w-field__label"}>Nom de l'enseigne</label>
                                <Field type="text" name="name" placeholder={"Nom"}  id="company-name" className="w-field__control"/>
                                {this.props.errors.name && this.props.touched.name && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.name}</div>}
                            </div>
                        </div>

                        <div className="w-field-group">
                            <div className={"w-field w-field--required " + (this.props.errors.address && this.props.touched.address ? "w-field--error" : "")}>
                                <label htmlFor="address" className={"w-field__label"}>Adresse</label>
                                <Field type="text" name="address" placeholder={"Adresse"} id="address" className="w-field__control"/>
                                {this.props.errors.address && this.props.touched.address && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.address}</div>}
                            </div>
                        </div>

                        <div className="w-field-group">
                            <div className={"w-field w-field--required field-postal-code " + (this.props.errors.zip_code && this.props.touched.zip_code ? "w-field--error" : "")}>
                                <label htmlFor="zip-code" className={"w-field__label"}>Code postal</label>
                                <Field type="text" name="zip_code" placeholder={"Code postal"} id="zip-code" className="w-field__control"/>
                                {this.props.errors.zip_code && this.props.touched.zip_code && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.zip_code}</div>}
                            </div>

                            <div className={"w-field w-field--required field-city " + (this.props.errors.city && this.props.touched.city ? "w-field--error" : "")}>
                                <label htmlFor="city" className={"w-field__label"}>Ville</label>
                                <Field type="text" name="city" placeholder={"Ville"} id="city" className="w-field__control"/>
                                {this.props.errors.city && this.props.touched.city && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.city}</div>}
                            </div>
                        </div>

                        <div className="w-field-group">
                            <div className={"w-field w-field--half w-field--required field-ag-com " + (this.props.errors.sales_unit && this.props.touched.sales_unit ? "w-field--error" : "")}>
                                <label htmlFor="sales_unit" className={"w-field__label"}>Unité de ventes</label>
                                <Field component="select" name="sales_unit" placeholder={"Choisissez une unité de ventes"} id="sales_unit" className="w-field__control">

                                     <option value="">Choisir</option>
                                    {this.context.userSaleUnits.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>;
                                    })}
                                </Field>
                                {this.props.errors.sales_unit && this.props.touched.sales_unit && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.sales_unit}</div>}
                            </div>

                            <div className={"w-field w-field--half w-field--required field-type-pdv " + (this.props.errors.type_pdv && this.props.touched.type_pdv ? "w-field--error" : "")}>
                                <label htmlFor="type_pdv" className={"w-field__label"}>Type PDV</label>
                                <Field component="select" name="type_pdv" placeholder={"Type PDV"} id="type_pdv" className="w-field__control">
                                    <option value="">Choisir</option>
                                    {this.context.salePointTypes.map((item, i) => {
                                        return <option key={i} value={item}>{item}</option>;
                                    })}
                                </Field>
                                {this.props.errors.type_pdv && this.props.touched.type_pdv && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.type_pdv}</div>}
                            </div>
                        </div>

                        {
                            (User.isManager || User.isAdmin) && this.state.fdvs
                                ?
                                <div className="w-field-group">
                                    <div className={"w-field w-field--required field-fdv " + (this.props.errors.fdv && this.props.touched.fdv ? "w-field--error" : "")}>
                                        <label htmlFor="fdv" className={"w-field__label"}>Commercial</label>
                                        <Field component="select" name="fdv" placeholder={"Choisissez un commercial"} id="fdv" className="w-field__control">

                                            <option value="">Choisir</option>
                                            {this.state.fdvs.map((item, i) => {
                                                return <option key={i} value={item.id}>{item.crm_id} - {item.firstname} {item.lastname}</option>;
                                            })}
                                        </Field>
                                        {this.props.errors.fdv && this.props.touched.fdv && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.fdv}</div>}
                                    </div>
                                </div>

                                : null
                        }

                        {/*
                            !this.state.fromDirection
                            ?

                                <div className="w-field-group">
                                    <div className={"w-field w-field--required field-direction " + (this.props.errors.direction && this.props.touched.direction ? "w-field--error" : "")}>
                                        <label htmlFor="direction" className={"w-field__label"}>Ce point de vente est une direction ?</label>
                                        <Field component="select" name="direction"  id="direction" className="w-field__control">
                                            <option value="0">Non</option>
                                            <option value="1">Oui</option>
                                        </Field>
                                        {this.props.errors.direction && this.props.touched.direction && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.direction}</div>}
                                    </div>
                                </div>



                            : <Field type="hidden" value={this.state.directionId} name="direction_id"  id="direction_id" className="w-field__control" />
                        */}


                    </div>



                    <div className={"w-btn " + (!this.props.update && !this.props.interview ? "w-btn--next" : "")}>
                        {this.props.update || this.props.interview ? <Button className={"btn--secondary"} content={this.props.update ? "Retour" : this.props.updateInterview ? "Annuler" : "Précédent"} onClick={() => this.props.handleStep(0)}/> : null}
                        {this.props.loading ? <Button content={"Veuillez patienter..."}/> :  <Button type='submit' disabled={this.props.isSubmitting}>{this.props.isSubmitting ? <div className="w-loading"><LoaderSpiral/></div> : (this.props.update ? "Valider" : "Suivant")}</Button> }
                    </div>

                </form>

            </React.Fragment>
        );
    }
}

UpdateShop.propTypes = {
    shop: PropTypes.object,
    interview: PropTypes.bool,
    handleStep: PropTypes.func,
    update: PropTypes.func,
    setLoading: PropTypes.func,
    updateInterview: PropTypes.bool
};

UpdateShop.defaultProps = {
    shop: {},
    interview: false,
    updateInterview: false
};

UpdateShop.contextType = GlobalContext;

export default withFormik({


    enableReinitialize: true,

    mapPropsToValues: (props) => ({
        crm_id: props.shop && props.shop.crm_id ? props.shop.crm_id : '',
        name: props.shop && props.shop.name ? props.shop.name : '',
        address: props.shop && props.shop.address ? props.shop.address : '',
        zip_code: props.shop && props.shop.zip_code ? props.shop.zip_code : '',
        city: props.shop && props.shop.city ? props.shop.city : '',
        //region: props.shop && props.shop.region ? props.shop.region : '',
        //uv: props.shop && props.shop.uv ? props.shop.uv : '',
        //ag_com: props.shop && props.shop.ag_com ? props.shop.ag_com : '',
        type_pdv: props.shop && props.shop.type_pdv ? props.shop.type_pdv : '',
        sales_unit: props.shop && props.shop.sales_unit ? props.shop.sales_unit.id : '',
        fdv:props.shop && props.shop.user_id ? props.shop.user_id : '',
        //direction: props.shop ? 1 : 0,
        id: props.shop ? props.shop.id : false,
        direction_id:props.shop && props.shop.directionPdv ? props.shop.directionPdv.id : ''
    }),

    validationSchema: (props) => {
        return Yup.object().shape({
            crm_id: Yup.string().required("Champ obligatoire"),
            name: Yup.string().required("Champ obligatoire"),
            address: Yup.string().required("Champ obligatoire"),
            zip_code: Yup.string().required("Champ obligatoire").matches(/^([0-9]{5})$/, 'Le code postal doit être composé de 5 chiffres'),
            city: Yup.string().required("Champ obligatoire"),
            //region: Yup.string().required("Champ obligatoire"),
            //uv: Yup.string().required("Champ obligatoire"),
            //ag_com: Yup.string().required("Champ obligatoire"),
            type_pdv: Yup.string().required("Champ obligatoire"),
            sales_unit: Yup.number().required("Champ obligatoire"),
            fdv:(User.isManager || User.isAdmin) ? Yup.number().required("Champ obligatoire"): Yup.number()
        });
    },

    handleSubmit: (values, { setSubmitting, setStatus, setFieldError,  props }) => {


        if(props.update){
            props.update(values);
        } else {
            // We have to chek the crm
            console.log(values);

            if (!values.direction_id) {


                SalePointService.checkCrmId(values.crm_id).then(response => {

                    let message = 'Le numéro SAP existe déjà, il n\'est pas possible de créer un autre point de vente avec ce numéro.';

                    if (User.isFdv) {
                        message += ' Contactez votre Chef de Vente afin qu’il vous assigne ce PdV.';
                    }

                    if (response.data) {
                        setFieldError('crm_id', message);
                        setSubmitting(false);
                    } else {


                        props.handleStep(2, values);
                    }

                }).catch(error => {

                    toast.error("Une erreur est survenue lors de la vérification du numéro SAP.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        toastId: 'login_error'
                    });

                    setSubmitting(false);

                })
            }
            else {
                props.handleStep(2, values);
            }

        }
    },

})(UpdateShop);
