import React, {Component} from 'react';
import './Navigation.css';

import { NavLink } from "react-router-dom";

import {routes} from "config/routes";

import {User} from "app/Core";

export default class Navigation extends Component {

    render() {
        return (
            <div className="Navigation">

                <div className="w-content col-8 m-auto">
                    <div className="w-link pdv">
                        <NavLink to={routes.dashboard.route()}>Points de vente</NavLink>
                    </div>


                    <div className="w-link stats">
                        <NavLink to={routes.stats.route()}>Statistiques</NavLink>
                    </div>
                    {
                        !User.isFdv ?
                        <div className="w-link sales">
                            <NavLink to={routes.commercial.route()}>Commerciaux Hager</NavLink>
                        </div>
                            :null
                    }
                </div>

            </div>
        );
    }

}
