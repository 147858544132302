import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";

import {GlobalContext} from "providers/GlobalProvider";

import UpdateShopForm from "components/Forms/UpdateShop";
import LoaderSpiral from "../../LoaderSpiral/LoaderSpiral";
import {SalePointService} from "../../../services/SalePointService";
import {toast} from "react-toastify";


import {Observer} from "app/Core";

class UpdateShop extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false
        };
    }

    closeModal(){
        if(this.props.previous){
            this.props.previous();
        } else {
            this.context.closeModal();
        }
    }

    updateShop(datas) {
        /*this.props.shop.address = datas.address;
        this.props.shop.city = datas.city;
        this.props.shop.name = datas.name;
        this.props.shop.zip_code = datas.zip_code;
        this.props.shop.crm_code = datas.crm_code;
        this.props.shop.region = datas.region;
        this.props.shop.uv = datas.uv;
        this.props.shop.ag_com = datas.ag_com;
        this.props.shop.type_pdv = datas.type_pdv;*/
        /*this.props.shop.save().then(_ => {
            this.closeModal();
        }).catch(cb => {
            alert('Les informations n\'ont pas pu être enregistrées');
        });*/
        SalePointService.save(datas).then(response => {

            if(200 === response.status){
                SalePointService.getUserList().then(response => {

                    if(response.data && Array.isArray(response.data)) {
                        this.context.setUserSalePoints(response.data);
                    }

                    Observer.publish('shopUpdated');
                    //this.setState({isLoading:false});
                    this.context.closeModal();


                }).catch(error => {
                    this.setState({isLoading:true});
                    toast.error("Une erreur est survenue lors de la sauvegarde de l'entretien.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        toastId: 'interview_save_error'
                    });
                })
            }


        }).catch(error => {

            this.setState({isLoading:false});
        });
    }

    setLoading(isLoading) {
        this.setState({
            isLoading : isLoading
        });
    }

    render() {

        return (
            <React.Fragment>

                <ModalHead subtitle={"Point de vente"} title={this.props.updateInterview ? "Modifier les infos" : "Modifier les données"} />

                <ModalContent>
                    {this.state.isLoading ? <div className="w-loading"><LoaderSpiral/></div> : null}
                    <UpdateShopForm setLoading={(isLoading) => this.setLoading(isLoading)} updateInterview={this.props.updateInterview} update={(shop) => this.updateShop(shop)} handleStep={() =>  this.closeModal()} shop={this.props.shop} />
                </ModalContent>

            </React.Fragment>
        );
    }

}

UpdateShop.contextType = GlobalContext;

UpdateShop.propTypes = {
    shop: PropTypes.object.isRequired,
    previous: PropTypes.func,
    updateInterview: PropTypes.bool,
};

UpdateShop.defaultProps = {
    shop: {},
    updateInterview: false
};

export default UpdateShop;

