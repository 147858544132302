import {Network} from "app/Core";



export class SalesUnitService {


    static getUserList() {

        return Network.get('/api/usersalesunits');

    }

}
