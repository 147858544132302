import axios from 'axios';
import networkConfig from 'config/network';
import {User} from "app/Core";




export class Network {
    
    constructor() {

        this.axios = axios;
        this.networkConfig = networkConfig;


        this.axios.defaults.baseURL = this.networkConfig.url;

        axios.interceptors.request.use((config) => {

            if (User.isLogged) {
                config.headers.Authorization = `Bearer ${User.token}`;
                return config;
            }
            else {
                return config;
            }
        }, (error) => {
            return Promise.reject(error);
        });


    }

    interceptConfig() {
        if(User.isLogged) {
            return {
                headers : {
                    Authorization : `Bearer ${User.token}`
                }
            };
        }
        return {};
    }


    get(url, config) {
        return this.axios.get(url, config);
    }


    rawPost(url, params) {
        return this.axios.post(url, params);
    }



    post(url, params) {

        return new Promise((resolve, reject) => {

            this.loading = true;

            this.axios.post(this.networkConfig.url+url, params, this.interceptConfig())
                .then(response => {

                    //console.log(response);

                    switch(response.status) {
                        case 200:
                            let datas = response.data;
                            resolve({
                                success : datas.success,
                                error: (typeof datas.error != 'undefined') ? datas.error : null,
                                datas : datas
                            });
                        break;
                        default:
                            resolve({
                                success : false,
                                error: response.statusText
                            });
                        break;
                    }

            }).catch(err => {
                this.loading = false;
                resolve({
                    success : false,
                    error: err
                })
            });

        });

    }

};