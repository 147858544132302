import React, {Component} from 'react';
import './Login.css';
import Logo from 'assets/images/logo-white.svg';

import Button from 'components/Button/Button';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {User} from 'app/Core';

class Login extends Component {

    render() {
        return (
            <div className="Login d-flex align-items-center">

                <div className="container">

                    <img src={Logo} alt={"Logo Hager"} className={"logo"}/>

                    <div className="w-content col-6 m-auto">

                        <h2 className="title h2">Connexion</h2>

                        <form noValidate onSubmit={this.props.handleSubmit}>

                            <div className="w-field-group">
                                <div className={"w-field w-field--required " + (this.props.errors.email && this.props.touched.email ? "w-field--error" : "")}>
                                    <label htmlFor="email" className={"w-field__label"}>Email</label>
                                    <Field type="email" name="email" placeholder={"Votre adresse email"} id="email" className="w-field__control"/>
                                    {this.props.errors.email && this.props.touched.email && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.email}</div>}
                                </div>
                            </div>

                            <div className="w-field-group">
                                <div className={"w-field w-field--required " + (this.props.errors.matricule_number && this.props.touched.matricule_number ? "w-field--error" : "")}>
                                    <label htmlFor="matricule_number" className={"w-field__label"}>Numéro de matricule</label>
                                    <Field type="text" name="matricule_number" placeholder={"Votre identifiant commercial"} id="matricule_number" className="w-field__control"/>
                                    {this.props.errors.matricule_number && this.props.touched.matricule_number && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.matricule_number}</div>}
                                </div>
                            </div>

                            <Button type="submit" content="Connexion" />

                        </form>

                    </div>

                </div>

            </div>
        );
    }
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('E-mail invalide').required('Champ obligatoire'),
    matricule_number: Yup.string().required('Champ obligatoire'),
});


export default withFormik({

    mapPropsToValues: (props) => ({
        email: User.email ? User.email : '',
        matricule_number: '',
    }),

    validationSchema: validationSchema,

    handleSubmit: (values, { setSubmitting, setStatus, setFieldError,  props }) => {

        User.login(values.email, values.matricule_number).then(_ => {
            toast.dismiss('login_error');
            props.history.push('/dashboard');
        }).catch(cb => {
            toast.error("Erreur de connexion", {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: 'login_error'
            });
        });

    },

})(Login);
