import React, {Component} from 'react';

import './SplashScreen.css';

import {Observer} from "app/Core";

export default class SplashScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: 'w-splashscreen loading',
            codeLoaded: false,
        };

    }

    componentDidMount() {

        Observer.subscribe('coreDidInit', () => {
            this.setState({
                loading: 'w-splashscreen',
                codeLoaded: true
            });
            setTimeout(_ => {
                this.setState({
                    loading: 'w-splashscreen loaded'
                });
            }, 800);
        });

    }

    render() {
        if (this.state.codeLoaded === false) {
            return (
                <div className={this.state.loading}></div>
            );
        } else {
            return (
                <div className={this.state.loading}>{this.props.children}</div>
            );
        }
    }

}