import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";

export default class ShowPage extends Component {


    render() {
        return (
            <div className={'ShowPage question question--bg'}>
                <div className={'ShowPage__in question__in'}>
                    <div className={'inline checkbox-row'}>
                        <label className={'checkbox-w'}>

                            <input
                                disabled={User.isSupervisor}
                                id={this.props.name}
                                type="checkbox"
                                checked={parseInt(this.props.value) === parseInt(this.props.question.conditional_page_id)}
                                name={this.props.name}
                                value={parseInt(this.props.question.conditional_page_id)}
                                onChange={(e) => {this.props.onChange(e.target.value, e.target.checked)}}
                                onBlur={this.props.onBlur}
                            />
                            <i className="icon icon-check mark" />
                        </label>
                        <label className={'checkbox-label'} htmlFor={`${this.props.name}`}><span>{this.props.question.name}</span></label>
                    </div>
                </div>
            </div>
        );
    }
}

ShowPage.propTypes = {
    question: PropTypes.object.isRequired,
    name:PropTypes.string,
    value:PropTypes.any,

};

ShowPage.defaultProps = {

};