import React, {Component} from 'react';
import './QuestionPage.css';

import PropTypes from "prop-types";

import {avancements} from "utils/fields";

import {User} from "app/Core";

import AnimateHeight from 'react-animate-height';

import Univers from './Fields/Univers';
import {InterviewService} from "../../../services/InterviewService";
import Button from "../../../components/Button/Button";



export default class ActionsDates extends Component {

    constructor(props) {

        super(props);

        this.state = {
            heights: {},
            dateHeights: {},
        };
    }
    buildDate(index = 0, action) {

        let dates = [
            {
                date:'',
                note:''
            }
        ];

        if(this.props.dates[index] && Array.isArray(this.props.dates[index])){
            dates = this.props.dates[index];
        }

        return (

                    dates.map((date, i) => {

                        return (
                            <div key={`date_${index}_${i}`} className={'Action accordeon Action--date'}>
                                <div className="accordeon__head">
                                    <div className="h3">Priorité - {this.props.value[index] && this.props.value[index]['priority'] ? this.props.value[index]['priority'] : index+1} </div>
                                    {/*<div className="h3">Date*/}
                                    {/*    - {dates && dates[index] && dates[index][i] && dates[index][i]['date'] ? this.props.dates[index][i]['date'] : i + 1}</div>*/}
                                    <div>
                                        <button className={'btn-icon btn-accordeon'} onClick={(e) => {
                                            let heights = {...this.state.dateHeights};
                                            heights[`${index}_${i}`] = heights[`${index}_${i}`] === 0 ? 'auto' : 0;
                                            this.setState({dateHeights: heights});
                                        }}>
                                            {this.state.dateHeights[`${index}_${i}`] === 0 ? 'Déplier' : 'Replier'} le contenu
                                            <i className={'icon icon-miniarrowright ' + (this.state.dateHeights[`${index}_${i}`] === 0 ? 'down' : 'up')}/>
                                        </button>
                                    </div>
                                </div>
                                <AnimateHeight duration={ 500 } height={ this.state.dateHeights[`${index}_${i}`] !== 0 ? 'auto' : 0 }>
                                    <div className="accordeon__body">
                                        {
                                            this.props.value[index] && typeof this.props.value[index]['other'] !== 'undefined' ? <p>{this.props.value[index]['other']}<br /><br /></p> : null
                                        }
                                        {
                                            i > 0 && !User.isSupervisor
                                                ?
                                                <button className={'Action__delete btn-icon txt--danger'} onClick={(e) => this.props.onDeleteDate(index, i)}>
                                                    <i className={'icon icon-delete'} />
                                                    Supprimer
                                                </button>
                                                : null
                                        }

                                        <div className={'Action__row Action__wdate'}>
                                            <div className={'Action__row Action__date'}>
                                                <label>Date</label>
                                                <input disabled={User.isSupervisor} type={"text"} value={this.props.dates[index] && this.props.dates[index][i] && this.props.dates[index][i]['date'] ? this.props.dates[index][i]['date'] : ''} name={`action_dates[${index}][${i}][date]`} onChange={this.props.onChange} />
                                            </div>
                                            <div className={'Action__row Action__date'}>
                                                <label>Avancement</label>
                                                <select disabled={User.isSupervisor} value={this.props.dates[index] && this.props.dates[index][i] && this.props.dates[index][i]['avancement'] ? this.props.dates[index][i]['avancement'] : ''} name={`action_dates[${index}][${i}][avancement]`} onChange={this.props.onChange}>
                                                    <option value=""></option>
                                                    {
                                                        Object.keys(avancements).map((key,i) => {
                                                            let item = avancements[key];
                                                            return <option key={i} value={item}>{item}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'Action__row'}>
                                            <label>Commentaires et prochaines étapes</label>
                                            <textarea disabled={User.isSupervisor} name={`action_dates[${index}][${i}][note]`} value={this.props.dates[index] && this.props.dates[index][i] && this.props.dates[index][i]['note'] ? this.props.dates[index][i]['note'] : ''} onChange={this.props.onChange} />
                                        </div>
                                    </div>
                                </AnimateHeight>


                                <div className={'add-btn-w action-add-date'}>
                                    <button className={'btn-icon'} onClick={(e) => this.props.onAddDate(index)}>
                                        <i className={'icon icon-add txt--secondary'} />
                                        Ajouter une nouvelle date
                                    </button>
                                </div>

                            </div>
                        )
                    })
        );

    }



    showPdf(e) {
        e.preventDefault();
        this.props.onExport();
        setTimeout(() => {
            window.open(InterviewService.getPdfPrioritiesLink(this.props.interview.token), "_blank");
        }, 1000);
    }


    render() {

        return (
            <React.Fragment>
                <h2 className={'h2 Title'}>
                    {this.props.page.actions_label ? this.props.page.actions_label : 'Suivi des priorités'}

                </h2>

                <div style={{"display":"flex", "alignItems": "center", "justifyContent": "flex-end"}}>
                    <Button onClick={(e) => this.showPdf(e)} className={'btn--secondary'}>Exporter au format PDF</Button>
                    &#160;&#160;&#160;&#160;&#160;&#160;
                    <Button onClick={(e) => {
                        e.preventDefault();
                        this.props.onValidate();
                    }} className={'btn--primary'}>Valider et notifier</Button>
                </div>

                <br /><br />


                <div>
                    {/*{*/}
                    {/*    this.props.value.length*/}
                    {/*    ?*/}
                    {/*    this.props.value.map((value, i) => {*/}
                    {/*        return [this.buildAction(i), this.buildDate(i)]*/}
                    {/*    })*/}
                    {/*    :  [this.buildAction(), this.buildDate()]*/}
                    {/*}*/}
                    {
                        this.props.value.length
                            ?
                                this.props.value.map((value, i) => {
                                    return [this.buildDate(i, value)]
                                })
                            :  [this.buildDate()]
                    }

                </div>

                {/*
                <h2 className={'h2 Title'}>{'Suivi des actions'}</h2>
                <div>
                {
                    this.props.dates.length
                    ?
                    this.props.dates.map((value, i) => {
                        return this.buildDate(i)
                    })
                    :  this.buildDate()
                }
                </div>
                {
                    !User.isSupervisor
                    ?
                    <div className={'add-btn-w'}>
                        <button className={'btn-icon'} onClick={(e) => this.props.onAddDate()}>
                            <i className={'icon icon-add txt--secondary'} />
                            Ajouter une nouvelle date
                        </button>
                    </div>
                    : null
                }
                */}

                {/*<div onClick={(e) => this.showPdf(e)}  className={'File question question--bg'}>*/}
                {/*    <div className={'File__in question__in'}>*/}
                {/*                <span className={'File__link'}>*/}
                {/*                    <i className={'icon icon-pdf'} />*/}
                {/*                    Exporter au format PDF*/}
                {/*                </span>*/}
                {/*        <i className={'icon arrow icon-miniarrowright'} />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <br /><br /><br />

            </React.Fragment>
        );



    }
}

ActionsDates.propTypes = {
    page: PropTypes.object.isRequired,
    value: PropTypes.array,
    dates: PropTypes.array
};

ActionsDates.defaultProps = {
    page:null,
    value:[],
    dates:[]
};
