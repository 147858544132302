import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

import MaskInput from 'react-maskinput';


export default class Input extends Component {

    constructor(props) {
        super(props);

    }

    getOtherUnivers(key, text) {
        for(let i in this.props.interview.answers){
            let answer = this.props.interview.answers[i];
            if (answer.question.id === 270 && typeof JSON.parse(answer.answer)[key] !== 'undefined') {
                return JSON.parse(answer.answer)[key].name ? JSON.parse(answer.answer)[key].name : text;
                //return answer;
            }
        }
        return text;
    }

    render() {

        let label = this.props.question.field_name ? this.props.question.field_name.replace('autre 1', 'autre n°1').replace('autre 2', 'autre n°2') : '';

        // Désolé pour cette merde
        if (
            label === 'Objectif de croissance Univers autre n°1' ||
            label === 'Objectif de croissance Univers autre n°2'
        ) {
            label = label.replace('Univers autre n°1', this.getOtherUnivers(3, 'Univers autre n°1'));
            label = label.replace('Univers autre n°2', this.getOtherUnivers(4, 'Univers autre n°2'));
        }

        return (

            <div className={'Input question question--bg'}>

                <div className={'Input__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.question.name}</label>
                    </div>
                    <div className="Input__control question__control">
                        <label>{label}</label>
                        <div className={'field-unit'}>
                            {
                                this.props.mask
                                ?
                                    <MaskInput type={ this.props.type} disabled={User.isSupervisor} placeholder={this.props.maskString} maskString={this.props.maskString} mask={ this.props.mask} size={20} name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.onBlur} />
                                :
                                <input disabled={User.isSupervisor} type={this.props.type ? this.props.type : 'text'} name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.onBlur} />

                            }
                            {
                                this.props.question.field_unit
                                ?
                                    <span className={'unit'}>{this.props.question.field_unit}</span>
                                :null
                            }
                        </div>
                        {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                        {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                        {/*    : null}*/}
                    </div>
                </div>

            </div>
        );
    }

}

Input.propTypes = {
    question: PropTypes.object.isRequired,
    name:PropTypes.string,
    value:PropTypes.number,
    interview:PropTypes.object,
    answers:PropTypes.object,
};

Input.defaultProps = {

};
