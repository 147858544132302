import React, {Component} from 'react';
import './ButtonToggle.css';

import { GlobalContext } from "providers/GlobalProvider";
import { CHOOSE_SHOP, CREATE_SHOP } from "utils/constants";
import {User} from "../../app/Core";

export default class ButtonToggle extends Component {

    constructor(props){
        super(props);
        this.state = {
            toggle: false
        }
    }

    render() {

        if(User.isSupervisor){
            return <React.Fragment />
        }

        return (
            <div className={"btn-toggle " + (this.state.toggle ? "active" : "")}>
                {/*
                <div className="btn" onClick={() => this.context.setModalContent(CREATE_SHOP)}>
                    <span className="icon icon--24 txt--white icon-icon-addlocation"></span>
                </div>
                <div className="btn" onClick={() => this.context.setModalContent(CHOOSE_SHOP)}>
                    <span className="icon icon--24 txt--white icon-icon-addquiz"></span>
                </div>


                <div className="btn-handle" onClick={() => this.setState({toggle: !this.state.toggle})}></div>
                */}
                <div className="btn-handle" onClick={() => this.context.setModalContent(CREATE_SHOP)}></div>
            </div>
        );
    }

}

ButtonToggle.contextType = GlobalContext;