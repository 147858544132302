import {Network,Storage,Observer} from "app/Core";

export class ContactAction {

    saveRemote(contact) {

        return new Promise((resolve, reject) => {

            Network.post('/api/salespoint/contact/save/', contact)
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.contact);
                    } else {
                        reject();
                    }
                });

        });

    }

    /*addRemote(contact, pdv_id) {

        return new Promise((resolve, reject) => {

            let params = contact.datas;
            params.pdv_source_id = pdv_id;

            if(params.pdv_source_id > 0 && params.pdv_id === 0) {

                Network.post('/api/salespoints/contacts/add/', params)
                    .then(cb => {
                        if(cb.success) {
                            resolve(cb.datas.contact);
                        } else {
                            reject(cb);
                        }
                    });

            } else {
                reject({
                    success : false,
                    error: 'wrong_salepoint_id'
                });
            }

        });

    }

    updateRemote(contact, pdv_id) {

        return new Promise((resolve, reject) => {

            let params = contact.datas;
            params.pdv_source_id = pdv_id;

            if(pdv_source_id === params.pdv_id) {

                Network.post('/api/salespoints/contacts/update/', params)
                    .then(cb => {
                        if(cb.success) {
                            resolve(cb.datas.contact);
                        } else {
                            reject(cb);
                        }
                    });

            } else {
                reject({
                    success : false,
                    error: 'wrong_salepoint_id'
                });
            }

        });

    }*/

    removeRemote(contact, pdv_id) {

        return new Promise((resolve, reject) => {

            let params = contact.datas;
            params.pdv_source_id = pdv_id;

            if(params.pdv_source_id === params.pdv_id) {

                Network.post('/api/salespoints/contacts/remove/', params)
                    .then(cb => {
                        if(cb.success) {
                            resolve();
                        } else {
                            reject(cb);
                        }
                    });

            } else {
                reject({
                    success : false,
                    error: 'wrong_salepoint_id'
                });
            }

        });

    }

}