import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

export default class Textarea extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div className={'Textarea'}>
                <label>{this.props.label} </label>
                <textarea disabled={User.isSupervisor} name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.onBlur}></textarea>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}
            </div>
        );
    }

}

Textarea.propTypes = {


};

Textarea.defaultProps = {

};
