import {Network,Storage,Observer} from "app/Core";

export class InterviewAction {

    getAnswersRemote(interview_id, pdv_id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/interview/answers/', {
                'interview_id' : interview_id,
                'pdv_id' : pdv_id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.answers);
                    } else {
                        resolve({});
                    }
                });

        });

    }

    saveRemove(datas) {

        return new Promise((resolve, reject) => {

            Network.post('/api/interview/save/', datas)
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.interview);
                    } else {
                        reject(cb);
                    }
                });

        });

    }

    validRemote(datas) {

        return new Promise((resolve, reject) => {

            Network.post('/api/interview/valid/', datas)
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.interview);
                    } else {
                        reject(cb);
                    }
                });

        });

    }

    saveQuestion() {

    }

}