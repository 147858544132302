import {Network} from "app/Core";
import {User} from "../app/Core";

import networkConfig from 'config/network';


export class InterviewService {


    static savePage(id, data) {

        return Network.rawPost('/api/interview/'+ id +'/save', data);

    }

    static setContact(id, contactId) {
        return Network.rawPost('/api/interview/'+ id +'/contact', {contact:contactId});
    }

    static getPdfLink(token) {
        return networkConfig.url + '/api/interview/'+ token +'/pdf';
    }

    static getPdfPrioritiesLink(token) {
        return networkConfig.url + '/api/interview/'+ token +'/pdf-priorities';
    }



}
