import React, {Component} from 'react';
import './ProgressIndicator.css';

import PropTypes from "prop-types";

import { GlobalContext } from "providers/GlobalProvider";
import {fieldsTypes} from "../../../utils/fields";





class ProgressIndicator extends Component {


    getNbQuestions () {

        let count = 0;

        if(this.context.getActiveQuestionPages()) {

            this.context.getActiveQuestionPages().forEach(type => {
                if(type.questions){
                    type.questions.forEach((question) => {
                        if(question.type !== fieldsTypes.TYPE_TITLE && question.type !== fieldsTypes.TYPE_SCORE_MORE && question.type !== fieldsTypes.TYPE_FILE && question.type !== fieldsTypes.TYPE_VALIDATION){
                            count++;
                        }
                    });
                }
            });
        }

        return count;

    }

    getPercentage() {

        let nbQuestions = this.getNbQuestions();

        let percentage = 0;

        if(this.props.interview && this.props.interview.answers && nbQuestions) {

            let nbAnswer = 0;

            if(this.context.getActiveQuestionPages()) {

                this.context.getActiveQuestionPages().forEach(type => {
                    if(type.questions){
                        type.questions.forEach((question) => {
                            if(question.type !== fieldsTypes.TYPE_TITLE && question.type !== fieldsTypes.TYPE_SCORE_MORE && question.type !== fieldsTypes.TYPE_FILE){
                                this.props.interview.answers.forEach(answer => {
                                    if (answer && answer.question.id === question.id) {
                                        if (answer.question.type === 'turnover') {
                                            const values = JSON.parse(answer.answer);
                                            const found = values.findIndex(v => v.year == 2021);
                                            nbAnswer += found >= 0 ? 1 : 0;
                                        } else {
                                            nbAnswer ++;
                                        }
                                    }
                                })
                            }
                        });
                    }
                });
            }

            percentage = Math.round((nbAnswer / nbQuestions) * 100);

            if(percentage > 100) {
                return 100;
            }
        }

        return percentage;

    }


    render() {

        return (
            <div className={'ProgressIndicator' }>

                <div className={'progress'}> <div style={{width:`${this.getPercentage()}%`}} className={'progress__in'}></div></div>

                <div className="ProgressIndicator__txt h2 h2--secondary">{this.getPercentage()} %</div>

            </div>
        );
    }
}



ProgressIndicator.propTypes = {
    interview: PropTypes.object
};

ProgressIndicator.defaultProps = {
    interview: {},
};


ProgressIndicator.contextType = GlobalContext;

export default ProgressIndicator;
