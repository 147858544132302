import React from 'react';
import ReactDOM from 'react-dom';

// IE11
import 'babel-polyfill';

import App from 'app/App';

import './assets/scss/global.css';
/*import './assets/fonts/icomoon/style.css';*/

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App/>, document.getElementById('main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
