import React from 'react';
import './InterviewNavigation.css';


import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent} from "app/Core";


import {withRouter} from "react-router-dom";
import {routes} from "../../config/routes";
import PropTypes from "prop-types";



class InterviewNavigation extends PrivateComponent {

    constructor(props) {

        super(props);

        this.navigation = React.createRef();
        this.firstItem =  React.createRef();

    }

    onItemSelected(page, ref){

        if(this.props.isLoading){
            return;
        }

        this.props.history.push(routes.questionPageSheet.route(this.props.salePoint.id, page.id));


        this.scrollToItem(ref.current);

        if(this.props.onSelectItem){
            this.props.onSelectItem(page);
        }

    }

    scrollToItem(element) {
        let nodeStyle = window.getComputedStyle(this.firstItem.current);
        this.navigation.current.scrollLeft = element.offsetLeft - parseFloat(nodeStyle.getPropertyValue('margin-left'));

    }


    componentDidUpdate(prevProps) {

        if(this.props.currentPage && this.props.currentPage !== prevProps.currentPage) {
            const current = document.getElementById('item-nav-' + this.props.currentPage.id);

            this.scrollToItem(current);
        }
    }


    getIsCompleted(page) {


        if (this.props.interview) {

            let filteredPage = this.props.interview.pages.find((item) => {
                return parseInt(item.question_page_id, 10) === parseInt(page.id, 10);
            });

            if(filteredPage) {
                return filteredPage.is_completed;
            }
        }

        return false;
    }


    navigationItems(){

        let items = [];

        const activePages = this.context.getActiveQuestionPages();

        if(activePages && activePages.length) {

            activePages.forEach((page, i) => {

                const ref = React.createRef();

                const isCompleted = activePages.length - 1 === i && this.props.interview
                                  ? this.props.interview.status
                                  : ( 0 === i && this.props.interview && this.props.interview.pdv_contact ? true : this.getIsCompleted(page));

                const isActive = this.props.currentPage && this.props.currentPage.id === page.id;

                items.push(
                    <div id={'item-nav-'+page.id} ref={0 === i ? this.firstItem : ref} key={i} onClick={(e) => this.onItemSelected(page, 0 === i ? this.firstItem : ref)} className={"InterviewNavigation__item " + (isActive ? 'active': '') + (isCompleted ? ' completed': '')}>
                        <div className={'icon-w'}><i className={`icon icon-${(isCompleted & !isActive ? 'check' : page.icon)}`} /></div>
                        <div className={'title'}>{page.name}</div>
                        {
                            page.id !== 53 ? <div className={'sub'}>{page.type}</div> : null
                        }

                    </div>
                );

            });
        }

        return items;

    }

    render() {


        return (
            <div ref={this.navigation} className="InterviewNavigation">

                {this.navigationItems()}

            </div>
        );
    }
}



InterviewNavigation.propTypes = {
    salePoint: PropTypes.object,
    onSelectItem: PropTypes.func,
    currentPage: PropTypes.object,
};

InterviewNavigation.defaultProps = {
    salePoint: null,
    onSelectItem:null,
    currentPage:null
};



export default withRouter(InterviewNavigation);

InterviewNavigation.contextType = GlobalContext;
