import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './LoaderSpiral.css';

class LoaderSpiral extends Component {

    render() {
        return (
            <div className={"w-loader-spiral " + (this.props.className ? this.props.className  : '')}>
                <div id="circularG_1" className="circularG"></div>
                <div id="circularG_2" className="circularG"></div>
                <div id="circularG_3" className="circularG"></div>
                <div id="circularG_4" className="circularG"></div>
                <div id="circularG_5" className="circularG"></div>
                <div id="circularG_6" className="circularG"></div>
                <div id="circularG_7" className="circularG"></div>
                <div id="circularG_8" className="circularG"></div>
            </div>
        );
    }

}

LoaderSpiral.propTypes = {
};

LoaderSpiral.defaultProps = {
};

export default LoaderSpiral;