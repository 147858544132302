import {Network,Storage,Observer} from "app/Core";
import {InterviewAction} from "./InterviewAction";
import {Interviews, SalePoints} from "../Core";

export class Interview extends InterviewAction {

    datas = {
        id : 0,
        status : 0,
        pdv_id : 0,
        pdv_contact_id : 0,
        terminated_at : null,
        created_at : new Date(),
        last_edited_step: 1,
        good_points: [],
        bad_points: [],
        fdv_user_id: null,
        fdv_user_name: null,
        answers : {
            actual: [],
            previous: []
        }
    };
    salepoint = null;
    salepointContact = null;

    constructor(datas) {
        super();
        if(typeof datas != 'undefined') {
            this.datas = datas;
        }
    }

    get id() {
        return this.datas['id'];
    }

    get step() {
        return typeof this.datas['last_edited_step'] !== 'undefined'? this.datas['last_edited_step'] : 1;
    }

    set step(step) {
        this.datas['last_edited_step'] = step;
    }

    get goodPoints() {
        if(typeof this.datas['good_points'] !== 'undefined') {
            let goodPoints = [];
            for(var i in this.datas['good_points']) {
                goodPoints.push(this.datas['good_points'][i]);
            }
            return goodPoints;
        }
        return [];
    }

    get badPoints() {
        if(typeof this.datas['bad_points'] !== 'undefined') {
            let badPoints = [];
            for(var i in this.datas['bad_points']) {
                badPoints.push(this.datas['bad_points'][i]);
            }
            return badPoints;
        }
        return [];
    }

    get status() {
        return this.datas['status'];
    }

    get createdAt() {
        return this.datas['created_at'];
    }

    get terminatedAt() {
        return this.datas['terminated_at'];
    }

    get userId() {
        return this.datas['fdv_user_id'];
    }

    set userId(id) {
        this.datas['fdv_user_id'] = id;
    }

    get userName() {
        return this.datas['fdv_user_name'];
    }

    getContact() {

        return new Promise((resolve, reject) => {
            if(this.salepointContact === null) {
                this.getPdv().then(salepoint => {
                    if(salepoint !== null) {
                        salepoint.findContact(this.datas['pdv_contact_id']).then(contact => {
                            this.salepointContact = contact;
                            resolve(contact);
                        });
                    } else {
                        this.salepointContact = null;
                        resolve(null);
                    }
                });
            } else {
                resolve(this.salepointContact);
            }
        });
        //return this.datas['pdv_contact_id'];
    }

    get contact() {
        return this.salepointContact;
    }

    set contact(pdv_contact_id) {

        if (!isNaN(pdv_contact_id)) {

            this.salepointContact = null;
            this.datas['pdv_contact_id'] = pdv_contact_id;

        } else {

            this.salepointContact = pdv_contact_id;
            this.datas['pdv_contact_id'] = pdv_contact_id.id;

        }

    }

    get pdv_id() {
        return this.datas['pdv_id'];
    }

    get pdv() {
        return this.salepoint;
    }

    set pdv(salepoint) {
        this.salepoint = salepoint;
        this.datas['pdv_id'] = salepoint.id;
    }

    getPdv() {
        return new Promise((resolve, reject) => {
            if(this.salepoint === null) {
                SalePoints.find(this.datas['pdv_id']).then(salepoint => {
                    this.salepoint = salepoint;
                    resolve(this.salepoint);
                }).catch(cb => {
                    resolve(null);
                });
            } else {
                resolve(this.salepoint);
            }
        });
    }

    getAnswers() {
        return new Promise((resolve, reject) => {
            if(!this.answers.actual.length) {
                this.getPdv().then(_ => {
                    this.getAnswersRemote(this.id, this.salepoint.id).then(answers => {
                        if(typeof answers.actual === 'undefined') answers.actual = [];
                        if(typeof answers.previous === 'undefined') answers.previous = [];
                        this.answers = answers;
                        resolve();
                    }).catch(cb => {
                        resolve();
                    });
                });
            } else {
                resolve();
            }
        });
    }

    setResponse(question, value, radioAnswer) {

        if(typeof this.answers.actual !== 'undefined') {
            let index = this.answers.actual.findIndex((answer, index) => {
                return (answer.question.id === question.id);
            });
            if(index >= 0) {
                return this.answers.actual[index].answer = value;
            } else {
                let answer = this.answers.actual.push({
                    answer: value,
                    id: 0,
                    question: question
                });
                return value;
            }
        }

    }

    getResponse(question) {

        if(typeof this.answers.actual !== 'undefined') {
            let answer = this.answers.actual.find((answer, index) => {
                if(typeof question.crm_code !== 'undefined') {
                    return (answer.question.crm_code === question.crm_code || answer.question.id === question.id);
                } else {
                    return (answer.question.id === question.id);
                }
            });
            if(typeof answer !== 'undefined') {
                return answer.answer;
            }
        }

        return null;

    }

    getPreviousResponse(question) {

        if(typeof this.answers.previous !== 'undefined') {
            let answer = this.answers.previous.find((answer, index) => {
                return ( (answer.question.crm_code === question.crm_code && question.crm_code) || (answer.question.crm_code === question.crm_code_last_year && question.crm_code_last_year) || answer.question.id === question.id);
            });
            if(typeof answer !== 'undefined') {
                return answer.answer;
            }
        }

        return null;

    }

    save() {
        return new Promise((resolve, reject) => {
            this.saveRemove(this.datas).then(datas => {
                this.salepoint = null;
                this.salepointContact = null;
                this.datas = datas;
                this.getContact().then(_ => {
                    this.getAnswers().then(_ => {
                        // Is the interview already in the list ?
                        Interviews.getList().then(list => {
                            let indexFound = list.findIndex(interview => {
                                return interview.id === this.id;
                            });
                            if(indexFound < 0) {
                                Interviews.list.push(this);
                            }
                            resolve();
                        });
                    });
                });
            }).catch(cb => {
                reject(cb);
            });
        });
    }



    valid() {
        return new Promise((resolve, reject) => {
            this.validRemote(this.datas).then(datas => {
                this.datas = datas;
                this.getAnswers().then(_ => {
                    resolve();
                });
            }).catch(cb => {
                reject();
            });
        });
    }

    get answers() {
        if(typeof this.datas['answers'] == 'undefined') {
            this.datas['answers'] = {
                'actual' : [],
                'previous' : []
            };
        }
        if(typeof this.datas['answers'].actual == 'undefined') this.datas['answers'].actual = [];
        if(typeof this.datas['answers'].previous == 'undefined') this.datas['answers'].previous = [];
        this.datas['answers'].actual.forEach((datas, index) => {
            if(datas.question.type === 'formation' && typeof datas.answer === "string") {
                this.datas['answers'].actual[index].answer = JSON.parse(datas.answer);
            }
        });
        this.datas['answers'].previous.forEach((datas, index) => {
            if(datas.question.type === 'formation' && typeof datas.answer === "string") {
                this.datas['answers'].previous[index].answer = JSON.parse(datas.answer);
            }
        });
        return this.datas['answers'];
    }

    set answers(answers) {
        this.datas['answers'] = answers;
    }

}