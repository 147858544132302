
export const fieldsTypes = {
    TYPE_TEXT: "text",
    TYPE_TEXTAREA: "textarea",
    TYPE_INT: "textint",
    TYPE_SCORE: "score",
    TYPE_SCORE_MORE: "score_more",
    TYPE_BOOLEAN: "boolean",
    TYPE_RADIO: "radio",
    TYPE_RATING: "degree",
    TYPE_RATING_WITH_ANSWERS: "rating_answers",
    TYPE_STARS: "stars",
    TYPE_MONTHS: "month",
    TYPE_TITLE: "title",
    TYPE_PRODUCT: "product",
    TYPE_TRAINING: "training",
    TYPE_TRAINING_AGENCY: "training_agency",
    TYPE_TURNOVER: "turnover",
    TYPE_SHOW_PAGE: "show_page",
    TYPE_FILE: "file",
    TYPE_VALIDATION: "validation",
    TYPE_SELECT: "select",
    TYPE_DATE: "date",
    TYPE_UNIVERS: "univers",
    TYPE_STOCK: "stock",
    TYPE_CLIENTS: "clients",
    TYPE_TURNOVER_UNIVERS: "turnover_univers",
    TYPE_TRAINING_CLIENT: "training_client",
    TYPE_TRAINING_TEAM: "training_team"
};


export const avancements = {
    '0-10': '0 à 10%',
    '11-20': '11 à 20%',
    '21-30': '21 à 30%',
    '31-40': '31 à 40%',
    '41-50': '41 à 50%',
    '51-60': '51 à 60%',
    '61-70': '61 à 70%',
    '71-80': '71 à 80%',
    '81-90': '81 à 90%',
    '91-100': '91 à 100%',
};

export const priorities = {
    /*TECHNICAL_TEAM:'Augmenter l’efficacité de mes équipes techniques',
    COMMERCIAL_TEAM:'Augmenter l’efficacité de mes équipes commerciales',
    MARGIN: 'Augmenter ma marge',
    CRAFT: 'Développer le nombre d’artisans mouvementés',
    REGION:'Se renforcer chez les régionaux et/ou nationaux',
    STOCK:'Optimiser mes stocks',
    FAMILLY: 'Se renforcer sur la famille…',
    MOVE: 'Réaménager/déménager le point de vente',
    STAR: 'Gagner ma première étoile ou une étoile supplémentaire en tertiaire',
    OTHER: 'Autre…'*/
    M_UNIVERS: 'Développer l\'univers M',
    T_UNIVERS: 'Développer l\'univers T',
    H_UNIVERS: 'Développer l\'univers H',
    STOCK: 'Optimiser mes stocks',
    COMPANIES: 'Développer les entreprises (Précisez)',
    PDV: 'Animer mon point de vente',
    // EFFICIENCE: 'Augmenter mon efficacité et celle de mes équipes',
    // TRADE: 'Développer mon fonds de commerce',
    // COMPANIES: 'Développer les entreprises régionales et nationales',
    // HAGER_UNIVERS: 'Se renforcer sur les 7 univers Hager',
    OTHER: 'Autre (Précisez)'
};
