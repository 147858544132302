import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";


export default class Months extends Component {

    constructor(props) {
        super(props);

        this.months = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ]

    }

    render() {


        return (

            <div className={'Months question question--bg'}>
                <div className={'Months__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Months__control question__control">
                        <select disabled={User.isSupervisor} name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.handleBlur}>
                            <option value=""></option>
                        {
                            this.months.map((item, i) => {
                                return <option key={i} value={item}>{item}</option>
                            })
                        }
                        </select>
                    </div>
                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}
            </div>
        );
    }

}

Months.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.string,

};

Months.defaultProps = {

};
