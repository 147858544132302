import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";

export default class Radio extends Component {

    constructor(props) {
        super(props);

        this.labels = {
            1:'Très faible',
            2:'Faible',
            3:'Moyen',
            4:'Fort',
            5: 'Très fort'
        }

    }

    render() {

        return (

            <div className={'Radio question question--bg'}>
                <div className={'Radio__in question__in'}>
                    <div className="Radio__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Radio__control question__control">
                    {
                        this.props.answers.map((item, i) => {
                            return <div className={'inline checkbox-row'} key={i}>
                                <label className={'checkbox-w'}>
                                    <input disabled={User.isSupervisor} id={`${this.props.name}_${item.id}`} checked={this.props.value === item.value} type='radio' value={item.value} name={this.props.name} onChange={() => this.props.onChange(item.value)} onBlur={this.props.handleBlur} />
                                    <i className="icon icon-check mark mark--radio"  />
                                </label>
                                <label htmlFor={`${this.props.name}_${item.id}`}><span>{item.name}</span></label>
                            </div>
                        })
                    }
                    </div>
                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}
            </div>
        );
    }

}

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.string,

};

Radio.defaultProps = {

};
