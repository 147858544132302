import {Network} from "app/Core";



export class SalePointService {


    static getUserList(page) {

        return Network.get('/api/usersalepoints' );

    }

    static getTypes() {
        return Network.get('/api/usersalepoints/types');
    }


    static getContactFunctions() {

        return Network.get('/api/contactfunctions' );

    }


    static getOne(id) {
        return Network.get('/api/salepoints/'+ id );
    }

    static delete(id) {
        return Network.get('/api/salepoints/'+ id +'/delete' );
    }

    static unlink(id) {
        return Network.get('/api/salepoints/'+ id +'/unlink' );
    }

    static getInterview(id) {
        return Network.get('/api/salepoints/'+ id +'/interview');
    }


    static getPreviousInterview(id) {
        return Network.get('/api/salepoints/'+ id +'/interview/previous');
    }

    static checkCrmId(id) {
        return Network.get('/api/salepoints/check/'+ id );
    }

    static search(query) {
        return Network.rawPost('/api/salepoints/search', {keyword:query} );
    }

    static autocomplete(query) {
        return Network.rawPost('/api/salepoints/autocomplete', {keyword:query} );
    }

    static save(data) {
        return Network.rawPost('/api/salepoints/save', data );
    }


    static addContact(id, data) {
        return Network.rawPost('/api/salepoints/'+ id +'/addcontact', data );
    }

    static edit(id, data) {
        return Network.rawPost('/api/salepoints/'+ id +'/update', data );
    }

    static addToUser(id) {
        return Network.get('/api/salepoints/'+ id +'/add' );
    }
}
