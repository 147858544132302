import React, {Component} from 'react';
import './Header.css';

import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Logo from 'assets/images/logo-white.svg';

import {User} from "app/Core";
import {routes} from "config/routes";

import { GlobalContext } from "providers/GlobalProvider";

import {Observer} from "app/Core";

class Header extends Component {

    logout(){
        User.logout();



        this.context.setUserSalePoints([]);
        this.context.setUserSaleUnits([]);
    }

    redirect(){
        if(this.props.history.location.pathname === routes.dashboard.path){
            return;
        }
        this.props.history.push(routes.dashboard.path);
    }

    render() {
        return (
            <div className={"Header " + (this.props.className)}>
                <div className="container">

                    <div className="w-top">

                        {
                            this.props.menuToggle
                            ?
                            <div className="w-menu link-icon txt--white" onClick={() => { if(this.props.menuToggle){this.props.menuToggle(); }}}>Menu</div>
                            :null
                        }
                        {
                            this.props.topTitle
                                ?
                                <span className="top-title">{this.props.topTitle}</span>
                                : <img src={Logo} alt="Logo Hager" className="logo"
                                       onClick={() => {if(this.props.saveInterview){this.props.saveInterview(); } this.redirect()}}/>
                        }
                        {
                            this.props.showSave
                            ?
                                <div className="w-leave link-icon txt--white" onClick={() => { if(this.props.saveInterview){ Observer.publish('leave'); this.props.saveInterview(); }}}>Enregistrer et quitter</div>
                            :
                                <div className="w-logout link-icon txt--white" onClick={() => {if(this.props.saveInterview){this.props.saveInterview(); } this.logout()}}>Déconnexion</div>
                        }

                    </div>

                    <div className="w-content">

                        <div className={'title-w'}>
                            <div className="headline">{this.props.subtitle}</div>
                            <h1 className="h1--secondary">{this.props.title}</h1>
                        </div>


                        {this.props.children}

                    </div>

                </div>
            </div>
        );
    }

}

Header.propTypes = {
    className: PropTypes.string,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    saveInterview: PropTypes.func,
    interview: PropTypes.object
};

Header.defaultProps = {
    className: '',
    subtitle: '',
    title: '',
    description: '',
    interview: {}
};


export default withRouter(Header);

Header.contextType = GlobalContext;