import React, {Component} from "react";
import PropTypes from "prop-types";

import {Field, withFormik} from "formik";
import * as Yup from "yup";
import Button from 'components/Button/Button';
import {SalePointService} from "../../services/SalePointService";

class AddContact extends Component {

    constructor(props) {
        super(props);

        this.state = {functions:[]};
    }

    componentDidMount() {

        const { childRef } = this.props;
        if(childRef){
            childRef(this);
        }

        SalePointService.getContactFunctions().then(response => {
            if(response.data && Array.isArray(response.data)) {

                this.setState({functions: response.data});
            }
        });
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        if(childRef) {
            childRef(undefined);
        }
    }


    submit(){
        this.props.setValues({
            ...this.props.values,
            submit: true,
        });

        //this.props.handleSubmit();
        this.props.handleContact(this.props.values, this.props.index, true, this.props.isValid);
    }

    render(){
        return(
            <React.Fragment>

                <form noValidate onSubmit={this.props.handleSubmit}>

                    <Field type="hidden" name="id" />

                    <div className="w-field-group">
                        <div className={"w-field w-field--half w-field--required " + (this.props.errors.first_name && this.props.touched.first_name ? "w-field--error" : "")}>
                            <label htmlFor={"first_name"} className={"w-field__label"}>Prénom</label>
                            <Field type="text" name={"first_name"} placeholder={"Prénom"} id={"first_name"} className="w-field__control"/>
                            {this.props.errors.first_name && this.props.touched.first_name && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.first_name}</div>}
                        </div>
                        <div className={"w-field w-field--half w-field--required " + (this.props.errors.last_name && this.props.touched.last_name ? "w-field--error" : "")}>
                            <label htmlFor={"last_name"} className={"w-field__label"}>Nom</label>
                            <Field type="text" name={"last_name"} placeholder={"Nom"} id={"last_name"} className="w-field__control"/>
                            {this.props.errors.last_name && this.props.touched.last_name && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.last_name}</div>}
                        </div>
                    </div>

                    <div className="w-field-group">
                        <div className={"w-field w-field--half w-field--required " + (this.props.errors.email && this.props.touched.email ? "w-field--error" : "")}>
                            <label htmlFor={"email"} className={"w-field__label"}>Email</label>
                            <Field type="text" name={"email"} placeholder={"Email"} id={"email"} className="w-field__control"/>
                            {this.props.errors.email && this.props.touched.email && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.email}</div>}
                        </div>
                        <div className={"w-field w-field--half w-field--required " + (this.props.errors.function && this.props.touched.function ? "w-field--error" : "")}>
                            <label htmlFor={"function"} className={"w-field__label"}>Fonction</label>
                            <Field component="select" name={"function"} placeholder={"Fonction"} id={"function"} className="w-field__control">
                                <option value="">Choisir</option>
                                {this.state.functions.map((item, i) => {
                                    return <option key={i} value={item.name}>{item.name}</option>;
                                })}
                            </Field>
                            {this.props.errors.function && this.props.touched.function && <div className="w-field__message--error d-flex align-items-center">{this.props.errors.function}</div>}
                        </div>
                    </div>

                    {!this.props.once ? <div className="w-delete-contact"><div className="delete-contact link-icon" onClick={() => this.props.removeContact(this.props.index)}>Supprimer</div></div> : null}

                    {!this.props.once && (this.props.count - 1 === this.props.index) ?
                        <span className="add-contact link-icon" onClick={this.props.handleSubmit}>Ajouter un contact</span>
                    : null}

                </form>

                {(this.props.once  || (this.props.count - 1 === this.props.index) ) && !this.props.noButton ?
                    <div className="w-btn">
                        <Button className={"btn--secondary"} content={this.props.interview ? "Annuler" : "Précédent"} onClick={() => this.props.handleStep(1) } />
                        <Button content="Terminer" onClick={() => this.submit()} />
                    </div>
                : null}

            </React.Fragment>
        );
    }
}

AddContact.propTypes = {
    noButton: PropTypes.bool,
    once: PropTypes.bool,
    count:  PropTypes.number,
    index:  PropTypes.number,
    handleContact: PropTypes.func,
    interview: PropTypes.bool
};

AddContact.defaultProps = {
    interview: false,
    once: false,
    count: 0,
    index: 0,
    noButton:false
};

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Champ obligatoire"),
    last_name: Yup.string().required("Champ obligatoire"),
    email: Yup.string().email('E-mail invalide').required('Champ obligatoire'),
    function: Yup.string(),
});

export default withFormik({

    enableReinitialize: true,

    mapPropsToValues: (props) => ({
        id: props.contact && props.contact.id ? props.contact.id : '',
        first_name: props.contact && props.contact.first_name ? props.contact.first_name : '',
        last_name: props.contact && props.contact.last_name ? props.contact.last_name : '',
        email: props.contact && props.contact.email ? props.contact.email : '',
        function: props.contact && props.contact.function ? props.contact.function : ''
    }),

    validationSchema: validationSchema,

    handleSubmit: (values, { setSubmitting, setStatus, setFieldValue, setFieldError,  props }) => {

        if(values.submit){
            delete values['submit'];
            props.handleContact(values, props.index, true);
        }
        else{
            props.handleContact(values, props.index);
        }

    },

})(AddContact);
