import {Network,Storage,Observer} from "app/Core";
import {SalePointsAction} from "./SalePointsAction";
import {SalePoint} from "./SalePoint";
import {SalePoints, User} from "../Core";
import {Interview} from "../Interview/Interview";

export class SalePointsClass extends SalePointsAction{

    list = null;
    enums = null;
    lastSearch = null;
    lastSearchList = null;



    getList() {

        return new Promise((resolve, reject) => {

            if(this.list === null) {

                this.getRemoteList().then(response => {

                    this.list = [];
                    if (response.data.length) {
                        this.list = response.data;

                        resolve(this.list);
                    }
                });
            }
            else {
                resolve(this.list);
            }

        });

    }

    getEnums() {

        return new Promise((resolve, reject) => {

            this.getRemoteEnums().then(enums => {
                this.enums = enums;
                resolve();
            });

        });
    }

    get listTypePdv() {
        return (this.enums !== null && typeof this.enums['type_pdv'] !== 'undefined') ? this.enums['type_pdv'] : [];
    }

    get listAgCom() {
        return (this.enums !== null && typeof this.enums['ag_com'] !== 'undefined') ? this.enums['ag_com'] : [];
    }

    get listUv() {
        return (this.enums !== null && typeof this.enums['uv'] !== 'undefined') ? this.enums['uv'] : [];
    }

    get listRegion() {
        return (this.enums !== null && typeof this.enums['region'] !== 'undefined') ? this.enums['region'] : [];
    }

    start() {

        return new Promise((resolve, reject) => {

            if(User.isLogged) {
                //this.getList().then(resolve);
                this.list = [];
                this.getEnums().then(resolve);
            } else {
                resolve();
            }

        });

    }

    find(id) {

        return new Promise((resolve, reject) => {

            let salepoint = (this.list !== null) ? this.list.find(salepoint => {
                return salepoint.id === id;
            }) : null ;

            if(salepoint != null) {
                resolve(salepoint);
            } else {
                SalePoints.findOneRemote(id).then(datas => {
                    if(typeof datas.enums !== 'undefined') {
                        this.enums = datas.enums;
                    }
                    resolve(this.addToList(datas.salepoint));
                }).catch(_ => {
                    reject();
                });
            }

        });

    }

    search(keywords) {

        return new Promise((resolve, reject) => {

            if(keywords.length > 2) {

                SalePoints.searchRemote(keywords).then( datas => {
                    if(typeof datas.enums !== 'undefined') {
                        this.enums = datas.enums;
                    }
                    let searchList = [];
                    if(datas.salepoints.length > 0) {
                        datas.salepoints.forEach((salepoint, index) => {
                            searchList.push(this.addToList(salepoint));
                            if(index === (datas.salepoints.length-1)) {
                                resolve(searchList);
                            }
                        });
                    } else {
                        resolve([]);
                    }
                }).catch(cb => {
                    reject(cb);
                });

            } else {

                resolve([]);

            }

        });

    }

    addToList(datas) {

        if(this.list == null) {
            this.list = [];
        }
        let salepoint = new SalePoint(datas);
        // Is the interview already in the list ?
        let indexFound = this.list.findIndex(interview => {
            return interview.id === datas.id;
        });
        if(indexFound >= 0) {
            this.list[indexFound] = salepoint;
        } else {
            this.list.push(salepoint);
        }
        return salepoint;

    }

    create(datas) {

        return this.addToList(datas);

    }

    remove(salepoint) {

        return new Promise((resolve, reject) => {

            if(!isNaN(salepoint.id)) {

                this.removeRemote(salepoint.id).then(_ => {
                    this.list.filter((value, index) => {
                        return value.id != salepoint.id;
                    });
                    resolve();
                }).catch(cb => {
                    reject(cb);
                });

            } else {
                reject({
                    'success' : false,
                    'error' : 'id_not_numeric'
                });
            }

        });

    }

    checkUnicity(crm_code) {

        return new Promise((resolve, reject) => {

            this.checkUnicityRemote(crm_code).then(_ => {
                resolve(true)
            }).catch(cb => {
                resolve(false)
            });

        });

    }

    logout() {
        this.list = null;
        this.lastSearch = null;
        this.lastSearchList = null;
    }

}