import React, {Component} from 'react';
import PropTypes from "prop-types";
import './Searchbar.css';

import {SalePoints} from "app/Core";

export default class Searchbar extends Component {

    constructor(props){
        super(props);
        this.state = {
            searchValue: this.props.reloadSearch ? this.props.reloadSearch : '',
            noResults: false
        };
        this.inputSearch = React.createRef();
    }

    resetField(){
        SalePoints.lastSearch = null;
        this.setState({searchValue: ''});
        this.inputSearch.current.focus();
    }

    handleChange(e){
        this.setState({searchValue: e.target.value});
    }

    handleSubmit(e){

        e.preventDefault();

        if(!this.state.searchValue.trim().length){
            this.inputSearch.current.focus();
            return;
        }

        this.search();

    }

    search() {

        this.props.handleLoading(true);

        SalePoints.search(this.state.searchValue).then(list => {
            SalePoints.lastSearch = this.state.searchValue;
            SalePoints.lastSearchList = list;
            if(list.length){
                this.props.handleDatas(list, this.state.searchValue);
            } else{
                this.props.handleDatas(list, this.state.searchValue, true);
            }
        }).catch(callback => {
            //console.log(callback);
        });

    }

    render() {
        return (
            <div className="w-searchbar">
                <form className="w-searchbar__field" onSubmit={(e) => this.handleSubmit(e)}>
                    <button></button>
                    <input ref={this.inputSearch} type="search" name="searchbar" id="searchbar" placeholder="Tapez le nom d'un point de vente" onChange={(e) => this.handleChange(e)} value={this.state.searchValue} />
                    {this.state.searchValue ?
                          <div className="reset-field" onClick={() => this.resetField()}>
                              <i className={'icon icon-delete'} />
                          </div>
                    : null}
                </form>
            </div>
        );
    }
}

Searchbar.propTypes = {
    handleDatas: PropTypes.func.isRequired,
    handleLoading: PropTypes.func.isRequired,
    searchValue:  PropTypes.string,
    reloadSearch: PropTypes.string
};