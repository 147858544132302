import React, {Component} from 'react';
import './QuestionPage.css';

import PropTypes from "prop-types";

import {priorities} from "utils/fields";

import {User} from "app/Core";

import AnimateHeight from 'react-animate-height';

import Univers from './Fields/Univers';



export default class Actions extends Component {

    constructor(props) {

        super(props);

        this.state = {
            heights: {},
            dateHeights: {},
        };
    }


        buildAction(index = 0) {

        return (
            <div className={'Action accordeon priority ' + (index == 0 ?'first' : '')} key={`action_${index}`}>

                <div className="accordeon__head">
                    <div className="h3">Priorité - {this.props.value[index] && this.props.value[index]['priority'] ? this.props.value[index]['priority'] : index+1}</div>
                    <div>
                        <button className={'btn-icon btn-accordeon'} onClick={(e) => { let heights = {...this.state.heights}; heights[index] = heights[index] === 0 ? 'auto' : 0; this.setState({heights: heights}); }}>
                            {this.state.heights[index] === 0 ? 'Déplier' : 'Replier'} le contenu
                            <i className={'icon icon-miniarrowright ' + (this.state.heights[index] === 0 ? 'down' : 'up')} />
                        </button>
                    </div>
                </div>
                <AnimateHeight duration={ 500 } height={ this.state.heights[index] !== 0 ? 'auto' : 0 }>
                    <div className="accordeon__body">
                        {
                            index > 0 && !User.isSupervisor
                            ?
                                <button className={'Action__delete btn-icon txt--danger'} onClick={(e) => this.props.onDelete(index)}>
                                    <i className={'icon icon-delete'} />
                                    Supprimer
                                </button>
                            : null
                        }

                        <div className={'Action__row Action__choice'}>
                            <label>Choisissez une priorité</label>
                            <select disabled={User.isSupervisor} value={this.props.value[index] && this.props.value[index]['priority'] ? this.props.value[index]['priority'] : ''} name={`actions[${index}][priority]`} onChange={this.props.onChange}>
                                <option value=""></option>
                                {
                                    Object.keys(priorities).map((key,i) => {
                                        let item = priorities[key];
                                        return <option key={i} value={item}>{item}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            this.props.value[index] && this.props.value[index]['priority'] && (priorities.OTHER === this.props.value[index]['priority'] || priorities.COMPANIES === this.props.value[index]['priority'])
                            ?
                                <div className={'Action__row'}>
                                    <label>Précisez</label>
                                    <input disabled={User.isSupervisor} type={'text'} name={`actions[${index}][other]`} value={this.props.value[index] && this.props.value[index]['other'] ? this.props.value[index]['other'] : ''} onChange={this.props.onChange} />
                                </div>
                            :null
                        }
                        {
                            this.props.value[index] && this.props.value[index]['priority'] && priorities.HAGER_UNIVERS === this.props.value[index]['priority']
                                ?
                                <div className={'Action__row'}>
                                    <Univers vertical  label={'Précisez'} name={`actions[${index}][univers]`} id={''} value={this.props.value[index]['univers']}
                                             onClick={(item) => {
                                             }}
                                             onChange={(item, i) => {

                                                 let items = this.props.value[index]['univers'];
                                                 if(!Array.isArray(items)) {
                                                     items = [];
                                                 }
                                                 console.log(item,items, items.includes(item) );
                                                 if(Array.isArray(items) && items.includes(item)){

                                                     let j = 0;
                                                     while (j < items.length) {
                                                         if (items[j] === item) {
                                                             items.splice(j, 1);
                                                         } else {
                                                             ++j;
                                                         }
                                                     }
                                                     console.log(items);
                                                 }
                                                 else{
                                                     console.log('else');
                                                     items.push(item);
                                                 }
                                                 console.log(items);


                                                 this.props.setFieldValue( `actions[${index}][univers]`, items);
                                             }}
                                             onBlur={this.props.handleBlur} />
                                </div>
                                :null
                        }

                        <div className={'Action__row Action__columns'}>
                            <div className={'Action__col'}>
                                <label>Objectif</label>
                                <input disabled={User.isSupervisor} type='text' name={`actions[${index}][objectif]`} value={this.props.value[index] && this.props.value[index]['objectif'] ? this.props.value[index]['objectif'] : ''} onChange={this.props.onChange} />
                            </div>
                            <div className={'Action__col'}>
                                <label>Indicateurs de suivi</label>
                                <input disabled={User.isSupervisor} type='text' name={`actions[${index}][suivi]`} value={this.props.value[index] && this.props.value[index]['suivi'] ? this.props.value[index]['suivi'] : ''} onChange={this.props.onChange} />
                            </div>
                            <div className={'Action__col'}>
                                <label>Durée</label>
                                <input disabled={User.isSupervisor} type='text' name={`actions[${index}][duration]`} value={this.props.value[index] && this.props.value[index]['duration'] ? this.props.value[index]['duration'] : ''} onChange={this.props.onChange} />
                            </div>
                        </div>

                        <div className={'Action__row'}>
                            <label>Développez les actions à mettre en place</label>
                            <textarea disabled={User.isSupervisor} name={`actions[${index}][note]`} value={this.props.value[index] && this.props.value[index]['note'] ? this.props.value[index]['note'] : ''} onChange={this.props.onChange} />
                        </div>

                        {/*<div className={'Action__row Action__columns'}>*/}
                        {/*    <div className={'Action__col'}>*/}
                        {/*        <label>Moyens / budget</label>*/}
                        {/*        <input disabled={User.isSupervisor} type='text' name={`actions[${index}][budget]`} value={this.props.value[index] && this.props.value[index]['budget'] ? this.props.value[index]['budget'] : ''} onChange={this.props.onChange} />*/}
                        {/*    </div>*/}
                        {/*    <div className={'Action__col'}>*/}
                        {/*        <label>Indicateurs de perf.</label>*/}
                        {/*        <input disabled={User.isSupervisor} type='text' name={`actions[${index}][perf]`} value={this.props.value[index] && this.props.value[index]['perf'] ? this.props.value[index]['perf'] : ''} onChange={this.props.onChange} />*/}
                        {/*    </div>*/}
                        {/*    <div className={'Action__col'}>*/}
                        {/*        <label>Durée / période</label>*/}
                        {/*        <input disabled={User.isSupervisor} type='text' name={`actions[${index}][duration]`} value={this.props.value[index] && this.props.value[index]['duration'] ? this.props.value[index]['duration'] : ''} onChange={this.props.onChange} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </AnimateHeight>
            </div>
        );

    }


    buildDate(index = 0) {

        let dates = [
            {
                date:'',
                note:''
            }
        ];

        if(this.props.dates[index] && Array.isArray(this.props.dates[index])){
            dates = this.props.dates[index];
        }

        return (
            <>

                {
                    dates.map((date, i) => {

                        return (
                            <div key={`date_${index}_${i}`} className={'Action accordeon Action--date'}>
                                <div className="accordeon__head">
                                    <div className="h3">Date
                                        - {dates && dates[index] && dates[index][i] && dates[index][i]['date'] ? this.props.dates[index][i]['date'] : i + 1}</div>
                                    <div>
                                        <button className={'btn-icon btn-accordeon'} onClick={(e) => {
                                            let heights = {...this.state.dateHeights};
                                            heights[`${index}_${i}`] = heights[`${index}_${i}`] === 0 ? 'auto' : 0;
                                            this.setState({dateHeights: heights});
                                        }}>
                                            {this.state.dateHeights[`${index}_${i}`] === 0 ? 'Déplier' : 'Replier'} le contenu
                                            <i className={'icon icon-miniarrowright ' + (this.state.dateHeights[`${index}_${i}`] === 0 ? 'down' : 'up')}/>
                                        </button>
                                    </div>
                                </div>
                                <AnimateHeight duration={ 500 } height={ this.state.dateHeights[`${index}_${i}`] !== 0 ? 'auto' : 0 }>
                                    <div className="accordeon__body">
                                        {
                                        i > 0 && !User.isSupervisor
                                        ?
                                        <button className={'Action__delete btn-icon txt--danger'} onClick={(e) => this.props.onDeleteDate(index, i)}>
                                            <i className={'icon icon-delete'} />
                                            Supprimer
                                        </button>
                                                : null
                                        }

                                        <div className={'Action__row Action__date'}>
                                            <label>Date</label>
                                            <input disabled={User.isSupervisor} type={"text"} value={this.props.dates[index] && this.props.dates[index][i] && this.props.dates[index][i]['date'] ? this.props.dates[index][i]['date'] : ''} name={`action_dates[${index}][${i}][date]`} onChange={this.props.onChange} />
                                        </div>
                                        <div className={'Action__row'}>
                                            <label>Remarque</label>
                                            <textarea disabled={User.isSupervisor} name={`action_dates[${index}][${i}][note]`} value={this.props.dates[index] && this.props.dates[index][i] && this.props.dates[index][i]['note'] ? this.props.dates[index][i]['note'] : ''} onChange={this.props.onChange} />
                                        </div>
                                    </div>
                                </AnimateHeight>
                            </div>
                        )
                    })
                }

                <div className={'add-btn-w action-add-date'}>
                    <button className={'btn-icon'} onClick={(e) => this.props.onAddDate(index)}>
                        <i className={'icon icon-add txt--secondary'} />
                        Ajouter une nouvelle date
                    </button>
                </div>
            </>
        );

    }




    render() {

        console.log(this.props.value);

        return (
            <React.Fragment>
                <h2 className={'h2 Title'}>{this.props.page.actions_label ? this.props.page.actions_label : 'Actions et suivi à mettre en oeuvre'}</h2>
                <br /><br />
                <div>
                {/*{*/}
                {/*    this.props.value.length*/}
                {/*    ?*/}
                {/*    this.props.value.map((value, i) => {*/}
                {/*        return [this.buildAction(i), this.buildDate(i)]*/}
                {/*    })*/}
                {/*    :  [this.buildAction(), this.buildDate()]*/}
                {/*}*/}
                {
                    this.props.value.length
                    ?
                    this.props.value.map((value, i) => {
                        return [this.buildAction(i)]
                    })
                    :  [this.buildAction()]
                }

                </div>

                    <div className={'add-btn-w'}>
                        {
                            !User.isSupervisor
                                ?
                        <button className={'btn-icon'} onClick={(e) => this.props.onAdd()}>
                            <i className={'icon icon-add txt--secondary'} />
                            Ajouter une nouvelle priorité
                        </button>
                                :null
                        }
                    </div>

                {/*
                <h2 className={'h2 Title'}>{'Suivi des actions'}</h2>
                <div>
                {
                    this.props.dates.length
                    ?
                    this.props.dates.map((value, i) => {
                        return this.buildDate(i)
                    })
                    :  this.buildDate()
                }
                </div>
                {
                    !User.isSupervisor
                    ?
                    <div className={'add-btn-w'}>
                        <button className={'btn-icon'} onClick={(e) => this.props.onAddDate()}>
                            <i className={'icon icon-add txt--secondary'} />
                            Ajouter une nouvelle date
                        </button>
                    </div>
                    : null
                }
                */}

            </React.Fragment>
        );



    }
}

Actions.propTypes = {
    page: PropTypes.object.isRequired,
    value: PropTypes.array,
    dates: PropTypes.array
};

Actions.defaultProps = {
    page:null,
    value:[],
    dates:[]
};
