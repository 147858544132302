import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './Button.css';

export default class Button extends Component {
    render() {
        return (
            <React.Fragment>

                {
                    this.props.type === "submit" ?

                        <button type={"submit"} className={"btn " + (this.props.className)} disabled={this.props.disabled ? this.props.disabled : false} >
                            {this.props.content ? this.props.content : null}
                            {this.props.children ? this.props.children : null}
                        </button>

                        :

                        <div className={"btn " + (this.props.className)} onClick={this.props.onClick}>
                            {this.props.content ? this.props.content : null}
                            {this.props.children ? this.props.children : null}
                        </div>
                }

            </React.Fragment>
        );
    }
}

Button.propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

Button.defaultProps = {
    type: 'div',
    className: 'btn--primary',
    content: '',
};