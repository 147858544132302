import React, {Component} from 'react';

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";
import AddContact from "../AddContact/AddContact";

import UpdateShop from "components/Forms/UpdateShop";

import { GlobalContext } from "providers/GlobalProvider";
import { CHOOSE_SHOP } from "utils/constants";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import LoaderSpiral from "../../LoaderSpiral/LoaderSpiral";
import {SalePointService} from "services/SalePointService";

import {Observer} from "app/Core";

export default class CreateShop extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentStep: 1,
            totalStep: 2,
            shop: {}
        }
    }



    handleStep(currentStep, shop){
        if(currentStep === 0){
            if(this.props.previous){
                this.props.previous();
            } else{
                this.context.setModalContent(CHOOSE_SHOP);
            }
            return;
        }
        let states = {currentStep: currentStep};
        if(shop){
            states.shop = shop;

        }
        this.setState(states);
    }

    createShop(contacts = []){

        let datas = this.state.shop;
        datas.contacts = contacts;

        this.setState({isLoading:true});


        SalePointService.save(datas).then(response => {

            if(200 === response.status){
                SalePointService.getUserList().then(response => {

                    if(response.data && Array.isArray(response.data)) {
                        this.context.setUserSalePoints(response.data);
                    }
                    this.setState({isLoading:false});
                    Observer.publish('shopCreated');
                    this.context.closeModal();

                }).catch(error => {
                    this.setState({isLoading:true});
                    toast.error("Une erreur est survenue lors de la sauvegarde de l'entretien.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        toastId: 'interview_save_error'
                    });
                })
            }


        }).catch(error => {

            this.setState({isLoading:false});
        });


    }

    setLoading(isLoading) {
        this.setState({
            isLoading : isLoading
        });
    }

    render() {

        return (
            <React.Fragment>


                <ModalHead subtitle={"Etape " + this.state.currentStep + "/" + this.state.totalStep} title={"Créer un point de vente"} />

                <ModalContent>
                    {this.state.isLoading ? <div className="w-loading"><LoaderSpiral/></div> : null}
                    {this.state.currentStep === 1 ?
                        <UpdateShop shop={this.props.shop} setLoading={(isLoading) => this.setLoading(isLoading)} handleStep={(currentStep, step) =>  this.handleStep(currentStep, step)} shop={this.state.shop} />
                    : null}

                    {this.state.currentStep === 2 ?
                        <AddContact shop={this.state.shop} interview={this.props.interview} handleStep={(currentStep) =>  this.handleStep(currentStep)} onSubmit={(contacts) => this.createShop(contacts)}/>
                    : null}
                </ModalContent>

            </React.Fragment>
        );
    }

}

CreateShop.contextType = GlobalContext;

CreateShop.propTypes = {
    onFinish: PropTypes.func,
    interviewObj: PropTypes.object
};

CreateShop.defaultProps = {
    interviewObj: {}
};
