import {Network,Storage,Observer} from "app/Core";

export class SalePointsAction {

    getRemoteEnums() {

        return new Promise((resolve, reject) => {

            Network.post('/api/enums/', {})
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.enums);
                    } else {
                        resolve(null);
                    }
                });

        });

    }

    getRemoteList() {

            return Network.get('/api/salepoints/');

    }

    searchRemote(keyword) {

        return new Promise((resolve, reject) => {

            Network.post('/api/salepoints/search/', {
                'keyword' : keyword
            })
                .then(cb => {
                    if(cb.success) {
                        console.log(cb.datas.enums);
                        resolve(cb.datas);
                    } else {
                        reject(cb);
                    }
                });

        });

    }

    findOneRemote(id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/salepoints/find/', {
                'pdv_id' : id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas);
                    } else {
                        reject();
                    }
                });

        });

    }

    removeRemote(id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/pdv/remove/', {
                'id' : id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve();
                    } else {
                        resolve(cb);
                    }
                });

        });

    }

    checkUnicityRemote(crm_code) {

        return new Promise((resolve, reject) => {

            Network.post('/api/pdv/check_crm_code_existance/', {
                'crm_code' : crm_code
            })
                .then(cb => {
                    if(cb.success) {
                        resolve();
                    } else {
                        reject();
                    }
                });

        });

    }

}