export const hostname = {
    "localhost": "https://hagerdriverbo.lndo.site",
    "0.0.0.0": "https://hagerdriverbo.lndo.site",
    "hagerdriver.adeliomdev.com": "http://hagerdriver.adeliomdev.com",
    "dev-driver.hager.fr": "http://dev-driver.hager.fr",
    "driver.hager.fr": "https://driver.hager.fr",
    "partner.hagercloud.com": "https://partner.hagercloud.com",
    "hagerdriverbo.lndo.site": "https://hagerdriverbo.lndo.site"
};

export const baseUrl = hostname[window.location.hostname] ? hostname[window.location.hostname] : 'https://hagerdriver.adeliomdev.com';

export const fileUrl = function(filename) {

    return networkConfig.url + '/uploads/files/' + filename;

}


const networkConfig = {
    url: baseUrl
};

export default networkConfig;
