import React, {Component} from 'react';
import PropTypes from "prop-types";

import Button from 'components/Button/Button';

import './Confirm.css';


export default class Confirm extends Component {


    render() {

        return (
                <div className={'Confirm ' + this.props.className}>
                    <button className={'Confirm__close'} onClick={this.props.onClose}>
                        <i className={'icon icon-close'} />
                    </button>
                    <div className={'Confirm__head'}>
                        {this.props.headline ? <div className={'headline'}>{this.props.headline}</div> : null }
                        <h2 className={'h2 h2--secondary'}>{this.props.title}</h2>
                    </div>

                    {this.props.children ? <div className={'Confirm__body'}>{this.props.children}</div> : null }

                    <div className={'Confirm__foot'}>
                        {
                         this.props.buttonOk
                         ?
                            <Button className={this.props.danger ? 'btn--danger' : 'btn--primary'} onClick={this.props.onValidate}>{this.props.buttonOk}</Button>
                        : null }
                        <Button className={'btn--secondary'} onClick={this.props.onClose}>{this.props.buttonKo}</Button>
                    </div>
                </div>
        );
    }

}

Confirm.propTypes = {
    headline: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    buttonOk: PropTypes.string,
    buttonKo: PropTypes.string,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    danger:PropTypes.bool
};

Confirm.defaultProps = {
    className: '',
    headline:'',
    title: '',
    text: '',
    buttonOk: '',
    buttonKo: '',
    danger:false
};