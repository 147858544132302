import {Network,Storage,Observer} from "app/Core";
import {ContactAction} from "./ContactAction";
import {SalePoints} from "../Core";

export class Contact extends ContactAction {

    datas = {
        id: 0,
        pdv_id: 0,
        email: 0,
        lastname: '',
        firstname: '',
        crm_code: '',
        fonction: '',
        created_at: new Date(),
        updated_at: new Date()
    };
    
    salepoint = null;

    constructor(datas) {
        super();
        if (typeof datas != 'undefined') {
            this.datas = datas;
        }
    }

    get pdv() {
        return this.salepoint;
    }

    set pdv(salepoint) {
        this.salepoint = salepoint;
        this.datas['pdv_id'] = salepoint.id;
    }

    get id() {
        return this.datas['id'];
    }

    get lastname() {
        return this.datas['lastname'];
    }

    set lastname(lastname) {
        this.datas['lastname'] = lastname;
    }

    get firstname() {
        return this.datas['firstname'];
    }

    set firstname(firstname) {
        this.datas['firstname'] = firstname;
    }

    get createdAt() {
        return this.datas['created_at'];
    }

    get updatedAt() {
        return this.datas['updated_at'];
    }

    get crm_code() {
        return this.datas['crm_code'];
    }

    get email() {
        return this.datas['email'];
    }

    set email(email) {
        this.datas['email'] = email;
    }

    get fonction() {
        return this.datas['fonction'];
    }

    set fonction(fonction) {
        this.datas['fonction'] = fonction;
    }

    set pdv_id(pdv_id) {
        this.datas['pdv_id'] = pdv_id;
    }

    listFunctions() {

        return [
            "Technico-commercial",
            "Directeur achats",
            "branch manager",
            "Directeur ventes",
            "employee",
            "director",
            "Chef",
            "Directeur",
            "Acheteur",
            "Vendeur",
            "Agent",
            "Secrétaire",
            "Technicien",
            "Chargé(e)/attaché(e)",
            "Sales rep.",
            "Président PDG Gérant",
            "Chargé d'affaires",
            "Comité directeur",
            "Contact E-Shop",
            "sales representative",
            "Patron artisan",
            "Directeur marketing",
            "Gestionnaire",
            "Chef du personnel",
            "Sales intern",
            "Secrétaire de direc.",
            "Regional Sales Manag",
            "Chef serv. informat.",
            "Group coordinator",
            "Informaticien",
            "Contrôleur",
            "Outside Vendor",
            "Maître d'ouvrage",
            "Directeur compt.fin.",
            "Formateur"
        ];

    }

    getPdv() {
        return new Promise((resolve, reject) => {
            if(this.salepoint === null) {
                SalePoints.find(this.datas['pdv_id']).then(salepoint => {
                    this.salepoint = salepoint;
                    resolve(this.pdv);
                }).catch(cb => {
                    resolve(null);
                });
            } else {
                resolve(this.salepoint);
            }
        });
    }

    save() {

        return new Promise((resolve, reject) => {

            return this.saveRemote(this.datas).then(contact => {
                this.salepoint = null;
                this.datas = contact;
                this.getPdv().then(resolve);
            }).catch(cb => {
                reject(cb);
            });

        });

    }

}