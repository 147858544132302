import React from 'react';
import './QuestionPage.css';


import { GlobalContext } from "providers/GlobalProvider";
import {PrivateComponent, User} from "app/Core";



import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import Button from "components/Button/Button";
import {UPDATE_SHOP} from "utils/constants";

import { confirmAlert } from 'react-confirm-alert';
import Confirm from 'components/Confirm/Confirm';
import {toast} from "react-toastify";
import Card from "../../../components/Card/Card";
import AddContact from "../../../components/Forms/AddContact";
import {SalePointService} from "../../../services/SalePointService";

import {Observer} from "app/Core";
import {InterviewService} from "../../../services/InterviewService";

import RelatedSalePoints from './RelatedSalePoints/RelatedSalePoints';


import networkConfig from "config/network";
import _ from "lodash";
import {fieldsTypes} from "../../../utils/fields";
import RatingWithAnswers from "./Fields/RatingWithAnswers";
import {isJSON} from "../../../helpers/string";
import {withFormik} from "formik";
import * as Yup from "yup";

const QUESTION_PREFIX = 'question_';

class StartingPage extends PrivateComponent {

    constructor(props) {

        super(props);

        this.state = {scoreMore:[], priorityPrevious:[]};
    }

    componentDidMount(){
        const { childRef } = this.props;
        childRef(this);

        this.getAnswers();
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.page.id != this.props.page.id) {
            this.getAnswers();
        }
    }

    getAnswers() {

        let values = {};

        if(this.props.interview && this.props.interview.pages) {

            let pageAnswer = _.find(this.props.interview.pages, (item) => {
                return this.props.page.id === item.question_page_id;
            });

            values['free_comment'] = pageAnswer && pageAnswer.free_comment? pageAnswer.free_comment : '';
            values['internal_comment'] = pageAnswer && pageAnswer.internal_comment ? pageAnswer.internal_comment : '';
            values['validate'] = '0';

        }

        if(this.props.interview && this.props.interview.answers) {

            if(this.props.page && this.props.page.questions){

                this.props.page.questions.forEach(question => {

                    let answer = _.find(this.props.interview.answers, (answer) => {
                        return question.id === answer.question.id;
                    });

                    if(answer) {
                        values[this.getQuestionFieldName(question.id)] = answer.answer && isJSON(answer.answer) ? JSON.parse(answer.answer) : answer.answer;
                    }

                })
            }

        }


        this.props.setValues(values);
    }

    getValues() {
        return this.props.values;
    }

    editListContact(e,item) {

        e.preventDefault();
        e.stopPropagation();

        confirmAlert({
            customUI: ({ onClose }) => {

                let form = React.createRef();

                return (

                    <Confirm
                        className={'form'}
                        onClose={onClose}
                        title='Editer un contact'
                        danger={false}
                        onValidate={() => {

                            form.submit();
                        }}
                        buttonOk='Enregistrer ce contact'
                        buttonKo='Annuler'
                    >

                        <AddContact contact={item} handleContact={(values, index, noIdea, isValid) => {
                            if(isValid) {
                                SalePointService.addContact(this.props.salePoint.id, values).then(response => {
                                    if(response.status === 200) {
                                        Observer.publish('updateContact');
                                        onClose();
                                    }
                                }).catch(e => {
                                    toast.error("Erreur lors de la création du contact ", {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        toastId: 'contact_error'
                                    });
                                })
                            }
                        }} childRef={ref => (form = ref)} once={true} noButton={true} />
                    </Confirm>

                );
            }
        });

    }


    editContact(e, currentContact) {

        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <Confirm
                        className={'form'}
                        onClose={onClose}
                        title='Sélectionner un nouveau contact'
                        danger={false}

                        buttonKo='Annuler'
                    >
                        {
                            this.props.salePoint.contacts
                            ?
                                <React.Fragment>
                                    {
                                        this.props.salePoint.contacts.map((item, i) => {
                                            return <Card contact={true} onEdit={(e, item) => this.editListContact(e,item)} edit={true} data={item} key={i} onClick={(contact) => {

                                                InterviewService.setContact(this.props.interview.id, contact.id).then(response => {
                                                    if (response.status === 200) {
                                                        Observer.publish('updateContact');
                                                        onClose();
                                                    }
                                                }).catch(e => {
                                                    toast.error("Erreur lors de l'ajout du contact ", {
                                                        position: toast.POSITION.BOTTOM_RIGHT,
                                                        toastId: 'contact_error'
                                                    });
                                                })

                                            }}/>
                                        })
                                    }
                                    <Button onClick={(e) => this.addContact() }>Ajouter un nouveau contact</Button>
                                </React.Fragment>
                            : null
                        }

                    </Confirm>

                );
            }
        });
    }

    addContact(e) {

        confirmAlert({
            customUI: ({ onClose }) => {

                let form = React.createRef();

                return (

                    <Confirm
                        className={'form'}
                        onClose={onClose}
                        title='Ajouter un contact'
                        danger={false}
                        onValidate={() => {

                            form.submit();
                        }}
                        buttonOk='Ajouter un nouveau contact'
                        buttonKo='Annuler'
                    >

                        <AddContact handleContact={(values, index, noIdea, isValid) => {
                            if(isValid) {
                                SalePointService.addContact(this.props.salePoint.id, values).then(response => {
                                    if(response.status === 200) {
                                        Observer.publish('updateContact');
                                        onClose();
                                    }
                                }).catch(e => {
                                    toast.error("Erreur lors de la création du contact ", {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        toastId: 'contact_error'
                                    });
                                })
                            }
                        }} childRef={ref => (form = ref)} once={true} noButton={true} />
                    </Confirm>

                );
            }
        });

    }

    getQuestionFieldName(questionId) {
        return QUESTION_PREFIX + questionId;
    }

    buildQuestions() {

        let questions = [];

        if(this.props.page && this.props.page.questions && this.props.values && this.props.interview) {

            this.props.page.questions.forEach(question => {

                let id = this.getQuestionFieldName(question.id);

                switch (question.type) {

                    case fieldsTypes.TYPE_RATING_WITH_ANSWERS:
                        questions.push(
                            <RatingWithAnswers pdv={this.props.interview.pdv} key={id} label={question.name} answers={question.answers} name={id} id={id} value={this.props.values[id]} onChange={(item) => this.props.setFieldValue(id, item)} onBlur={this.props.handleBlur} />
                        );
                        break;
                }

            });
        }

        return questions;
    }

    render() {


        return (
            <div className="StartingPage container">


               <div className={'info-card-w row'}>
                   <div className={'info-card-col'}>
                       <h2 className="h2">{this.props.salePoint.direction ? 'Direction' : 'Point de vente' }</h2>
                       <div className={'info-card'}>
                           <span className={'info-card__icon'}><i className={'icon icon-place'} /> </span>
                           <h3 className={'info-card__title'}>{this.props.salePoint.name}</h3>
                           <div className={'info-card__txt'}>
                           {this.props.salePoint.address}<br />
                           {this.props.salePoint.zip_code} {this.props.salePoint.city}
                           </div>
                           <button
                               className={'info-card__edit'}
                               onClick={(e) => {
                                   e.preventDefault();
                                   this.context.setShop(UPDATE_SHOP, this.props.salePoint, this.props.interview, false )
                               }}
                           >    <i className={'icon icon-edit'} />
                           </button>
                       </div>
                   </div>

                   <div className={'info-card-col'}>
                       <h2 className="h2">Interlocuteur</h2>
                       <div className={'info-card'}>
                           {
                               this.props.interview.pdv_contact
                               ?
                                   <React.Fragment>
                                       <span className={'info-card__icon'}><i className={'icon icon-contact'} /> </span>
                                       <h3 className={'info-card__title'}>
                                           {this.props.interview.pdv_contact.first_name} {this.props.interview.pdv_contact.last_name}
                                       </h3>
                                       <div className={'info-card__txt'}>
                                        {this.props.interview.pdv_contact.function}
                                       </div>
                                       <div className={'info-card__email'}>
                                           {this.props.interview.pdv_contact.email}
                                       </div>
                                       <button onClick={(e) => {

                                           this.editContact(e, this.props.interview.pdv_contact);


                                       }} className={'info-card__edit'}><i className={'icon icon-edit'} /> </button>
                                   </React.Fragment>
                               : <Button onClick={(e) => {this.editContact(e)}}>Sélectionner un interlocuteur</Button>
                           }
                       </div>
                   </div>
               </div>

                {
                    this.props.salePoint.direction
                    ?
                        <RelatedSalePoints salePoint={this.props.salePoint} />
                    :
                    null
                }


                <div className={'StartingPage__txt'}>
                    <p>Pour bien démarrer l'année je vous propose dans un premier temps de faire un bilan des actions que nous avons menées ensemble.<br />Puis d'échanger sur nos priorités et objectifs afin d'identifier les actions communes que nous souhaitons entreprendre cette année.</p>
                </div>

                {/*<div className={'File question question--bg'}>*/}
                    {/*<div className={'File__in question__in'}>*/}
                    {/*    <a className={'File__link'} href={networkConfig.url + '/medias/reflexe.pdf'} target={"_blank"}>*/}
                    {/*        <i className={'icon icon-pdf'} />*/}
                    {/*        Télécharger le PDF Hager Reflexes*/}
                    {/*    </a>*/}
                    {/*    <i className={'icon arrow icon-miniarrowright'} />*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/* TODO : maj pdf files */}
                <div style={{'display': 'flex', 'width': '100%', 'justifyContent': 'space-between'}}>
                    <div style={{'width': '49%'}} className={'File question question--bg'}>
                        <div className={'File__in question__in'}>
                            <a className={'File__link'} href={networkConfig.url + '/medias/guide-utilisation-Hager Partner.pptx'} target={"_blank"}>
                                <i className={'icon icon-pdf'} />
                                Télécharger le guide d'utilisation
                            </a>
                            <i className={'icon arrow icon-miniarrowright'} />
                        </div>
                    </div>
                    {/*<div style={{'width': '49%'}} className={'File question question--bg'}>*/}
                    {/*    <div className={'File__in question__in'}>*/}
                    {/*        <a className={'File__link'} href={networkConfig.url + '/medias/reflexe.pdf'} target={"_blank"}>*/}
                    {/*            <i className={'icon icon-pdf'} />*/}
                    {/*            Télécharger le pdf des outils merchandising<br /> Hager Partner*/}
                    {/*        </a>*/}
                    {/*        <i className={'icon arrow icon-miniarrowright'} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>


                <div className={'StartingPage__txt'}>
                    <div className={'Rating question question--bg'}>
                        { this.buildQuestions() }
                        {/*<div className={'Rating__in question__in'}>*/}
                        {/*    <div className="question__side">*/}
                        {/*        <label className={'question__name'}>Merchandising Hager</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="Rating__control question__control">*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={true} type='radio' value={'Oui'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>Oui</span></label>*/}
                        {/*        </div>*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={false} type='radio' value={'Non'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>Non</span></label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className={'Rating__in question__in'}>*/}
                        {/*    <div className="question__side">*/}
                        {/*        <label className={'question__name'}>Tête de gondole</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="Rating__control question__control">*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={true} type='radio' value={'Oui'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>Oui</span></label>*/}
                        {/*        </div>*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={false} type='radio' value={'Non'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>Non</span></label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className={'Rating__in question__in'}>*/}
                        {/*    <div className="question__side">*/}
                        {/*        <label className={'question__name'}>Point de vente étoilé</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="Rating__control question__control">*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={true} type='radio' value={'1'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>1 étoile</span></label>*/}
                        {/*        </div>*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={false} type='radio' value={'2'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>2 étoiles</span></label>*/}
                        {/*        </div>*/}
                        {/*        <div className={'Rating__input-w'}>*/}
                        {/*            <input className={'Rating__input'} checked={false} type='radio' value={'3'} />*/}
                        {/*            <label className={`Rating__label level-1`}><span>3 étoiles</span></label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>

                <br /><br /><br />
            </div>
        );
    }
}

StartingPage.propTypes = {
    salePoint: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    interview: PropTypes.object.isRequired,
};

StartingPage.defaultProps = {
    salePoint: null,
    page:null,
    interview:null
};

const validationSchema = Yup.object().shape({
    internal_comment: Yup.string(),
    free_comment: Yup.string()
});

export default withRouter(withFormik({

    mapPropsToValues: (props) => ({
        internal_comment: '',
        free_comment: ''
        //question_156: ''
    }),

    validationSchema: validationSchema,

    handleSubmit: (values, { setSubmitting, setStatus, setFieldError,  props }) => {},


})(StartingPage));

StartingPage.contextType = GlobalContext;
