import React, {Component} from 'react';
import PropTypes from "prop-types";

import {User} from "app/Core";


export default class Select extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (

            <div className={'Months question question--bg'}>
                <div className={'Months__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Months__control question__control">
                        <select disabled={User.isSupervisor} name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.handleBlur}>
                            <option value=""></option>
                            {
                                this.props.answers.map((item, i) => {
                                    return <option key={i} value={item.value}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                {/*{this.props.previousAnswer && this.props.previousAnswer.value !== null*/}
                {/*    ?<div className={'previous-answer'}>Réponse n-1 : "{this.props.previousAnswer.answer}"</div>*/}
                {/*    : null}*/}
            </div>
        );
    }

}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.string,

};

Select.defaultProps = {

};
