export const statusKey = {
    TODO: 'todo',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed'
}

export const generateStatus = function (status){
    switch (status) {
        case statusKey.TODO :
            return 'à faire';
        case statusKey.IN_PROGRESS :
            return 'en cours';
        case statusKey.COMPLETED :
            return 'réalisé';
    }
}