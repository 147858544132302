import React, {Component} from 'react';
import PropTypes from "prop-types";

class ModalContent extends Component {

    constructor(props){
        super(props);
        this.scrollContainer = React.createRef();
        this.state = {
            isActive: true
        };
    }

    componentDidMount(){
        this.scrollContainer.current.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        this.scrollContainer.current.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll(e) {

        const el = e.target;
        let state = true;

        if(el.scrollTop + el.offsetHeight >= el.scrollHeight) {
            state = false;
        }

        this.setState({
            isActive: state
        });

    }

    componentDidUpdate(){
        if(this.scrollContainer.current){
            let modal = document.querySelector('.w-modal-overlay');
            if(this.isScrollable()){
                modal.classList.add('scroll-active');
            } else{
                modal.classList.remove('scroll-active');
            }
        }
    }

    isScrollable(){
        let el = document.querySelector('.w-modal__content__main');
        if(el){
            let hasVerticalScrollbar = el.scrollHeight > el.clientHeight;
            return hasVerticalScrollbar;
        }
        return false;
    }

    render() {
        
        return (
            <div ref={this.scrollContainer} className={"w-modal__content__main custom-scrollbar " + (this.props.className)}>
                {this.props.children}
                <div className={"divider " + (!this.state.isActive ? 'disabled' : '')} ></div>
            </div>
        );
    }

}

export default ModalContent;

ModalContent.propTypes = {
    className: PropTypes.string
};

ModalContent.defaultProps = {
    className: ''
};