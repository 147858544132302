import React, {Component} from 'react';
import Button from "../../../../components/Button/Button";

import PropTypes from "prop-types";

import {User} from "app/Core";
import {InterviewService} from "services/InterviewService";

export default class Validation extends Component {

    showPdf(e) {
        e.preventDefault();

        window.open(InterviewService.getPdfLink(this.props.interview.token), "_blank");
    }


    render() {

        return (

            <React.Fragment>

                <div className={'Validation'}>
                    <input type={'hidden'} name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.onBlur} />


                    <h2 className={'Validation__title Title h2'}>
                        {this.props.interview && this.props.interview.status ? 'L’entretien a été validé !' : 'Merci de votre confiance !' }
                    </h2>

                    <div>
                        {
                            this.props.interview && this.props.interview.status
                            ?
                                <div>Les résultats de l’entretien ont été validé et notifié à votre client.<br /> Vous pouvez continuer à éditer l’entretien si nécessaire.</div>
                            : <div dangerouslySetInnerHTML={{__html:'Votre entretien est complété.<br />Vous pouvez dès à présent valider l’entretien et notifier le client en cliquant sur le bouton ci-dessous.<br />Tout au long de l’année, vous pourrez modifier et éditer l’entretien même si celui-ci a été validé.'}} />
                        }

                    </div>
                    {
                        !User.isSupervisor
                        ?
                        <div className={'Validation__footer'}>
                            {
                                this.props.interview && this.props.interview.status
                                    ?
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        this.props.onLeave();
                                    }} className={'btn--primary'}>Enregistrer et quitter</Button>
                                    :
                                    <React.Fragment>
                                        <Button onClick={(e) => {
                                            e.preventDefault();
                                            this.props.onValidate();
                                        }} className={'btn--primary'}>Valider et notifier le client</Button>
                                        <Button onClick={(e) => {
                                            e.preventDefault();
                                            this.props.onSave();
                                        }} className={'btn--secondary'}>Enregistrer en brouillon</Button>
                                    </React.Fragment>
                            }
                        </div>
                        : null
                    }

                </div>

                <div onClick={(e) => this.showPdf(e)}  className={'File question question--bg'}>
                    <div className={'File__in question__in'}>
                        <span className={'File__link'}>
                            <i className={'icon icon-pdf'} />
                            Exporter au format PDF
                        </span>
                        <i className={'icon arrow icon-miniarrowright'} />
                    </div>
                </div>

            </React.Fragment>
        );
    }

}

Validation.propTypes = {
    onValidate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

Validation.defaultProps = {
};
