import React, {Component} from 'react';
import PropTypes from "prop-types";

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";

import Card from "components/Card/Card";
import Button from 'components/Button/Button';
import AddContact from "components/Forms/AddContact";
import {Interviews, Observer} from "app/Core";
import UpdateContact from "../UpdateContact/UpdateContact";
import {GlobalContext} from "providers/GlobalProvider";

export default class ChooseContact extends Component {

    constructor(props){
        super(props);
        this.state = {
            contacts: [],
            contact: {},
            index: null,
            addContact: false,
            isLoaded: false
        };
    }

    componentDidMount() {
        this.props.shop.getContacts().then(_ => {
            this.props.shop.getInterviews().then(_ => {
                this.setState({
                    contacts: this.props.shop.contacts,
                    isLoaded: true
                });
            });
        });
    }

    saveContact(contacts){
        this.setState({
            contacts: contacts,
            contact: {}
        });
    }

    addContact(datas){
        this.props.shop.addContact(datas);
        this.props.shop.save().then(_ => {
            this.setState({
                contacts: this.props.shop.contacts,
                addContact: false
            });
        }).catch(cb => {
            alert('Une erreur est survenue');
        });
    }

    handleContact(contact, index){

        // We come here when we simply edit the contact information
        if(this.props.changeContact) {
            this.props.interviewObj.contact = contact;
            this.props.interviewObj.save().then(_ => {
                this.props.onFinish();
            }).catch(cb => {
            });
        }
        // w
        else if(!this.props.interview){
            this.setState({contact: contact, index: index});
        }
        // we come here after choosen a contact in the interview creation process
        else if (this.props.creationProcess) {
            // Mode interview : we have choose a contact, so we can create the interview and redirect to it
            let interview = Interviews.create();
            interview.pdv = this.props.shop;
            interview.contact = contact;
            interview.save().then(_ => {
                Observer.publish('redirectToInterviewPageAfterCreation', interview);
                this.props.onFinish();
            }).catch(cb => {
            });
        }
        // we come here after choosen a contact in the interview pdv modification process
        else {
            this.props.interviewObj.pdv = this.props.shop;
            this.props.interviewObj.contact = contact;
            this.props.interviewObj.save().then(_ => {
                this.props.interviewObj.getAnswers().then(_ => {
                    this.props.onFinish();
                });
            }).catch(cb => {
            });
        }
    }

    previous(){
        if(this.props.previous){
            this.props.previous();
        } else{
            this.context.closeModal();
        }
    }

    render() {

        const title = this.props.handleContacts ? 'Quel contact souhaitez-vous modifier ?' : 'Quel contact souhaitez-vous associer ?';

        return (
            <React.Fragment>
                {
                    Object.keys(this.state.contact).length ?

                    <UpdateContact shop={this.props.shop} contact={this.state.contact} saveContact={(contacts) => this.saveContact(contacts)} previousStep={() => this.setState({contact:{}})}/>

                    :

                <React.Fragment>

                    {
                    this.props.changeContact ?
                        <ModalHead className={"choose-shop"} subtitle={"Interlocuteur"} title={"Changer de contact"}/>
                    : this.props.handleContacts ?
                        <ModalHead className={"choose-shop"} subtitle={"Point de vente"} title={"Gérer les contacts"}/>
                    :
                        <ModalHead className={"choose-shop"} subtitle={this.props.creationProcess ? "Nouvel entretien" : ""} title={"Choisissez un contact"}/>
                    }

                    <ModalContent>

                        {this.state.isLoaded && this.state.addContact ?

                                <React.Fragment>
                                    <h3 className="title h3">Ajouter un contact</h3>
                                    <AddContact once={true} handleStep={() => this.setState({addContact:false})} handleContact={(contact) => this.addContact(contact)}/>
                                </React.Fragment>

                                :

                                <React.Fragment>
                                    <h3 className="title h3">{title}</h3>
                                    <div className="w-list-contacts display-row">
                                        <div className="row-small">
                                            {
                                                this.state.contacts.map((contact, i) => {
                                                    return <Card contact={true} data={contact} key={i} onClick={(contact) => this.handleContact(contact, i)} />
                                                })
                                            }
                                        </div>
                                        {!this.props.disallowContactCreation ?
                                            <span className="add-contact link-icon" onClick={() => this.setState({addContact:true})}>{this.props.interview ? "Créer un nouveau contact" : "Ajouter un contact"}</span>
                                        : null }
                                    </div>
                                    <div className={"w-btn"}>
                                        <Button className={"btn--secondary"} content={this.props.handleContacts ? "Retour" : this.props.changeContact ? "Annuler" : "Précédent"} onClick={() => this.previous()}/>
                                    </div>
                                </React.Fragment>
                        }

                    </ModalContent>

                </React.Fragment>

                }
            </React.Fragment>
        );
    }

}

ChooseContact.contextType = GlobalContext;

ChooseContact.propTypes = {
    contacts: PropTypes.array,
    shop: PropTypes.object,
    interviewObj: PropTypes.object,
    changeContact: PropTypes.bool,
    handleContacts: PropTypes.bool,
    interview: PropTypes.bool,
    disallowContactCreation: PropTypes.bool,
    creationProcess: PropTypes.bool,
    previous: PropTypes.func,
    onFinish: PropTypes.func
};

ChooseContact.defaultProps = {
    contacts: [],
    shop: {},
    interviewObj: {},
    handleContacts: false,
    interview: false,
    changeContact: false,
    disallowContactCreation: false,
    creationProcess: true
};