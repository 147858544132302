import React, {Component} from 'react';
import PropTypes from "prop-types";


import {User} from "app/Core";

export default class Stock extends Component {

    constructor(props) {
        super(props);

        this.labels = {
            0:'Non stocké',
            1:'Stock J0',
            2:'Stock J+1'
        }
    }

    render() {

        return (

            <div className={'Rating question question--bg'}>
                <div className={'Rating__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.label} </label>
                    </div>
                    <div className="Rating__control question__control">
                    {
                        [0,1,2,/*4,5*/].map((item, i) => {
                            return <div className={'Rating__input-w'}  key={i}>
                                <input disabled={User.isSupervisor} className={'Rating__input'} id={`${this.props.name}_${item}`} checked={this.props.value === item} type='radio' value={item} name={this.props.name} onChange={() => this.props.onChange(item)} onBlur={this.props.handleBlur} />
                                <label className={`Rating__label level-${item}`} htmlFor={`${this.props.name}_${item}`}><span>{this.labels[item]}</span></label>
                            </div>
                        })
                    }
                    </div>
                </div>
                {this.props.previousAnswer && this.props.previousAnswer.value !== null
                    ?<div className={'previous-answer'}>Réponse n-1 : "{this.labels[this.props.previousAnswer.answer]}"</div>
                    : null}
            </div>
        );
    }

}

Stock.propTypes = {
    name: PropTypes.string.isRequired,
    value:PropTypes.number,

};

Stock.defaultProps = {

};
