import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";

import {GlobalContext} from "providers/GlobalProvider";

import AddContact from "components/Forms/AddContact";

class UpdateContact extends Component {

    closeModal(contacts){
        if(this.props.saveContact){
            this.props.saveContact(contacts);
        } else {
            this.context.closeModal();
        }
    }

    updateContact(datas){
        this.props.contact.firstname = datas.firstname;
        this.props.contact.lastname = datas.lastname;
        this.props.contact.email = datas.email;
        this.props.contact.fonction = datas.fonction;
        this.props.shop.updateAndSaveContact(this.props.contact).then(_ => {
            this.closeModal(this.props.shop.contacts);
        }).catch(cb => {
            alert('Une erreur est survenue');
        });
    }

    previousStep(){
        if(this.props.previousStep){
            this.props.previousStep();
        } else {
            this.context.closeModal();
        }
    }

    render() {
        return (
            <React.Fragment>

                <ModalHead subtitle={"Interlocuteur"} title={"Modifier les infos"} />

                <ModalContent>
                    <React.Fragment>
                        <h3 className="title h3">Modifier un contact</h3>
                        <AddContact interview={this.props.previousStep ? false : true} once={true} contact={this.props.contact} handleStep={() => this.previousStep()} handleContact={(contact) => this.updateContact(contact)}/>
                    </React.Fragment>
                </ModalContent>

            </React.Fragment>
        );
    }

}

UpdateContact.contextType = GlobalContext;

UpdateContact.propTypes = {
    contact: PropTypes.object.isRequired,
    shop: PropTypes.object.isRequired,
    saveContact: PropTypes.func,
    previousStep: PropTypes.func
};

UpdateContact.defaultProps = {
    shop: {},
    contact: {}
};

export default UpdateContact;

