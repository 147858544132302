import {Network} from "app/Core";



export class StatsService {


    static getRegions() {
        return Network.get('/api/stats/region');
    }

    static getDistributor() {
        return Network.get('/api/stats/distributor');
    }

    static getFrance() {
        return Network.get('/api/stats/france');
    }

    static getUser() {
        return Network.get('/api/stats/user');
    }

}
