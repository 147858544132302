import React, {Component} from 'react';
import PropTypes from "prop-types";
import Pie from "react-chartjs-2";

import {User} from "app/Core";

import MaskInput from 'react-maskinput';


export default class Clients extends Component {

    constructor(props) {
        super(props);

    }

    getValue(key) {
        let clientDatas = this.props.value;
        if (!clientDatas || !Array.isArray(clientDatas)) clientDatas = [];
        const kv = clientDatas.findIndex(v => v.key === key);
        if (kv === -1) {
            return '';
        } else {
            return clientDatas[kv].value;
        }
    }

    displayField(label, key) {
        return (
            <>
                <label>{label}</label>
                <div className={'field-unit'}>
                    {
                        this.props.mask
                            ?
                            <MaskInput type={ this.props.type} disabled={User.isSupervisor} placeholder={this.props.maskString} maskString={this.props.maskString} mask={ this.props.mask} size={20} name={this.props.name} value={this.getValue(key)} onChange={(e) => this.props.onChange(key, e.target.value)} onBlur={(e) => this.props.onChange(key, e.target.value)} />
                            :
                            <input disabled={User.isSupervisor} type={this.props.type ? this.props.type : 'text'} name={this.props.name} value={this.getValue(key)} onChange={(e) => this.props.onChange(key, e.target.value)}  onBlur={(e) => this.props.onChange(key, e.target.value)} />
                    }
                    {
                        this.props.question.field_unit
                            ?
                            <span className={'unit'}>{this.props.question.field_unit}</span>
                            :null
                    }
                </div>
            </>
        );
    }

    render() {

        return (

            <div className={'Input question question--bg'}>

                <div className={'Input__in question__in'}>
                    <div className="question__side">
                        <label className={'question__name'}>{this.props.question.name}</label>
                    </div>
                    <div className="Input__control question__control">
                        { this.displayField(this.props.question.field_name, 'all') }
                    </div>

                </div>

                <div className={'Input__in question__in'}>
                    <div className="question__side">
                        <div className="Input__control question__control">
                            { this.displayField('Clients de 1 à 4', '1-4') }
                            { this.displayField('Clients de 5 à 9', '5-9') }
                            { this.displayField('Clients de 10 à 19', '10-19') }
                            { this.displayField('Clients de 20 et +', '20+') }
                        </div>
                    </div>

                    <div><Pie
                        height={300}
                        width={500}
                        data={{
                            labels: ['1 à 4', '5 à 9', '10 à 19', '20 et +'],
                            // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
                            datasets: [
                                {
                                    label: 'Répartition des clients Hager mouvementés',
                                    data: [this.getValue('1-4'), this.getValue('5-9'), this.getValue('10-19'), this.getValue('20+')],
                                    // you can set indiviual colors for each bar
                                    backgroundColor: [
                                        '#4372C4',
                                        '#EC7D31',
                                        '#A4A5A5',
                                        '#FFC000',
                                    ],
                                    borderWidth: 1,
                                }
                            ],
                            options: {

                                legend: {
                                    position: 'bottom'
                                },
                            },
                        }}
                    /></div>
                </div>
            </div>
        );
    }

}

Clients.propTypes = {
    question: PropTypes.object.isRequired,
    name:PropTypes.string,
    value:PropTypes.number,

};

Clients.defaultProps = {

};
