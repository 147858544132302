import React, {Component} from 'react';
import {statusKey} from "config/status";

import './ShopCount.scss';


import { GlobalContext } from "providers/GlobalProvider";
import {withRouter} from "react-router-dom";

class ShopCount extends Component {

    constructor(props) {
        super(props);

        this.counts = {
            todo: {
                count:0,
                label:{
                    singular: 'à faire',
                    plural: 'à faire',
                }
            },
            inProgress: {
                count: 0,
                label:{
                    singular: 'en cours',
                    plural: 'en cours',
                }
            },
            completed: {
                count: 0,
                label:{
                    singular: 'réalisé',
                    plural: 'réalisés',
                }
            }
        }

    }

    count() {
        this.counts.todo.count = 0;
        this.counts.inProgress.count = 0;
        this.counts.completed.count = 0;

        if(this.props.salesPoints.length > 0){


            for(let i in this.props.salesPoints) {
                let salePoint = this.props.salesPoints[i];

                switch (salePoint.status) {
                    case statusKey.TODO:
                        this.counts.todo.count++;
                        break;
                    case statusKey.IN_PROGRESS:
                        this.counts.inProgress.count++;
                        break;
                    case statusKey.COMPLETED:
                        this.counts.completed.count++;
                        break;
                    default:
                        break;
                }
            }
        }
    }

    componentDidMount(){

        this.count();
    }


    render() {

        this.count();

        return (
            <div className="shop-count">

                {
                    Object.keys(this.counts).map((key, i) => {
                        return(
                            <div className="shop-count__counter" key={i}>
                                <div className="shop-count__counter__number">
                                    {
                                        this.counts[key].count
                                    }
                                </div>
                                <div className="shop-count__counter__label">
                                    {
                                        this.counts[key].count > 1 ?
                                            this.counts[key].label.plural
                                        :
                                            this.counts[key].label.singular
                                    }
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        )
    }

}

export default ShopCount;
