import React, {Component} from 'react';

import GlobalProvider from "providers/GlobalProvider";

import {routes} from 'config/routes';

import { Route, Redirect, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';

import NotFound from 'screens/NotFound/NotFound';
import Resize from 'screens/Resize/Resize';

import Modal from 'components/Modal/Modal';
import SplashScreen from "components/SplashScreen/SplashScreen";

import {User, Observer} from "app/Core";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//confirm
import 'react-confirm-alert/src/react-confirm-alert.css';


const NoMatch = ({ location }) => (
    <NotFound/>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        User.isLogged ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{
                pathname: routes.home.path,
                state: { from: props.location }
            }}/>
        )
    )}/>
);

const LoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        !User.isLogged ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{
                pathname: routes.dashboard.path,
                state: { from: props.location }
            }}/>
        )
    )}/>
);

class App extends Component {

    constructor(props){

        super(props);

        this.state = {
            isAppAvailable: window.innerWidth < 720 ? false : true,
            appIsReady: false
        };

        this.routesArr = [];
        for(var key in routes) {
            this.routesArr.push(routes[key]);
        }

        this.resizeWindow = this.resizeWindow.bind(this);

        Observer.subscribe('coreDidInit', () => {
            this.setState({
                appIsReady: true
            });
        });

    }

    componentDidMount(){
        window.addEventListener("resize", this.resizeWindow);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow(){
        window.innerWidth < 720 ? this.setState({isAppAvailable: false}) : this.setState({isAppAvailable: true});
    }

    render() {

        return (

            <SplashScreen>

                <GlobalProvider>

                    <HashRouter>

                        {
                            this.state.isAppAvailable ?

                                (

                                    !this.state.appIsReady ?

                                        <div>&#160;</div>

                                    :

                                        <Switch>
                                            {
                                                this.routesArr.map((route, i) => {
                                                    if (route.private) {
                                                        return(
                                                            <PrivateRoute exact={route.exact} key={i} path={route.path} component={route.component} />
                                                        );
                                                    }
                                                    else {
                                                        return(
                                                            <LoginRoute exact={route.exact} key={i} path={route.path} component={route.component} />
                                                        );
                                                    }
                                                })
                                            }
                                            <Route component={NoMatch}/>
                                        </Switch>

                                )

                                :

                                <Resize/>
                        }

                    </HashRouter>

                    <Modal/>

                    <ToastContainer />

                </GlobalProvider>

            </SplashScreen>

        );

    }

}

export default App;
