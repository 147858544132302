import React, {Component} from 'react';
import PropTypes from "prop-types";

import ModalHead from "../ModalHead";
import ModalContent from "../ModalContent";

import UpdateShop from '../UpdateShop/UpdateShop';
import ChooseContact from "../ChooseContact/ChooseContact";
import Card from "components/Card/Card";
import {Observer, User} from "../../../app/Core";

import { toast } from 'react-toastify';

export default class InfoShop extends Component {

    constructor(props){
        super(props);
        this.state = {
            updateShop: false,
            handleContacts: false,
            addInterview: false,
            isLoaded: false
        };
    }

    componentDidMount() {

        this.props.shop.getContacts().then(_ => {
            this.props.shop.getInterviews(true).then(_ => {
                this.setState({
                    isLoaded: true
                });
            });
        });

    }

    handleInterview(interview){
        if(interview) {
            // We can only access to our own interview or their there are not linked to any user
            if(interview.userId === User.id || !interview.userId) {
                Observer.publish('redirectToInterviewPageAfterCreation', interview);
                this.props.onFinish();
            } else{
                toast.error("Cet entretien appartient à un autre commercial", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'login_error'
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>

                { this.state.isLoaded ?

                    this.state.handleContacts ?

                    <ChooseContact previous={() => this.setState({handleContacts: false})} handleContacts={true} shop={this.props.shop} onFinish={() => this.props.onFinish()} />

                    :

                    this.state.addInterview ?

                        <ChooseContact previous={() => this.setState({addInterview: false})} shop={this.props.shop} disallowContactCreation={this.props.shop.contacts.length ? true : false} interview={true} onFinish={() => this.props.onFinish()} />

                        :

                        this.state.updateShop ?

                            <UpdateShop shop={this.props.shop} previous={() => this.setState({updateShop: false})}/>

                            :

                            <React.Fragment>

                                <ModalHead subtitle={"Point de vente"} title={this.props.shop.name} text={
                                        this.props.shop.contacts.length + " contact" + (this.props.shop.contacts.length > 1 ? 's' : '') + " - " +
                                        this.props.shop.interviews.length + " entretien" + (this.props.shop.interviews.length > 1 ? 's' : '')
                                } />

                                <ModalContent className="full">

                                    <React.Fragment>
                                        <div className="w-top">
                                            <h3 className="title h3">Que voulez-vous faire ?</h3>
                                            <span className="update-shop link-icon" onClick={() => this.setState({updateShop:true})}>Modifier les données du point de vente</span>
                                            <span className="handle-contacts link-icon" onClick={() => this.setState({handleContacts:true})}>Gérer les contacts</span>
                                        </div>

                                        <div className="w-bottom display-row">

                                            <h3 className="title h3 no-margin">{this.props.shop.interviews.length ? "Entretien associé" : "Aucun entretien associé"}</h3>

                                            {this.props.shop.interviews.length ?
                                                <div className="row-small">
                                                    {
                                                        this.props.shop.interviews.map((interview, i) => {
                                                            return <Card interview={true} data={interview} key={i} onClick={(interview) => this.handleInterview(interview) }/>
                                                        })
                                                    }
                                                </div>
                                                :
                                                <span className="add-interview link-icon" onClick={() => this.setState({addInterview:true})}>Créer un nouvel entretien</span>
                                            }

                                        </div>

                                    </React.Fragment>

                                </ModalContent>

                            </React.Fragment>

                    :

                    <div></div>
                }

            </React.Fragment>
        );
    }

}

InfoShop.propTypes = {
    shop: PropTypes.object.isRequired,
    onFinish: PropTypes.func
};

InfoShop.defaultProps = {
    shop: {}
};