import React, {Component} from 'react';
import PropTypes from "prop-types";
import {User} from "app/Core";

export default class Turnover extends Component {


    constructor(props) {
        super(props);
        this.labels = {
            0:'Univers H',
            1:'Univers M',
            2:'Univers T',
            3:'Univers autre n°1',
            4:'Univers autre n°2',
        }
        this.labelsCA = {
            0:'CA H Hager du PdV',
            1:'CA M Hager du PdV',
            2:'CA T Hager du PdV',
            3:'CA Hager du PdV',
            4:'CA Hager du PdV',
        }
    }

    render() {

        this.value = this.props.value;

        // Apply default value (from pdv 2022 import)
        if( typeof this.props.pdv.default2022_datas != "undefined") {
            [0,1,2,3,4].map((i) => {
                if (typeof this.value === 'undefined') {
                    this.value = [];
                }
                if (typeof this.value[i] === 'undefined') {
                    this.value[i] = {'year': 2023};
                }
                if (!this.value[i]['year']) {
                    this.value[i]['year'] = '2023';
                }
                let k = '';
                switch (i) {
                    case 0: k = 'Evolution CA H/ N-1'; break;
                    case 1: k = 'Evolution CA M/ N-1'; break;
                    case 2: k = 'Evolution CA T/ N-1'; break;
                }

                if ((typeof this.value[i]['ca_hager'] === "undefined" || !this.value[i]['ca_hager']) && typeof this.props.pdv.default2022_datas !== "undefined" && this.props.pdv.default2022_datas && typeof this.props.pdv.default2022_datas[k] !== "undefined" && this.props.pdv.default2022_datas[k]) {
                    if (parseFloat(this.props.pdv.default2022_datas[k].replace('%', '').replace(',', '.')) !== this.value[i]['ca_hager']) {
                        this.value[i]['ca_hager'] = parseFloat(this.props.pdv.default2022_datas[k].replace('%', '').replace(',', '.'));
                        this.props.onChange(i, 'ca_hager', this.value[i]['ca_hager']);
                    }
                }
                k = '';
                switch (i) {
                    case 0: k = 'CA H PDV 102022'; break;
                    case 1: k = 'CA M PDV 102022'; break;
                    case 2: k = 'CA T PDV 102022'; break;
                }
                if ((typeof this.value[i]['ca_hager_pdv'] === "undefined" || !this.value[i]['ca_hager_pdv']) && typeof this.props.pdv.default2022_datas !== "undefined" && this.props.pdv.default2022_datas && typeof this.props.pdv.default2022_datas[k] != "undefined" && this.props.pdv.default2022_datas[k]) {
                    if ((this.props.pdv.default2022_datas[k].replace(' €', '')) !== this.value[i]['ca_hager_pdv']) {
                        this.value[i]['ca_hager_pdv'] = (this.props.pdv.default2022_datas[k].replace(' €', ''));
                        this.props.onChange(i, 'ca_hager_pdv', this.value[i]['ca_hager_pdv']);
                    }
                }
                k = '';
                switch (i) {
                    case 0: k = 'Evolution CA H secteur / N-1'; break;
                    case 1: k = 'Evolution CA M secteur / N-1'; break;
                    case 2: k = 'Evolution CA T secteur / N-1'; break;
                }
                if ((typeof this.value[i]['ca_hager_sector'] === "undefined" || !this.value[i]['ca_hager_sector']) && typeof this.props.pdv.default2022_datas !== "undefined" && this.props.pdv.default2022_datas && typeof this.props.pdv.default2022_datas[k] != "undefined" && this.props.pdv.default2022_datas[k]) {
                    if (parseFloat(this.props.pdv.default2022_datas[k].replace('%', '').replace(',', '.')) !== this.value[i]['ca_hager_sector']) {
                        this.value[i]['ca_hager_sector'] = parseFloat(this.props.pdv.default2022_datas[k].replace('%', '').replace(',', '.'));
                        this.props.onChange(i, 'ca_hager_sector', this.value[i]['ca_hager_sector']);
                    }
                }
            });
        }

        return (

            <div className={'Turnover'}>
                <h2 className={'Turnover__title h2'}>{this.props.title}</h2>

                <div className={'Turnover__in'}>
                    {
                        [0,1,2,3,4].map((i) => {

                            return <div className={'Turnover__row'} key={`turnover_row_${i}`}>

                                <div className={'Turnover__col'}>
                                    <div className={'field-unit'}>
                                        {
                                            this.labels[i] ?
                                                <>
                                                    <br /><br />
                                                    {
                                                        i >= 3 ?
                                                            <>
                                                                <input disabled={User.isSupervisor} type="text" name={`${this.props.name}[${i}][name]`} value={this.props.value && this.props.value[i] && this.props.value[i]['name'] ? this.props.value[i]['name'] : this.labels[i]} onChange={(e) => {this.props.onChange(i, 'name', e.target.value)}} onBlur={this.props.onBlur} />
                                                            </>
                                                            :
                                                            <>
                                                                <br />{this.labels[i]}
                                                            </>
                                                    }
                                                </>
                                            :
                                                <>
                                                    <br /><br />
                                                    <input type="text" name={`${this.props.name}[${i}][name]`} value={this.props.value && this.props.value[i] && this.props.value[i]['name'] ? this.props.value[i]['name'] : ''} onChange={(e) => {this.props.onChange(i, 'name', e.target.value)}} onBlur={this.props.onBlur} />
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className={'Turnover__col'} style={{'width': '125px'}}>
                                    <label>Année</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][year]`} value={this.value && this.value[i] && this.value[i]['year'] ? this.value[i]['year'] : ''} onChange={(e) => {this.props.onChange(i, 'year', e.target.value)}} onBlur={this.props.onBlur} />
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>Evol CA Hager / N-1</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_hager]`} value={this.value && this.value[i] && this.value[i]['ca_hager'] ? this.value[i]['ca_hager'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_hager', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>%</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>{this.labelsCA[i]}</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="text" name={`${this.props.name}[${i}][ca_hager_pdv]`} value={this.value && this.value[i] && this.value[i]['ca_hager_pdv'] ? this.value[i]['ca_hager_pdv'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_hager_pdv', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>€</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    <label>Evol CA Secteur / N-1</label>
                                    <div className={'field-unit'}>
                                        <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_hager_sector]`} value={this.value && this.value[i] && this.value[i]['ca_hager_sector'] ? this.value[i]['ca_hager_sector'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_hager_sector', e.target.value)}}  onBlur={this.props.onBlur} />
                                        <span className={'unit'}>%</span>
                                    </div>
                                </div>
                                <div className={'Turnover__col'}>
                                    {
                                        i === 0 ?
                                            <>
                                                <label>Dont CA TAMC</label>
                                                <div className={'field-unit'}>
                                                    <input disabled={User.isSupervisor} type="number" name={`${this.props.name}[${i}][ca_tamc]`} value={this.value && this.value[i] && this.value[i]['ca_tamc'] ? this.value[i]['ca_tamc'] : ''} onChange={(e) => {this.props.onChange(i, 'ca_tamc', e.target.value)}}  onBlur={this.props.onBlur} />
                                                    <span className={'unit'}>€</span>
                                                </div>
                                            </>
                                            : null
                                    }

                                </div>
                            </div>

                        })
                    }
                </div>

            </div>
        );
    }

}

Turnover.propTypes = {
    title:PropTypes.string,
    name:PropTypes.string.isRequired,
    value:PropTypes.array,
    pdv:PropTypes.array,
};

Turnover.defaultProps = {
    title:'Niveau d’engagement'
};
