export const sanitizeForSearch = function(str) {
    return removeAccents(str).toUpperCase();
};

//https://gist.github.com/alisterlf/3490957
export const removeAccents = function(str) {
    var accents    = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž’';
    var accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'";
    str = str ? str.split('') : [];
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
        if ((x = accents.indexOf(str[i])) !== -1) {
            str[i] = accentsOut[x];
        }
    }
    return str.join('');
};

//https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try/3710294#comment63309174_3710506
export const isJSON = function(str) {

    return (/^[\],:{}\s]*$/.test(str.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, '')))
}

export const convertDate = function(date){
    function pad(s) { return (s < 10) ? '0' + s : s; }
    let d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear().toString().substr(-2)].join('/')
}