import {Network,Storage,Observer} from "app/Core";

export class SalePointAction {

    saveContactHasFinished = false;
    intervalContactSaving = null;

    saveRemote(salepointDatas) {

        return new Promise((resolve, reject) => {

            if(this.intervalContactSaving !== null) {
                clearInterval(this.intervalContactSaving);
                this.intervalContactSaving = null;
                this.saveContactHasFinished = false;
            }

            Network.post('/api/salepoint/save/', salepointDatas)
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.salepoint);
                    } else {
                        reject();
                    }
                });

        });


    }

    saveContactRemote(contact, pdv_id) {

        return new Promise((resolve, reject) => {

            contact.pdv_id = pdv_id;
            contact.save().then(resolve(contact)).catch(reject);

        });

    }

    getRemoteContacts(pdv_id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/salespoints/contacts/', {
                pdv_id : pdv_id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.contacts);
                    } else {
                        resolve([]);
                    }
                });

        });

    }

    checkIfWeCanCreateANewInterviewRemote(pdv_id) {

        return new Promise((resolve, reject) => {

            Network.post('/api/salespoint/canwecreateinterview/', {
                pdv_id : pdv_id
            })
                .then(cb => {
                    if(cb.success) {
                        resolve(cb.datas.possible);
                    } else {
                        resolve([]);
                    }
                });

        });

    }

}