import React, {Component} from 'react';
import PropTypes from "prop-types";

import './FindShop.css';

import { GlobalContext } from "providers/GlobalProvider";
import { CREATE_SHOP, CREATE_SHOP_INTERVIEW } from "utils/constants";

export default class FindShop extends Component {

    render() {
        return (
            <div className="w-find-shop">

                {
                    this.props.noResults ?

                    <React.Fragment>
                        <h3 className="title h3--gray">Désolé, aucun résultat ne correspond à votre mot clé </h3>
                        {this.props.interview ?
                            <p className="txt--gray">Vérifiez l’orthographe de votre recherche, utilisez un mot clé plus général ou <span
                                onClick={() => this.context.setShop(CREATE_SHOP_INTERVIEW, {}, this.props.interviewObj)}>créez un nouveau point de vente</span>
                            </p>
                            :
                            <p className="txt--gray">Vérifiez l’orthographe de votre recherche, utilisez un mot clé plus général ou <span
                                onClick={() => this.context.setModalContent(CREATE_SHOP)}>créez un nouveau point de vente</span>
                            </p>
                        }
                    </React.Fragment>

                    : this.props.interview ?

                    <React.Fragment>
                        <h3 className="title h3--gray">Tout d’abord choisissez un point de vente associé à votre entretien</h3>
                        <p className="txt--gray">Pour trouver un point de vente, tapez votre recherche via le champs ci-dessus ou <span onClick={() => this.context.setShop(CREATE_SHOP_INTERVIEW, {}, this.props.interviewObj)}>créez un nouveau point de vente</span></p>
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <h3 className="title h3--gray">Trouver un point de vente</h3>
                        <p className="txt--gray">Pour trouver un point de vente, tapez votre recherche via le champs ci-dessus ou <span onClick={() => this.context.setModalContent(CREATE_SHOP)}>créez un nouveau point de vente</span></p>
                    </React.Fragment>
                }

            </div>
        );
    }

}

FindShop.propTypes = {
    interviewObj: PropTypes.object,
    interview: PropTypes.bool,
    noResults: PropTypes.bool
};

FindShop.defaultProps = {
    interviewObj: {},
    interview: false,
    noResults: false
};

FindShop.contextType = GlobalContext;